import React, { useState } from "react";
import { Box, Text } from "UI";
import { TextInput } from "grommet";
import { FormView, Hide } from "grommet-icons";

import PasswordMask from "./PasswordMask";

/**
 * Password component that returns a Text or TextInput with control for showing/hiding
 *
 * @prop {string} mode - "input" outputs TextInput component, otherwise outputs Text component
 * @prop {bool} [visibleInitially] - whether password visible initially, defaults to false
 * @prop {string} password - password to display if visible
 * @prop {function} setPassword - onChange function passed to TextInput
 * @prop {any} [any] - any other props to pass through to Text/TextInput
 */
const Password = (props) => {
    // leftOver props pass through to components
    const {
        mode,
        visibleInitially = false,
        password,
        setPassword,
        ...leftOver
    } = props;

    const [visible, setVisible] = useState(visibleInitially);
    const mask = "•".repeat(password.length);

    const toggleVisible = (e) => {
        setVisible((prevVisible) => !prevVisible);
        e.stopPropagation(); // Stops parent onClick firing (tapToCopy)
    };

    const componentProps = {
        mask,
        password,
        setPassword,
        visible,
        ...leftOver,
    };
    const toggleProps = { size: "2rem", onClick: (e) => toggleVisible(e) };

    return (
        <Box
            direction="row"
            align="center"
            basis="full"
            pad={{ right: "0.3rem" }}
        >
            <Box basis="full">
                {mode === "input" ? (
                    <InputPassword {...componentProps} />
                ) : (
                    <TextPassword {...componentProps} />
                )}
            </Box>
            <Box>
                {visible ? (
                    <Hide {...toggleProps} />
                ) : (
                    <FormView {...toggleProps} />
                )}
            </Box>
        </Box>
    );
};

const TextPassword = ({ mask, password, visible, ...leftOver }) => {
    return (
        // Disable selecting/highlighting when masked
        <Text {...leftOver} style={visible ? null : { userSelect: "auto" }}>
            {visible ? password : mask}
        </Text>
    );
};

const InputPassword = ({ password, setPassword, visible, ...leftOver }) => {
    return (
        <>
            <TextInput
                onChange={(e) => setPassword(e.target.value)}
                type="text"
                value={password}
                className={visible ? null : "password-mask"}
                {...leftOver}
                plain={true}
            />
            <PasswordMask />
        </>
    );
};

export default Password;
