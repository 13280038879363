import React from "react";
import { Link } from "react-router-dom";
import { Trash } from "grommet-icons";
import { format as timeFormat } from "timeago.js";

import { Box, Button, AppHeader, Page, LoadingScreen, Text } from "UI";
import { ButtonBack, SharedBy } from "Components";

const RecommendationViewItem = ({ label, children, ...props }) => (
    <Box {...props}>
        <Box>
            <Text color="dark-5" style={{ userSelect: "none" }}>
                {label}
            </Text>
        </Box>
        <Box>{children}</Box>
    </Box>
);

export default ({
    canDelete,
    createdOnDate,
    lastUpdatedDate,
    groupid,
    groupName = "Group",
    handleDelete,
    id,
    isMobile,
    isUserRecommendationOwner,
    isLoading,
    metadata,
    name,
    owner,
    relatedService,
    services,
    testimonial,
    TypeIcon,
    typeLabel,
    url,
}) =>
    isLoading ? (
        <LoadingScreen />
    ) : (
        <>
            <AppHeader
                back={<ButtonBack />}
                crumbGrandparent={{
                    text: groupName /* TODO: pass group name through */,
                    link: `/group/view/${groupid}`,
                }}
                next={
                    isUserRecommendationOwner ? (
                        <Link to={`/recommendation/edit/${groupid}/${id}`}>
                            Edit
                        </Link>
                    ) : (
                        <></>
                    )
                }
                title={"View recommendation"}
            />
            <Page pad={"0"}>
                {metadata && metadata.openGraph && metadata.openGraph.image && (
                    <Box
                        align="center"
                        justify="center"
                        background="#f2f2f2"
                        style={{ borderBottom: "1px solid #e7e7e7" }}
                    >
                        <img
                            src={metadata.openGraph.image.url}
                            alt={metadata.general.title}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "60vh",
                                width: "auto",
                                height: "auto",
                            }}
                        />
                    </Box>
                )}
                <Box pad={`${isMobile ? 8 : 32}px 24px`}>
                    <Box gap={isMobile ? "24px" : "32px"}>
                        <Box gap={"12px"}>
                            <Box
                                gap="12px"
                                direction="row"
                                align="center"
                                justify="center"
                            >
                                <TypeIcon color="#888" size="14px" />
                                <Text color="dark-3" size="18px">
                                    {typeLabel}
                                </Text>
                            </Box>
                            <Box align="center" textAlign="center">
                                <Text
                                    size="28px"
                                    style={{ lineHeight: 1.3 }}
                                    textAlign="center"
                                    weight={600}
                                >
                                    {name}
                                </Text>
                            </Box>
                            {owner && <SharedBy email={owner} size="18px" />}
                        </Box>

                        {/* RecommendationLink */}

                        {url && (
                            <Box margin={{ bottom: "8px" }}>
                                <a
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button
                                        style={{
                                            width: "100%",
                                            height: "50px",
                                        }}
                                    >
                                        <Text truncate={true}>
                                            View ({new URL(url).hostname})
                                        </Text>
                                    </Button>
                                </a>
                            </Box>
                        )}

                        {/* Description */}
                        {testimonial && (
                            <RecommendationViewItem
                                label="Testimonial"
                                style={{ fontSize: "1.2rem", lineHeight: 1.4 }}
                            >
                                {testimonial}
                            </RecommendationViewItem>
                        )}

                        {relatedService && services && (
                            <RecommendationViewItem label="Service (tap to view details)">
                                <Box>
                                    <Link
                                        to={`/service/view/${groupid}/${relatedService}`}
                                    >
                                        <Text>
                                            {services[relatedService].name}
                                        </Text>
                                    </Link>
                                </Box>
                            </RecommendationViewItem>
                        )}

                        {metadata &&
                            metadata.general &&
                            metadata.general.description && (
                                <RecommendationViewItem label="Description">
                                    {metadata.general.description}
                                </RecommendationViewItem>
                            )}

                        {createdOnDate && (
                            <RecommendationViewItem label="Added">
                                {timeFormat(createdOnDate)}
                            </RecommendationViewItem>
                        )}

                        {timeFormat(lastUpdatedDate) !==
                            timeFormat(createdOnDate) && (
                            <RecommendationViewItem label="Updated">
                                {timeFormat(lastUpdatedDate)}
                            </RecommendationViewItem>
                        )}

                        <Box margin={{ vertical: "large" }}>
                            <Button
                                icon={<Trash />}
                                onClick={() => handleDelete(id)}
                                plain={true}
                                disabled={!canDelete}
                                type="tertiary"
                            >
                                Delete this recommendation
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Page>
        </>
    );
