import React from "react";
import { Link } from "react-router-dom";
import { Box, DefaultLogo, Text } from "UI";
import { useResponsive } from "Hooks";

export default ({ crumbGrandparent, crumbParent, title, titleDesktop }) => {
    const { isDesktop } = useResponsive();

    return (
        <Box direction="row">
            {isDesktop && (
                <>
                    {crumbGrandparent && (
                        <Text color="dark-3">
                            <Link to={crumbGrandparent.link}>
                                <Text truncate={true}>
                                    {crumbGrandparent.text}
                                </Text>
                            </Link>
                            <Text
                                color="#ccc"
                                margin={{ horizontal: "xsmall" }}
                            >
                                »
                            </Text>
                        </Text>
                    )}
                    {crumbParent && (
                        <Text color="dark-3">
                            <Link to={crumbParent.link}>
                                {crumbParent.text}
                            </Link>
                            <Text
                                color="#ccc"
                                margin={{ horizontal: "xsmall" }}
                            >
                                »
                            </Text>
                        </Text>
                    )}
                </>
            )}
            <Text truncate={true} style={{ maxWidth: "100%" }}>
                {isDesktop
                    ? titleDesktop || title || <DefaultLogo />
                    : title || <DefaultLogo />}
            </Text>
        </Box>
    );
};
