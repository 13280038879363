import React from "react";
import styled from "styled-components";
import { Box, Button, Text } from "UI";

import rankImageScrub from "common/images/rank-scrub.jpg";
import rankImageGolden from "common/images/rank-golden.jpg";
import rankImageEven from "common/images/rank-even.jpg";
import rankImageNew from "common/images/rank-new.jpg";

const RankImage = styled.img`
    max-height: 160px;
    width: auto;
`;

export default ({
    id,
    isRankLast,
    rankName,
    rank,
    rankByBalance,
    rankShared,
}) => {
    return (
        <>
            <Box pad="24px 16px 16px" border="bottom">
                <Box align="center" justify="center">
                    {rankName === "SCRUB" && (
                        <>
                            <RankImage src={rankImageScrub} />
                        </>
                    )}
                    {rankName === "GOLDEN" && (
                        <>
                            <RankImage src={rankImageGolden} />
                        </>
                    )}
                    {rankName === "EVEN" && (
                        <>
                            <RankImage src={rankImageEven} />
                        </>
                    )}
                    {rankName === "NEW" && (
                        <>
                            <RankImage src={rankImageNew} />
                        </>
                    )}
                </Box>
                <Box direction="column" pad={"16px 0"}>
                    {rank !== 1 && (
                        <Box pad={"0 0 16px"}>
                            <Text weight={500} size="16px" textAlign="center">
                                You're{" "}
                                {rankName === "EVEN" && "all squared up and "}
                                {rankShared && "equal"}{" "}
                                {isRankLast ? "last" : formatRank(rank)} in the
                                group!
                                <br />
                                {rankByBalance
                                    ? `${
                                          rankName === "EVEN"
                                              ? "Pay"
                                              : "Square up with"
                                      } someone above you to place higher.`
                                    : "Add another service to place higher."}
                            </Text>
                        </Box>
                    )}
                    <Box>
                        {rankByBalance ? (
                            <Button to={`/group/squareup/${id}`}>
                                Square up<em>!</em>
                            </Button>
                        ) : (
                            <Button to={`/service/new/${id}`}>
                                Add Service
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const formatRank = (rank) => {
    // Add suffix to rank
    const j = rank % 10,
        k = rank % 100;
    if (j === 1 && k !== 11) {
        return rank + "st";
    }
    if (j === 2 && k !== 12) {
        return rank + "nd";
    }
    if (j === 3 && k !== 13) {
        return rank + "rd";
    }
    return rank + "th";
};
