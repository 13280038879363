import useWindowSize from "@rooks/use-window-size";

const MOBILE_BREAKPOINT = 768;

export default () => {
    const { innerWidth } = useWindowSize();

    return {
        isMobile: innerWidth <= MOBILE_BREAKPOINT,
        isDesktop: innerWidth > MOBILE_BREAKPOINT,
    };
};
