import { useEffect, useState } from "react";
import {
    isLoaded,
    useFirestoreConnect,
    useFirebase,
} from "react-redux-firebase";
import { useSelector } from "react-redux";

import useAuth from "./useAuth";

export default (groupid, id) => {
    useFirestoreConnect([
        {
            path: `groups/${groupid}/services/${id}`,
            collection: "groups",
            doc: groupid,
            subcollections: [{ collection: "recommendations", doc: id }],
            storeAs: `recommendation_${groupid}_${id}`,
        },
        {
            path: `groups/${groupid}/services/${id}`,
            collection: "groups",
            doc: groupid,
            subcollections: [
                { collection: "recommendations", doc: id },
                { collection: "comments" },
            ],
            orderBy: ["date"],
            storeAs: `comments_${groupid}_${id}`,
        },
        {
            collection: `groups`,
            doc: groupid,
            storeAs: `group_${groupid}`,
        },
    ]);
    const recommendation = useSelector(
        (state) => state.firestore.data[`recommendation_${groupid}_${id}`]
    );
    const group = useSelector(
        (state) => state.firestore.data[`group_${groupid}`]
    );
    const comments = useSelector(
        (state) => state.firestore.data[`comments_${groupid}_${id}`]
    );

    const [votesUp, setVotesUp] = useState(0);
    const [votesDown, setVotesDown] = useState(0);
    const [votedDir, setVotedDir] = useState(null);
    const [votesLoaded, setVotesLoaded] = useState(false);
    const { uid } = useAuth();

    useEffect(() => {
        if (recommendation && !votesLoaded) {
            const votes = recommendation.votes;
            votes.up && setVotesUp(Object.keys(votes.up).length);
            votes.up && setVotesDown(Object.keys(votes.down).length);
            if (votes.up && uid in votes.up) {
                setVotedDir("up");
            } else if (votes.down && uid in votes.down) {
                setVotedDir("down");
            }
            setVotesLoaded(true);
        }
    }, [recommendation, votesLoaded]);

    const firebase = useFirebase();
    const voteRecommendation = firebase
        .functions()
        .httpsCallable("voteRecommendation");
    const vote = async (dir) => {
        voteRecommendation({ rid: id, groupId: groupid, vote: dir }).then(
            function (result) {
                // maybe have popup that you've voted like in youtube app
            }
        );
        // Updating our local state based on the vote
        if (dir == "up") {
            if (votedDir == "up") {
                setVotedDir(null);
                setVotesUp((votes) => votes - 1);
            } else if (votedDir == "down") {
                setVotedDir("up");
                setVotesUp((votes) => votes + 1);
                setVotesDown((votes) => votes - 1);
            } else if (votedDir == null) {
                setVotedDir("up");
                setVotesUp((votes) => votes + 1);
            }
        } else if (dir == "down") {
            if (votedDir == "down") {
                setVotedDir(null);
                setVotesDown((votes) => votes - 1);
            } else if (votedDir == "up") {
                setVotedDir("down");
                setVotesDown((votes) => votes + 1);
                setVotesUp((votes) => votes - 1);
            } else if (votedDir == null) {
                setVotedDir("down");
                setVotesDown((votes) => votes + 1);
            }
        }
    };

    return recommendation
        ? {
              ...recommendation,
              comments,
              groupName: group && group.name,
              isLoaded: isLoaded(recommendation) && isLoaded(group),
              vote,
              votesUp,
              votesDown,
              votedDir,
          }
        : recommendation;
};
