import React from "react";
import NumberFormat from "react-number-format";
import { Box, Button, Text } from "UI";
import { useUsers } from "common/Firebase/Hooks";
import { paymentOptions } from "common/helpers";

export default ({
    id,
    signedInEmail,
    handleMakePayment,
    userToPay,
    currencySymbolSimple,
    amountToPay,
}) => {
    const { users } = useUsers([userToPay.email]);
    const details = users[userToPay.email] || false;
    const selectedPaymentOption = details
        ? paymentOptions.filter(
              (option) => option.name === details.paymentMethod
          )[0]
        : false;
    return (
        <Box direction="column" pad="16px">
            <Box pad={{ bottom: "24px" }} style={{ userSelect: "none" }}>
                <Box pad={{ bottom: "4px" }}>Transfer method</Box>
                <Box border={{ color: "#dedfe0" }} direction="row">
                    <Box
                        basis="50%"
                        grow="0"
                        shrink="0"
                        background={"#EDFCFF"}
                        pad="medium"
                        align="center"
                        justify="center"
                        border={{
                            size: "1px",
                            color: "#dedfe0",
                            side: "right",
                        }}
                    >
                        <Text textAlign="center">I will pay them directly</Text>
                    </Box>
                    <Box
                        basis="50%"
                        grow="0"
                        shrink="0"
                        pad="medium"
                        align="center"
                        justify="center"
                    >
                        <Text textAlign="center">
                            <Box>Pay through Sharezies</Box>
                            <Text size="14px" color="rgba(0,0,0,0.5)">
                                Coming soon
                            </Text>
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Box pad={{ bottom: "24px" }}>
                <Box pad={{ bottom: "8px" }}>
                    {details.name}'s payment details
                </Box>
                <Box border={{ color: "#dedfe0" }} pad="24px">
                    {details.paymentMethod ? (
                        <>
                            <Box>
                                <Text color="dark-4">Amount</Text>
                                <Text>
                                    <NumberFormat
                                        displayType={"text"}
                                        prefix={currencySymbolSimple}
                                        value={amountToPay}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                    />
                                </Text>
                            </Box>
                            <Box pad="12px 0 0">
                                <Text color="dark-4">Method</Text>
                                <Text>{selectedPaymentOption.label}</Text>
                            </Box>
                            {details.paymentDetails && (
                                <Box pad="12px 0 0">
                                    <Text color="dark-4">
                                        {selectedPaymentOption.additionalLabel}
                                    </Text>
                                    <Text style={{ whiteSpace: "pre-wrap" }}>
                                        {details.paymentDetails}
                                    </Text>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Text>
                            Contact {userToPay.name || userToPay.email}{" "}
                            {userToPay.name && ` (${userToPay.email})`} directly
                            and pay them{" "}
                            <NumberFormat
                                displayType={"text"}
                                prefix={currencySymbolSimple}
                                value={amountToPay}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                            />
                            . Once you've made this payment, tap Square Up! to
                            continue.
                        </Text>
                    )}
                </Box>
            </Box>
            <Box>
                <Button
                    onClick={() => {
                        handleMakePayment(
                            id,
                            userToPay.email,
                            signedInEmail,
                            amountToPay
                        );
                    }}
                >
                    Square up<em>!</em>
                </Button>
            </Box>
        </Box>
    );
};
