import React from "react";

import { Page, AppHeader } from "UI";
import { ButtonBack, MarkdownContent } from "Components";
import TermsUrl from "common/pages/Terms.md";

export default () => (
    <>
        <AppHeader
            align="center"
            titleDesktop={"Terms and Conditions"}
            back={<ButtonBack />}
        />
        <Page>
            <MarkdownContent markdownUrl={TermsUrl} />
        </Page>
    </>
);
