const grommetTheme = {
    global: {
        control: {
            border: {
                radius: "3px",
            },
        },
        colors: {
            brand: "#222222",
            border: "rgba(0,0,0,0.1)",
        },
        focus: {
            border: {
                color: "rgba(0,0,0,0.1)",
            },
        },
        font: {
            family:
                "'acumin-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
            size: "18px",
            height: "26px",
        },
        input: {
            weight: 500,
        },
        select: { background: "white", container: { background: "white" } },
    },
    button: {
        border: {
            radius: "4px",
            width: "0px",
        },
        padding: {
            vertical: "12px",
        },
        primary: {
            color: { dark: "#E2DFEB", light: "#E2DFEB" },
        },
    },
    carousel: {
        animation: {
            duration: 250,
        },
    },
    heading: {
        level: {
            "1": {
                font: {
                    weight: 600,
                },
            },
            "2": {
                font: {
                    weight: 500,
                },
            },
            "3": {
                font: {
                    weight: 500,
                },
            },
            4: {
                font: {
                    weight: 500,
                },
            },
            5: {
                font: {
                    weight: 500,
                },
            },
            6: {
                font: {
                    weight: 500,
                },
            },
        },
    },
    formField: {
        border: {
            color: "#dadce0",
            side: "all",
        },
        label: {
            color: "dark-4",
            size: "small",
            margin: {
                left: "none",
            },
        },
    },
    layer: {
        overlay: {
            background:
                "linear-gradient(270deg, #c8fff1, #e0eaff, #ffe2f7, #feffe2) 800%",
        },
    },
    select: {
        background: "#fff",
    },
    textInput: {
        extend: "background: white;",
    },
    textArea: {
        extend: "background: white;",
    },
    text: {
        medium: {
            size: "1rem",
        },
    },
};

export { grommetTheme };
