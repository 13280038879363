import React from "react";
import { Box } from "UI";
import styled from "styled-components";

const List = (props) => <Box {...props} />;
const ListItemBox = (props) => <Box {...props} />;

const ListItem = styled(ListItemBox)`
    border-bottom: 1px solid #dedfe0;
    display: flex;
    padding: 0;
    &:last-child {
        border-bottom: 0;
    }
`;

export { List, ListItem };
