export const paymentOptions = [
    {
        name: "PAYPAL",
        label: "Paypal",
        additionalLabel: "Paypal email address",
    },
    {
        name: "DIRECT_TRANSFER",
        label: "Direct Transfer",
        additionalLabel: "Bank Transfer Details",
    },
    {
        name: "VENMO",
        label: "Venmo (US only)",
        additionalLabel: "Venmo Details",
    },
    {
        name: "CASH_APP",
        label: "Square Cash (US Only)",
        additionalLabel: "Square Cash Details",
    },
    { name: "CASH", label: "Cash" },
    {
        name: "OTHER",
        label: "Other",
        additionalLabel: "Details for payment method",
    },
];
