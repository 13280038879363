import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import useAuth from "./useAuth";

export default (id) => {
    useFirestoreConnect(
        [
            { collection: "groups", doc: id },
            {
                collection: "groups",
                doc: id,
                storeAs: `services_${id}`,
                subcollections: [{ collection: "services" }],
            },
            {
                collection: "groups",
                doc: id,
                storeAs: `recommendations_${id}`,
                subcollections: [
                    {
                        collection: "recommendations",
                        orderBy: ["createdOn", "desc"],
                    },
                ],
            },
            {
                collection: "groups",
                doc: id,
                storeAs: `invitations_${id}`,
                subcollections: [
                    {
                        collection: "invitations",
                    },
                ],
            },
        ],
        [id]
    );

    const { email } = useAuth();

    const group = useSelector(({ firestore: { data } }) => ({
        group: data.groups && data.groups[id],
        services: data[`services_${id}`],
        recommendations: data[`recommendations_${id}`],
        invitations: data[`invitations_${id}`],
    }));

    const query = `groups/${id}`;
    const error = useSelector(
        ({ firestore: { errors } }) =>
            errors.byQuery[query] && {
                code: errors.byQuery[query].code,
                message: errors.byQuery[query].message,
            }
    );

    const loadState = error
        ? new Error(error.code)
        : isLoaded(group.group) &&
          isLoaded(group.services) &&
          isLoaded(group.invitations);

    const groupObject = {
        ...group,
        isLoaded: loadState,
        isGroupOwner: email && group?.group?.roles?.[email]?.role === "owner",
    };

    return groupObject;
};
