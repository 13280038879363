import sortMembersByBalance from "./sortMembersByBalance";

/**
 * Previews the effects of the user paying another member (target) an
 * amount by returning a newly sorted members array that contains
 * a `previousRank`, `newRank` and `changedRank` properties.
 * @param {Array} sortedMembers list of members sorted in descending order
 * using `balance` and with `email` key added
 * @param {string} target email address str of the payment recipient
 * @param {string} user email address of the payment sender
 * @param {number} amount the amount
 */
export default (sortedMembersOriginal, target, user, amount) => {
    let sortedMembers = JSON.parse(JSON.stringify(sortedMembersOriginal));
    amount = typeof amount === "string" ? Number.parseFloat(amount) : amount;

    for (const [i, member] of sortedMembers.entries()) {
        member.previousRank = i + 1;
        if (member.email === target) {
            member.balance -= Math.abs(amount);
        } else if (member.email === user) {
            member.balance += Math.abs(amount);
        }
    }

    const newlySortedMembers = sortMembersByBalance(
        sortedMembers.reduce((newObj, member) => {
            newObj[member.email] = member;
            return newObj;
        }, {})
    );

    for (const [i, member] of newlySortedMembers.entries()) {
        const newRank = i + 1;
        member.newRank = newRank;
        member.deltaRank = member.previousRank - newRank;
    }

    return newlySortedMembers;
};
