import React from "react";
import styled from "styled-components";
import { Box } from "UI";

const CardBox = styled(Box)`
    border: 1px solid #f3f3f3;
    @media screen and (max-width: 620px) {
        border-width: 1px 0;
    }
`;

const Card = (props) => <CardBox background="white" {...props} />;

export default Card;
