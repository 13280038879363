import React, { useRef, useState } from "react";
import { isLoaded, useFirebase } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import toaster from "toasted-notes";

import { useAuth } from "common/Firebase/Hooks";
import { uploadAvatar } from "common/Firebase/Actions";

import {
    AppHeader,
    AvatarOwn,
    Box,
    Button,
    Heading,
    Page,
    Text,
    LoadingScreen,
} from "UI";

import { EditFormUI } from "./EditForm";

import { ButtonBack } from "Components/ButtonBack";

const Edit = () => {
    const { isLoggedIn, profile, email, uid } = useAuth();
    const fileRef = useRef(null);
    const firebase = useFirebase();
    const [isUploading, setIsUploading] = useState(false);

    const handleAvatarUpload = async (e) => {
        if (e.target.files[0]) {
            setIsUploading(true);
            await uploadAvatar(firebase, uid, e.target.files[0]);
            setIsUploading(false);
            toaster.notify("Profile image updated!", {
                position: "bottom",
            });
        }
    };

    return (
        <EditUI
            isUploading={isUploading}
            isLoggedIn={isLoggedIn}
            fileRef={fileRef}
            profile={profile}
            email={email}
            handleAvatarUpload={handleAvatarUpload}
        />
    );
};

const EditUI = ({
    isLoggedIn,
    isUploading,
    email,
    profile,
    fileRef,
    handleAvatarUpload,
}) => (
    <>
        {isLoggedIn ? (
            <>
                {isUploading && <LoadingScreen />}
                <AppHeader back={<ButtonBack />} title="Account" next={<></>} />
                <Page
                    align="center"
                    justify="start"
                    pad="none"
                    fill
                    insideFill={"vertical"}
                >
                    {isLoaded(profile) && (
                        <>
                            <Box align="center" pad="large" justify="center">
                                <Box margin="medium">
                                    <Box
                                        style={{ cursor: "pointer" }}
                                        align="center"
                                        justify="center"
                                    >
                                        <AvatarOwn
                                            onClick={() =>
                                                fileRef.current.click()
                                            }
                                        />
                                        <Box pad="6px">
                                            <Text size="12px" color="dark-4">
                                                Tap to change
                                            </Text>
                                        </Box>
                                    </Box>
                                    <input
                                        accept="image/*"
                                        ref={fileRef}
                                        type={"file"}
                                        onChange={handleAvatarUpload}
                                        style={{ display: "none" }}
                                    />
                                </Box>
                                <Heading
                                    margin="xsmall"
                                    level="2"
                                    textAlign="center"
                                >
                                    {profile.name}
                                </Heading>
                                <Heading
                                    margin="xsmall"
                                    level="3"
                                    textAlign="center"
                                >
                                    {email}
                                </Heading>
                            </Box>
                            <EditFormUI />
                            <Box pad={"48px 0 0 0"}>
                                <Button type="tertiary" to="/account/logout">
                                    Log out
                                </Button>
                            </Box>
                        </>
                    )}
                    <Box margin={{ top: "auto" }} pad="24px">
                        <Text color="dark-4" textAlign="center" size="0.85rem">
                            Contact{" "}
                            <a
                                href="mailto:help@sharezi.es"
                                style={{ color: "#333" }}
                            >
                                help@sharezi.es
                            </a>{" "}
                            to close your account.
                        </Text>
                    </Box>
                </Page>
            </>
        ) : (
            <Redirect to="/account/new" />
        )}
    </>
);

export default Edit;
