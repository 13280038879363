import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AuthRouter from "./_AuthRouter";

import { RouteNotFound } from "Pages/404";

import { Create } from "Pages/Group/Create";
import { CreateUsers } from "Pages/Group/CreateUsers";
import { View } from "Pages/Group/View";
import { Edit } from "Pages/Group/Edit";
import { SquareUp } from "Pages/Group/SquareUp";

import { ContextNewGroupUsers } from "common/Components";

const GroupRouter = () => {
    return (
        <ContextNewGroupUsers>
            <AuthRouter>
                <Switch>
                    <Route
                        exact
                        path="/group"
                        component={() => <div>Groups</div>}
                    />
                    <Route path="/group/view/:id" component={View} />
                    <Route exact path="/group/edit/:id" component={Edit} />
                    <Route path="/group/squareup/:id" component={SquareUp} />
                    <Route
                        exact
                        path="/group/new/"
                        render={() => <Redirect to={"/group/new/users"} />}
                    />
                    <Route
                        exact
                        path="/group/new/users"
                        component={CreateUsers}
                    />
                    <Route exact path="/group/new/details" component={Create} />
                    <Route component={RouteNotFound} />
                </Switch>
            </AuthRouter>
        </ContextNewGroupUsers>
    );
};

export { GroupRouter };
