import React, { useMemo } from "react";
import { Redirect, useLocation } from "react-router";
import queryString from "query-string";

import { AppHeader, Page } from "UI";
import { ButtonBack, GroupPreview } from "Components";
import { useAuth } from "common/Firebase/Hooks";

// TODO: break up this screen into smaller components
const Preview = ({
    match: {
        params: { id },
    },
}) => {
    const { isLoggedIn } = useAuth();
    const { search } = useLocation();
    const redirectQueryString = useMemo(() => {
        const secret = queryString.parse(search).secret;
        return `?groupId=${id}` + (secret ? `&secret=${secret}` : "");
    }, [id, search]);

    return (
        <>
            <AppHeader
                back={<ButtonBack />}
                next={<></>}
                title={"Join group"}
            />
            <Page
                fill
                textAlign="center"
                align="center"
                justify="center"
                insideFill={"vertical"}
            >
                {isLoggedIn ? (
                    <GroupPreview groupId={id} />
                ) : (
                    // Redirect to sign in with preview (using search params)
                    <Redirect
                        to={{
                            pathname: "/account/login",
                            search: redirectQueryString,
                        }}
                    />
                )}
            </Page>
        </>
    );
};

export default Preview;
