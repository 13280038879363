import React from "react";
import { useAuth } from "common/Firebase/Hooks";
import { Redirect } from "react-router-dom";

import { Router } from "./_BaseRouter";

export default ({ children, ...props }) => {
    const { isLoggedIn, isLoaded } = useAuth();
    if (!isLoaded) {
        return false;
    } else if (isLoaded && !isLoggedIn) {
        return <Redirect to="/" />;
    } else {
        return <Router {...props}>{children}</Router>;
    }
};
