/**
 * Returns true if a given score appears multiple times i.e. multiple users shares the same rank
 *
 * @param {Number} score - the score to check if rank shared for
 * @param {Map} scoreMap - map of scores as given by getScoresRanks
 */
const isRankShared = (score, scoreMap) => {
    const scores = Array.from(scoreMap.values());
    return scores.indexOf(score) !== scores.lastIndexOf(score);
};

export default isRankShared;
