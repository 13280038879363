import React from "react";
import { ButtonBack, ImageUploader } from "Components";
import {
    AppHeader,
    Box,
    FormField,
    Page,
    SaveOverlay,
    Select,
    TextArea,
    TextInput,
} from "UI";

import { randomBandName, randomMotto } from "common/helpers";

export default (props) => {
    const {
        groupImageTarget,
        groupMotto,
        groupName,
        groupCurrency,
        currencyOptions,
        handleSave,
        isSaving,
        setGroupImageTarget,
        setGroupMotto,
        setGroupName,
        setGroupCurrency,
    } = props;

    // TODO: make next disabled if group name is not filled out
    // TODO: add random lookup of group name and motto placeholders

    return (
        <>
            <AppHeader
                back={<ButtonBack />}
                next={
                    <Box style={{ cursor: "pointer" }} onClick={handleSave}>
                        Save
                    </Box>
                }
                title={"Group Details"}
            />
            <Page pad={{}}>
                <ImageUploader
                    name={groupName}
                    groupImageTarget={groupImageTarget}
                    setGroupImageTarget={setGroupImageTarget}
                />
                <Box pad={{ horizontal: "large", vertical: "small" }}>
                    <FormField label="Group name">
                        <TextInput
                            id={"name-input"}
                            required
                            value={groupName || ""}
                            onChange={(e) => setGroupName(e.target.value)}
                            placeholder={randomBandName()}
                            maxLength={30}
                            minLength={1}
                        />
                    </FormField>
                    <FormField label="Currency">
                        <Select
                            options={currencyOptions}
                            value={groupCurrency}
                            onChange={({ option }) => setGroupCurrency(option)}
                        />
                    </FormField>
                    <FormField label="Group motto">
                        <TextArea
                            background="white"
                            value={groupMotto || ""}
                            onChange={(e) => setGroupMotto(e.target.value)}
                            placeholder={randomMotto()}
                            style={{ minHeight: "6rem" }}
                        />
                    </FormField>
                    {isSaving && <SaveOverlay />}
                </Box>
            </Page>
        </>
    );
};
