import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

/**
 *
 * @type {React.Context<{queries: Map<string, string>?, resetQueries: function(): void?}>}
 */
export const QueryContext = React.createContext({});

export default ({ children }) => {
    const { search } = useLocation();
    const [queries, setQueries] = useState({});
    useEffect(() => {
        const newQueries = queryString.parse(search);
        setQueries((q) => ({
            ...q,
            ...newQueries,
        }));
    }, [search]);
    const resetQueries = () => setQueries({});
    const contextValue = useMemo(() => ({ queries, resetQueries }), [queries]);

    return (
        <QueryContext.Provider value={contextValue}>
            {children}
        </QueryContext.Provider>
    );
};
