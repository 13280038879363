import React, { useState } from "react";
import toaster from "toasted-notes";

import { useAuth } from "common/Firebase/Hooks";
import { useFirebase } from "react-redux-firebase";
import { editProfile } from "common/Firebase/Actions";
import { paymentOptions } from "common/helpers";
import { Box, FormField, Select, TextInput, TextArea } from "UI";

const EditFormUI = () => {
    const { profile, isLoaded } = useAuth();
    const firebase = useFirebase();

    const selectedPaymentMethod = paymentOptions.filter(
        (item) => item.name === profile.paymentMethod
    )[0];

    const [hasChanged, setHasChanged] = useState(false);

    const notifyChangeSaved = (override = false) => {
        if (hasChanged || override) {
            toaster.notify("Changes saved!", {
                position: "bottom",
                duration: 2000,
            });
        }
        setHasChanged(false);
    };

    if (!isLoaded) return false;

    return (
        <Box pad="0 24px">
            <FormField label="Full name">
                <TextInput
                    value={profile.name}
                    onChange={(e) => {
                        setHasChanged(true);
                        editProfile(firebase, { name: e.target.value });
                    }}
                    onBlur={() => notifyChangeSaved()}
                    required
                    maxLength={30}
                    minLength={1}
                />
            </FormField>
            <FormField label="Preferred Payment Details">
                <Select
                    background="white"
                    children={(option) => (
                        <Box pad={"12px"}>{option.label}</Box>
                    )}
                    options={paymentOptions}
                    onChange={({ option }) => {
                        setHasChanged(true);
                        notifyChangeSaved(true);
                        editProfile(firebase, { paymentMethod: option.name });
                    }}
                    placeholder={"No preferred method selected"}
                    value={profile.paymentMethod}
                    valueKey={(option) => option.name}
                    valueLabel={
                        selectedPaymentMethod ? (
                            <Box pad="12px" background="white">
                                {selectedPaymentMethod.label}
                            </Box>
                        ) : null
                    }
                ></Select>
            </FormField>
            {selectedPaymentMethod && selectedPaymentMethod.additionalLabel && (
                <FormField label={selectedPaymentMethod.additionalLabel}>
                    <TextArea
                        value={profile.paymentDetails}
                        onBlur={() => notifyChangeSaved()}
                        onChange={(e) => {
                            setHasChanged(true);
                            editProfile(firebase, {
                                paymentDetails: e.target.value,
                            });
                        }}
                        rows="4"
                        placeholder={"Enter your payment details here."}
                    />
                </FormField>
            )}
        </Box>
    );
};

export { EditFormUI };
