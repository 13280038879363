import { useMemo } from "react";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { sortMembersByBalance } from "../../helpers/squareUp";

import useUsers from "./useUsers";

export default (id) => {
    useFirestoreConnect([{ collection: "groups", doc: id }], [id]);

    const roles = useSelector(
        ({ firestore: { data } }) =>
            data.groups && data.groups[id] && data.groups[id].roles
    );

    const { users, isLoaded: isUsersLoaded } = useUsers(
        roles && Object.keys(roles)
    );

    for (let user in users) {
        roles[user].name = users[user].name;
    }

    const sortedMembers = useMemo(
        () => (isLoaded(roles) ? sortMembersByBalance(roles) : []),
        [roles]
    );

    const rolesObject = {
        sortedMembers,
        isLoaded: isLoaded(roles),
    };

    return rolesObject;
};
