import React, { useState, useContext } from "react";
import styled from "styled-components";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Link, useLocation } from "react-router-dom";
import { useFirebase, useFirestore } from "react-redux-firebase";

import { QueryContext } from "Components/ContextQuery";
import { Box, LoadingScreen, Text } from "UI";

import LoginStyles from "./LoginStyles";

const LoginDisclaimer = styled.div`
    line-height: 1.1;
    margin-top: auto;
    max-width: 520px;
    text-align: center;
    padding: 0 0.5rem;
    a {
        color: #666;
    }
`;

/**
 * Auth component
 * @param {string} [returnUrl]
 */
const Auth = ({ returnUrl }) => {
    const firebase = useFirebase();
    const firestore = useFirestore();
    const { search } = useLocation();
    const [isPendingRedirect, setIsPendingRedirect] = useState(false);
    const {
        queries: { email },
    } = useContext(QueryContext);

    const uiConfig = {
        signInSuccessUrl: (returnUrl || "/") + search,
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            "apple.com",
        ],
        credentialHelper: "none",
        callbacks: {
            uiShown: () => {
                setIsPendingRedirect(false);
            },
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                try {
                    const eventToLog = authResult.additionalUserInfo.isNewUser
                        ? "create_account"
                        : "sign_in";
                    firebase.analytics().logEvent(eventToLog);

                    firestore
                        .collection("users")
                        .doc(firebase.auth().currentUser.uid)
                        .get()
                        .then((profile) => {
                            // if we have an auth displayname but no profile displayname
                            if (
                                (firebase.auth().currentUser.displayName &&
                                    !profile.exists) ||
                                (firebase.auth().currentUser.displayName &&
                                    !profile.data().name)
                            ) {
                                firestore
                                    .collection("users")
                                    .doc(firebase.auth().currentUser.uid)
                                    .set(
                                        {
                                            name: firebase.auth().currentUser
                                                .displayName,
                                            email: firebase.auth().currentUser
                                                .email,
                                        },
                                        { merge: true }
                                    );
                            }
                        });
                } catch (error) {
                    console.log(error);
                }
                return true;
            },
        },
        /* tosUrl: "https://sharezi.es/about/terms",
        privacyPolicyUrl: "https://sharezi.es/about/privacy", */
    };

    return (
        <AuthUI
            email={email}
            firebase={firebase}
            uiConfig={uiConfig}
            isPendingRedirect={isPendingRedirect}
            setIsPendingRedirect={setIsPendingRedirect}
        />
    );
};

const AuthUI = ({
    email,
    isPendingRedirect,
    setIsPendingRedirect,
    uiConfig,
    firebase,
}) => (
    <>
        <LoginStyles />

        {isPendingRedirect && <LoadingScreen />}

        {/*{email && /\S+@\S+\.\S+/.test(email) && (*/}
        {/*    <Box pad="24px 24px 48px">*/}
        {/*        <Text textAlign="center">*/}
        {/*            Make sure you sign in as <strong>{email}</strong> in order*/}
        {/*            to accept the invitation to your friend's group.*/}
        {/*        </Text>*/}
        {/*    </Box>*/}
        {/*)}*/}

        <Box align="center" justify="center" flex="grow">
            <StyledFirebaseAuth
                uiConfig={uiConfig}
                uiCallback={(ui) =>
                    setIsPendingRedirect(ui.isPendingRedirect())
                }
                firebaseAuth={firebase.auth()}
            />
            <Text size="small" color="dark-5" textAlign="center">
                We won't share or post anything without your permission.
            </Text>
        </Box>

        {!isPendingRedirect && (
            <>
                <LoginDisclaimer>
                    <Text size="xsmall" color="dark-5">
                        By clicking "Sign in with Google/Facebook/Apple" above,
                        you acknowledge that you have read, understood, and
                        agree to our{" "}
                        <Link to={"/about/terms"}>Terms &amp; Conditions</Link>{" "}
                        and <Link to={"/about/privacy"}>Privacy Policy</Link>.
                    </Text>
                </LoginDisclaimer>
            </>
        )}
    </>
);

export default Auth;
