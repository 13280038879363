import React from "react";
import styled from "styled-components";
import { Box } from "UI";

const PageBox = styled(Box)`
    background: #fcfcfc;
    overflow: auto;
    display: block;
    /* display: ${(props) => (props.insideFill ? "flex" : "block")}; */
`;

const InsidePageBox = styled(Box)`
    max-width: 620px;
    margin: 0 auto;
    width: 100%;
    min-height: 100%;
    flex-grow: 1;
    /* height: ${(props) => (props.insideFill ? "100%" : "auto")}; */
`;

const Page = ({ children, insideFill = false, pad = "large", ...props }) => (
    <PageBox {...props} insideFill={insideFill}>
        <InsidePageBox
            direction="column"
            pad={pad}
            insideFill={insideFill}
            justify={props.justify}
            gap={"medium"}
        >
            {children}
        </InsidePageBox>
    </PageBox>
);

export default Page;
