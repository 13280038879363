import { isNativeApp } from "../../helpers";

const production = {
    apiKey: "AIzaSyBzqpGI1IiUW49GetOo7RJ14Vpf850CTpQ",
    authDomain: "sharezi.es",
    databaseURL: "https://sharezies-akr.firebaseio.com",
    projectId: "sharezies-akr",
    storageBucket: "gs://content.sharezi.es",
    messagingSenderId: "300431506287",
    appId: "1:300431506287:web:71b010ed32bdc07f9b469a",
    measurementId: "G-2GFL9VEDEZ",
};
const test = {
    apiKey: "AIzaSyCUu_DB9NgmZAU0LwfwDYP4gab3-8X_BIY",
    authDomain: "sharezies-test.firebaseapp.com",
    databaseURL: "https://sharezies-test.firebaseio.com",
    projectId: "sharezies-test",
    storageBucket: "gs://sharezies-test.appspot.com",
    messagingSenderId: "38477874904",
    appId: "1:38477874904:web:769c5e105c82b3136639ad",
    measurementId: "G-6KCS6GJSGG",
};

const chooseDb = () => {
    return (process && process.env && process.env.NODE_ENV === "development") ||
        (!isNativeApp() &&
            location &&
            location.hostname === "sharezies-test.firebaseapp.com")
        ? test
        : production;
};

export default chooseDb();
