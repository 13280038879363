import React, { useEffect } from "react";
import {
    useGroup,
    useBalances,
    useAuth,
    useGroups,
} from "common/Firebase/Hooks";
import ViewUI from "./ViewUI";
import { useHistory } from "react-router-dom";

const EMPTY_GROUP = "EMPTY_GROUP";
const NORMAL_GROUP = "NORMAL_GROUP";

// TODO: this stuff should be in common
const ViewGroup = ({
    match: {
        params: { id },
    },
}) => {
    const { email } = useAuth();
    const { newGroups, isLoaded: isGroupsLoaded } = useGroups(email);
    const history = useHistory();
    const {
        group,
        services,
        recommendations,
        isLoaded: isGroupLoaded,
    } = useGroup(id);
    const { sortedMembers, isLoaded: isBalancesLoaded } = useBalances(id);

    // Redirect to preview if not accepted group yet
    useEffect(() => {
        if (isGroupsLoaded) {
            const reduceGroups = (isGroup, group) => isGroup || group.id === id;
            const isNewGroup = newGroups.reduce(reduceGroups, false);
            if (isNewGroup) {
                history.push(`/group/preview/${id}`);
            }
        }
    }, [newGroups, history, id, isGroupsLoaded]);

    // Show 404 if error occurs while loading group
    useEffect(() => {
        if (isGroupLoaded instanceof Error) {
            history.push(`/404`);
        }
    }, [isGroupLoaded, history]);

    // Check if any of the services have a cost, determines ranking of leaderboard
    const reduceServiceCost = (hasCost, service) =>
        service && (hasCost || parseFloat(service.serviceCost) > 0);
    const rankByBalance =
        !!services && Object.values(services).reduce(reduceServiceCost, false);

    const isLoaded = isGroupLoaded && isBalancesLoaded;
    const groupStatus = group && !services ? EMPTY_GROUP : NORMAL_GROUP;
    const showLeaderboardTab =
        group &&
        services &&
        Object.keys(services).length &&
        group.roles &&
        Object.keys(group.roles).length > 1;

    const propsToPass = {
        ...group,
        groupStatus,
        id,
        isLoaded,
        rankByBalance,
        recommendations,
        services,
        showLeaderboardTab,
        sortedMembers,
    };

    return <ViewUI {...propsToPass} />;
};

export { EMPTY_GROUP, NORMAL_GROUP };
export default ViewGroup;
