import human from "humanparser";

const formatDisplayName = (userName, userEmail) => {
    return userName && userName !== "Sharezies User"
        ? human.parseName(userName).firstName
        : userEmail
        ? userEmail.split("@", 1)[0]
        : "";
};

export default formatDisplayName;
