import React from "react";
import { Route, Switch } from "react-router-dom";

import { Router } from "./_BaseRouter";

import { Terms, Privacy, LearnMore, Support } from "Pages/About";
import { RouteNotFound } from "Pages/404";

const AboutRouter = () => {
    return (
        <Router>
            <Switch>
                <Route path="/about/terms" exact component={Terms} />
                <Route path="/about/privacy" exact component={Privacy} />
                <Route path="/about/learn-more" exact component={LearnMore} />
                <Route path="/about/support" exact component={Support} />
                <Route component={RouteNotFound} />
            </Switch>
        </Router>
    );
};

export { AboutRouter };
