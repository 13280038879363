import React from "react";

import { Page, AppHeader } from "UI";
import { ButtonBack, MarkdownContent } from "Components";
import SupportUrl from "common/pages/Support.md";

export default () => (
    <>
        <AppHeader
            align="center"
            titleDesktop={"Support"}
            back={<ButtonBack />}
        />
        <Page>
            <MarkdownContent markdownUrl={SupportUrl} />
        </Page>
    </>
);
