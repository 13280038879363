import React from "react";
import styled from "styled-components";
import { Link, Redirect, Route, Switch } from "react-router-dom";

import { AppHeader, LoadingScreen, Box, Page, Tab, TabBox } from "UI";
import { ButtonBack } from "Components";
import { formatStorageUrl } from "common/formatters";

import { ViewServices } from "./ViewServices";
import { ViewLeaderboard } from "./ViewLeaderboard";
import { ViewRecommendations } from "./ViewRecommendations";

const GroupTitleImage = styled(Box)`
    background-image: url("${(props) => encodeURI(props.image)}");
    background-position: 50%;
    background-size: cover;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    height: 24px;
    margin-right: 8px;
    position: relative;
    width: 24px;
`;

const ViewUI = ({
    currencySymbolSimple,
    groupStatus,
    id,
    image,
    isLoaded,
    motto,
    name,
    rankByBalance,
    recommendations,
    roles,
    services,
    showLeaderboardTab,
    sortedMembers,
}) => {
    return !isLoaded ? (
        <LoadingScreen />
    ) : (
        <>
            <AppHeader
                back={<ButtonBack />}
                next={<Link to={`/group/edit/${id}`}>Edit</Link>}
                title={
                    <Box direction="row" align="center" justify="center">
                        {image && (
                            <GroupTitleImage
                                image={formatStorageUrl(image.gsPath)}
                            />
                        )}
                        {name}
                    </Box>
                }
                tabs={
                    <TabBox>
                        <Tab to={`/group/view/${id}/services`}>Passwords</Tab>
                        <Tab to={`/group/view/${id}/recommendations`}>
                            Recommendations
                        </Tab>
                        {showLeaderboardTab && (
                            <Tab to={`/group/view/${id}/leaderboard`}>
                                Stats
                            </Tab>
                        )}
                    </TabBox>
                }
            />
            <Page pad={{}}>
                <Switch>
                    <Redirect
                        from="/group/view/:id"
                        to={"/group/view/:id/services"}
                        exact
                    />
                    <Route exact path="/group/view/:id/services">
                        <ViewServices
                            id={id}
                            groupStatus={groupStatus}
                            motto={motto}
                            roles={roles}
                            services={services}
                        />
                    </Route>
                    <Route exact path="/group/view/:id/leaderboard">
                        <ViewLeaderboard
                            id={id}
                            currencySymbolSimple={currencySymbolSimple}
                            rankByBalance={rankByBalance}
                            services={services}
                            sortedMembers={sortedMembers}
                        />
                    </Route>
                    <Route exact path="/group/view/:id/recommendations">
                        <ViewRecommendations
                            groupid={id}
                            recommendations={recommendations}
                        />
                    </Route>
                </Switch>
            </Page>
        </>
    );
};

export default ViewUI;
