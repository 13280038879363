import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useResponsive } from "Hooks";

import { AvatarOwn, Box, Breadcrumbs } from "UI";
import styled from "styled-components";

const AppHeaderBox = styled(Box)`
    background: #fcfcfc;
    flex: 0 0 50px;

    svg {
        vertical-align: bottom;
    }

    @media screen and (min-width: 768px) {
        flex: 0 0 72px;
        height: 72px;
        padding: 22.5px 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        border-bottom-width: ${(props) => (props.isHidingBorder ? "0" : "1px")};
    }
`;

const DefaultBack = ({ isLoggedIn }) => {
    return (
        <>
            {isLoggedIn ? (
                <Link to="/account">
                    <AvatarOwn size="small" />
                </Link>
            ) : (
                <Link to="/account/login">Login</Link>
            )}
        </>
    );
};

const DefaultNext = ({ isLoggedIn }) => <>{isLoggedIn ? <div /> : <div />}</>;

const AppHeader = ({
    align,
    back,
    crumbGrandparent,
    crumbParent,
    isHidingLogin,
    isHidingBorder,
    titleDesktop,
    photoURL,
    next,
    tabs,
    title,
    titleOverride = false,
}) => {
    // TODO: replace with useAuth
    // TODO: remove dep on react-redux from web when done
    const isLoggedIn = useSelector(
        ({
            firebase: {
                auth: { email, isEmpty, isLoaded },
            },
        }) => !!email || !(isLoaded && isEmpty)
    );

    const { isMobile, isDesktop } = useResponsive();

    const headTitle = titleOverride || title;

    return (
        <>
            <AppHeaderBox
                tag="header"
                direction="row"
                isHidingBorder={isHidingBorder || tabs}
                align="center"
                justify="between"
                pad={{ left: "medium", right: "medium", vertical: "medium" }}
            >
                {headTitle && headTitle !== "" && typeof headTitle == "string" && (
                    <Helmet>
                        <title>{headTitle}</title>
                    </Helmet>
                )}
                {/* Show back link only on mobile */}
                {isMobile && !isHidingLogin && (
                    <Box flex={{ grow: 0.15 }} basis="15%">
                        {back || (
                            <DefaultBack
                                isLoggedIn={isLoggedIn}
                                photoURL={photoURL}
                            />
                        )}
                    </Box>
                )}
                {/* Spacer for center-aligned text without left box */}
                {((isDesktop && align === "center") || isHidingLogin) && (
                    <Box flex={{ grow: 0.15 }} basis="15%" />
                )}
                {/* Main title */}
                <Box
                    style={{ fontWeight: 500 }}
                    flex={{ grow: 0.7 }}
                    basis="70%"
                    align={align || (isMobile ? "center" : "start")}
                    overflow="hidden"
                >
                    <Breadcrumbs
                        crumbParent={crumbParent}
                        crumbGrandparent={crumbGrandparent}
                        title={title}
                        titleDesktop={titleDesktop}
                    />
                </Box>

                <Box
                    flex={{ grow: 0.15 }}
                    basis="15%"
                    align={"end"}
                    style={{ zIndex: 2 }}
                >
                    {next || <DefaultNext isLoggedIn={isLoggedIn} />}
                </Box>
            </AppHeaderBox>

            {tabs}
        </>
    );
};

export default AppHeader;
