export default (url) => {
    const isDevelopment =
        process && process.env && process.env.NODE_ENV === "development";
    return isDevelopment
        ? `https://firebasestorage.googleapis.com/v0/b/sharezies-test.appspot.com/o/${url.replace(
              /\//g,
              "%2F"
          )}?alt=media`
        : `https://content.sharezi.es/${url}`;
};
