import { customAlphabet } from "nanoid/non-secure";
import {
    HOURS_TO_MS,
    INVITATION_LIFETIME_HOURS,
    CODE_ALLOWED_CHARS,
    CODE_LENGTH,
} from "../../helpers/invitationConfig";

const nanoid = customAlphabet(CODE_ALLOWED_CHARS, CODE_LENGTH);

/**
 * Helper function to automatically get list of invited user emails.
 * @param {Object.<string,{email: string}>} [invitations]
 * @returns {string[]}
 */
export function getInvitedEmails(invitations) {
    try {
        return Object.values(invitations).map((invitation) => invitation.email);
    } catch (err) {
        return [];
    }
}

/**
 *
 * @param {firebase.firestore.Firestore} firestore
 * @param {string} email
 * @returns {Promise<null|string>}
 */
export async function getInvitedUidByEmail(firestore, email) {
    // OK to create a new invitation
    const invitedUserRef = firestore
        .collection("users")
        .where("email", "==", email)
        .limit(1);

    const snapshot = await invitedUserRef.get();
    if (snapshot.empty) {
        console.debug(
            `No users matching ${email}: will create invitation for new user`
        );
        return null;
    } else {
        const userDoc = snapshot.docs[0];
        console.debug(
            `User found for ${email} with uid ${userDoc.id}: will create invitation for existing user`
        );
        return userDoc.id;
    }
}

/**
 * Template for creating a new invitation map in Firestore.
 * Random invite code generation done using nanoid.
 * @param {string | null} [groupId]
 * @param {string | null} email
 * @param {string | null } uid
 * @param {string} addedBy
 * @param firebase
 */
export function newInvitationTemplate(
    { groupId = null, email = null, uid = null, addedBy },
    firebase
) {
    const currentDateTime = new Date();
    const expiryDateTime = new Date(
        currentDateTime.getTime() + INVITATION_LIFETIME_HOURS * HOURS_TO_MS
    );
    const code = nanoid();
    return {
        code,
        groupId,
        email,
        uid,
        addedBy,
        invitedAt: firebase.firestore.Timestamp.fromDate(currentDateTime),
        expiresAt: firebase.firestore.Timestamp.fromDate(expiryDateTime),
    };
}

/**
 * Create an invitation for the given email. If a user with that email already
 * exists, associated their uid with the new invitation.
 * @param firebase
 * @param {firebase.firestore.Firestore} firestore
 * @param {string} groupId
 * @param {string} email
 * @param {string} addedBy
 * @param {Object} [roles]
 * @param {Object} [invitations]
 * @returns {Promise<{invitationRef: firebase.firestore.DocumentReference, invitation: Object} | null>}
 */
export default async function addInvitation(
    firebase,
    firestore,
    { groupId, email, addedBy },
    roles = {},
    invitations = {}
) {
    const invitedEmails = Object.values(invitations)
        .map((invitation) => invitation?.email)
        .filter((email) => !!email);

    // Only create a new invitation if the user isn't currently a member
    // and if there isn't any outstanding invitation for them
    if (roles.hasOwnProperty(email) || invitedEmails.includes(email))
        return null;

    // OK to create a new invitation
    const uid = await getInvitedUidByEmail(firestore, email);

    const invitationRef = firestore
        .collection("groups")
        .doc(groupId)
        .collection("invitations")
        .doc();
    const invitation = newInvitationTemplate(
        { groupId, email, addedBy, uid },
        firebase
    );
    return { invitationRef, invitation };
}
