import { getMetadata, deleteComment } from "./shared";
import { logEvent } from "../Analytics";

/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} groupid
 * @param {string} service
 */
const addRecommendation = async (firestore, groupid, recommendation) => {
    if (recommendation.url && !recommendation.metadata) {
        recommendation.metadata = await getMetadata(recommendation.url);
    } else {
        recommendation.metadata = recommendation.metadata || {};
    }
    logEvent("create_recommendation");
    return firestore
        .collection("groups")
        .doc(groupid)
        .collection("recommendations")
        .add(recommendation);
};
/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} groupid
 * @param {string} id
 */
const deleteRecommendation = async (firestore, groupid, id) => {
    logEvent("delete_recommendation");

    // delete comments first
    const snapshot = await firestore
        .collection("groups")
        .doc(groupid)
        .collection("recommendations")
        .doc(id)
        .collection("comments")
        .get();
    snapshot.docs.map((doc) =>
        deleteComment(firestore, doc.id, groupid, "recommendations", id)
    );

    return firestore
        .collection("groups")
        .doc(groupid)
        .collection("recommendations")
        .doc(id)
        .delete();
};
/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} groupid
 * @param {string} id
 * @param {Object} service
 */
const editRecommendation = async (firestore, groupid, id, recommendation) => {
    if (recommendation.url) {
        recommendation.metadata = await getMetadata(recommendation.url);
    } else {
        recommendation.metadata = {};
    }

    return firestore
        .collection("groups")
        .doc(groupid)
        .collection("recommendations")
        .doc(id)
        .set(recommendation);
};

export { addRecommendation, deleteRecommendation, editRecommendation };
