import { logEvent } from "../Analytics";

/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} groupid
 * @param {string} toUserEmail
 * @param {string} fromUserEmail
 * @param {Number} amount
 */
const makePayment = async (
    firestore,
    groupid,
    toUserEmail,
    fromUserEmail,
    amount
) => {
    // create batch and doc
    const batch = firestore.batch();
    const docRef = firestore.collection("groups").doc(groupid);

    logEvent("make_payment", {
        amount: amount,
    });

    // add amount to fromUserEmail balance
    const fromUserIncrement = firestore.FieldValue.increment(Number(amount));
    const fromUserPath = new firestore.FieldPath(
        "roles",
        fromUserEmail,
        "balance"
    );

    // remove amount from toUserEmail balance
    const toUserIncrement = firestore.FieldValue.increment(0 - Number(amount));
    const toUserPath = new firestore.FieldPath("roles", toUserEmail, "balance");

    const paymentObject = {
        fromUserEmail,
        toUserEmail,
        amount,
        createdTime: firestore.FieldValue.serverTimestamp(),
        isReversed: false,
    };

    const paymentRef = docRef.collection("payments").doc();

    // send batch
    batch.update(docRef, fromUserPath, fromUserIncrement);
    batch.update(docRef, toUserPath, toUserIncrement);
    batch.set(paymentRef, paymentObject);

    return batch.commit();
};
export { makePayment };
