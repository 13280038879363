import React, { useState } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import toaster from "toasted-notes";

import { formatUrl, validateService } from "common/helpers";

import { useAuth, useGroup } from "common/Firebase/Hooks";
import { addService } from "common/Firebase/Actions";

import { formatNewService } from "common/formatters";
import { FormService } from "Components/FormService";

const ServiceCreate = ({
    match: {
        params: { groupid },
    },
}) => {
    const { email } = useAuth();
    const firebase = useFirebase();
    const firestore = useFirestore();
    const history = useHistory();
    const { group } = useGroup(groupid);

    const currencySymbol = group && group.currencySymbol;

    const createdOn = firebase.firestore.Timestamp.fromDate(new Date());

    const [isSaving, setIsSaving] = useState(false);
    const [cost, setCost] = useState("");
    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [inputUrl, setInputUrl] = useState("");
    const [username, setUsername] = useState("");

    const handleSave = async () => {
        const urlInput = document.getElementById("url-input");
        const nameInput = document.getElementById("name-input");
        const costInput = document.getElementById("cost-input");
        if (validateService(urlInput, nameInput, costInput)) {
            const url = formatUrl(inputUrl);
            setIsSaving(true);
            const serviceDoc = await addService(
                firestore,
                groupid,
                formatNewService({
                    createdOn,
                    cost,
                    description,
                    email,
                    firebase,
                    name,
                    password,
                    url,
                    username,
                })
            );
            toaster.notify("Your service has been created!", {
                position: "bottom",
            });
            history.push(`/service/view/${groupid}/${serviceDoc.id}`);
        }
    };

    const isDataLoaded = true;
    const isNewService = true;

    const propsToPass = {
        cost,
        currencySymbol,
        description,
        groupid,
        groupName: group && group.name,
        handleSave,
        isDataLoaded,
        isNewService,
        isSaving,
        name,
        password,
        setCost,
        setDescription,
        setName,
        setPassword,
        setInputUrl,
        setUsername,
        inputUrl,
        username,
    };

    return <FormService {...propsToPass} title={"New service"} />;
};

export default ServiceCreate;
