import React from "react";
import { Box, Heading } from "UI";
import { ImageDash } from "Components";

const EmptyWelcome = ({ shift, heading, subheading }) => {
    return (
        <Box
            align="center"
            justify="center"
            margin={{ top: "medium", bottom: "medium" }}
        >
            <ImageDash shift={shift} />
            {heading && (
                <Heading
                    textAlign="center"
                    level={2}
                    margin={{ top: "xsmall", bottom: "xsmall" }}
                >
                    {heading}
                </Heading>
            )}
            {subheading && (
                <Heading
                    textAlign="center"
                    level={3}
                    margin={{ top: "xsmall", bottom: "xsmall" }}
                >
                    {subheading}
                </Heading>
            )}
        </Box>
    );
};

export default EmptyWelcome;
