import { logEvent } from "../Analytics";

/**
 * Add an invitation to Firestore, creating a link to parent group via groupId.
 * @param {firebase.firestore.Firestore} firestore
 * @param {firebase.firestore.DocumentReference} invitationRef
 * @param {Object} invitation
 * @returns {Promise<{inviteId: string}>}
 */
export async function addInvitationToFirestore(
    firestore,
    invitationRef,
    invitation
) {
    logEvent("create_invitation", {
        inviteId: invitationRef.id,
        inviteContent: invitation,
    });
    await invitationRef.set(invitation);
    console.log("Invitation successfully created:", invitationRef.id);
}

/**
 *
 * @param {firebase.firestore.Firestore} firestore
 * @param {string} groupId
 * @param {string} inviteId
 */
export async function deleteInvitationFromFirestore(
    firestore,
    groupId,
    inviteId
) {
    logEvent("delete_invitation", { groupId, inviteId });
    await firestore
        .collection("groups")
        .doc(groupId)
        .collection("invitations")
        .doc(inviteId)
        .delete();
    console.log("Invitation successfully deleted:", { groupId, inviteId });
}
