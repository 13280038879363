/**
 * Provides scores and ranks for balances or services
 *
 * @param {Array} sortedMembers - array of sortedMembers as given by squareUp helpers
 * @param {Object} services - services object as given by useGroup hook
 * @param {Boolean} rankByBalance - whether to get scores and ranks for balances or for services
 * @return {Array} array [scoreMap, rankArray] where scoreMap holds scores and rankArray can be used to lookup rank of a score
 */
const getScoresRanks = (sortedMembers, services, rankByBalance) => {
    let rankArray = new Map();
    let deltaMap = new Map();
    let scoreMap = new Map();

    const sortedJoinedMembers = sortedMembers.filter(
        (member) => member.isAccepted
    );

    if (rankByBalance) {
        // Map emails to balances, falsey balance to 0
        scoreMap = sortedJoinedMembers.reduce((map, member) => {
            map.set(member.email, member.balance || 0);
            return map;
        }, new Map());
        deltaMap = sortedJoinedMembers.reduce((map, member) => {
            map.set(member.email, member.deltaRank || 0);
            return map;
        }, new Map());
        // Array of balances removing duplicates, converts falsey to 0
        const balances = sortedJoinedMembers.map(
            (member) => member.balance || 0
        );
        rankArray = Array.from(new Set(balances));
    } else {
        // Map emails to number of services owned
        if (services) {
            scoreMap = Object.keys(services).reduce((map, service) => {
                const owner = services[service].owner;
                if (map.has(owner)) {
                    map.set(owner, map.get(owner) + 1);
                } else {
                    map.set(owner, 1);
                }
                return map;
            }, new Map());
        }
        // Adding emails that don't own any services
        sortedJoinedMembers.forEach((member) => {
            if (!scoreMap.has(member.email)) {
                scoreMap.set(member.email, 0);
            }
        });
        // Sort map
        scoreMap = new Map(
            [...scoreMap.entries()].sort((a, b) => {
                return b[1] - a[1];
            })
        );

        // Array of service counts sorted descending removing duplicates
        const servicesCounts = [...scoreMap.values()];
        rankArray = Array.from(new Set(servicesCounts));
    }
    return [scoreMap, rankArray, deltaMap];
};

export default getScoresRanks;
