import React from "react";
import { ListUserColumn } from "Components";
import { Box, TextInput, Button } from "UI";

export default ({
    emailToAdd,
    handleAddUser,
    removeUser,
    setEmailToAdd,
    users,
}) => {
    // TODO handle enter submit on form
    return (
        <Box gap="medium" direction="column">
            <Box direction="row" gap="xsmall" margin={{ bottom: "small" }}>
                <TextInput
                    type="text"
                    value={emailToAdd}
                    onChange={(e) =>
                        setEmailToAdd(e.target.value.toLowerCase())
                    }
                    placeholder={"Enter a friend's email"}
                />
                <Box basis="2rem">
                    <Button
                        disabled={
                            !emailToAdd ||
                            emailToAdd === "" ||
                            !/\S+@\S+\.\S+/.test(emailToAdd)
                        }
                        onClick={() => {
                            handleAddUser(emailToAdd);
                            setEmailToAdd("");
                        }}
                        style={{ height: "48px", borderRadius: "2px" }}
                    >
                        +
                    </Button>
                </Box>
            </Box>
            <Box>
                {users && (
                    <ListUserColumn users={users} removeAction={removeUser} />
                )}
            </Box>
        </Box>
    );
};
