/**
 * Suggests who the user should pay and how much
 * @param {Array} sortedMembers array of members sorted in descending order
 * using `balance` and with `email` key added
 * @param {string} userEmail email address str of the user
 */

const isNumber = (n) => {
    if (isNaN(n)) {
        return false;
    }
    return typeof n === "number";
};

export default (sortedMembers, userEmail) => {
    let target;
    if (Object.keys(sortedMembers).length < 2) {
        // less than 2 members
        return;
    }

    let user;
    for (const member of sortedMembers) {
        if (target && user) {
            break;
        }
        if (!target && member.email !== userEmail) {
            target = member;
        } else if (member.email === userEmail) {
            user = member;
        }
    }
    const targetBalance = isNumber(target.balance)
        ? parseFloat(target.balance)
        : 0;
    const userBalance = isNumber(user.balance) ? parseFloat(user.balance) : 0;
    const paymentAmount =
        targetBalance >= userBalance
            ? (targetBalance - userBalance) / 2 + 0.01
            : 0;

    return { userToPay: target, amountToPay: paymentAmount };
};
