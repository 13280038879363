import { logEvent } from "../Analytics";

/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {Object} group
 * @param {{addedBy: string, code: string}[]} invitations
 * @return {Promise<firebase.firestore.DocumentReference>}
 */
const addGroup = async (firestore, group, invitations) => {
    logEvent("create_group", {
        numberOfUsers: group && group.roles && Object.keys(group.roles).length,
        numberOfInvitations: invitations && invitations.length,
    });
    const groupDoc = await firestore.collection("groups").add(group);
    console.log("Group doc created, now creating invitations");
    try {
        const batch = firestore.batch();
        for (const invitation of invitations) {
            batch.set(groupDoc.collection("invitations").doc(), {
                ...invitation,
                groupId: groupDoc.id,
            }); // Ensure groupId is set
        }
        await batch.commit();
        console.log("Invitations successfully created");
        return groupDoc;
    } catch (err) {
        console.warn(
            "Error when creating invitations, rolling back group creation"
        );
        await groupDoc.delete();
        throw err;
    }
};
/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} groupid
 * @return {Promise<void>}
 */
const deleteGroup = async (firestore, groupid) => {
    logEvent("delete_group");
    const batch = firestore.batch();

    // delete subcollections first
    const subCollections = [
        "services",
        "recommendations",
        "invitations",
        // Firebase permissions error when trying to delete events
        // "events",
    ];

    const subDocs = await Promise.all(
        subCollections.map((subCollection) =>
            firestore
                .collection("groups")
                .doc(groupid)
                .collection(subCollection)
                .get()
                .then((snapshot) => snapshot.docs)
        )
    );
    const mergedSubDocs = [].concat.apply([], subDocs);
    for (const doc of mergedSubDocs) {
        batch.delete(doc.ref);
    }

    batch.delete(firestore.collection("groups").doc(groupid));
    return batch.commit();
};
/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} id
 * @param {string} group
 */
const editGroup = (firestore, id, group) => {
    return firestore.collection("groups").doc(id).set(group, { merge: false });
};
/**
 *
 * @param {FirebaseFirestore} firestore
 * @param firebase
 * @param {string} groupId
 * @param {File} imageObject
 */
const uploadGroupImage = async (firestore, firebase, groupId, imageObject) => {
    logEvent("upload_image");

    const filePath = `/groups/${groupId}/`;
    const image = await firebase.uploadFile(filePath, imageObject);

    if (image.uploadTaskSnapshot.state === "success") {
        return firestore
            .collection("groups")
            .doc(groupId)
            .set(
                {
                    image: {
                        gsPath: image.uploadTaskSnapshot.metadata.fullPath,
                    },
                },
                { merge: true }
            );
    } else {
        return false;
    }
};
/**
 * Join the group and delete the associated invitation.
 * @param {FirebaseFirestore} firestore
 * @param {string} id
 * @param {string} email
 * @param {string} inviteId
 */
const joinGroup = (firestore, id, email, inviteId) => {
    logEvent("join_group");

    const batch = firestore.batch();
    const docRef = firestore.collection("groups").doc(id);
    const isAcceptedPath = new firestore.FieldPath(
        "roles",
        email,
        "isAccepted"
    );
    const joinedAtPath = new firestore.FieldPath("roles", email, "joinedAt");
    batch.update(docRef, isAcceptedPath, true);
    batch.update(docRef, joinedAtPath, firestore.FieldValue.serverTimestamp());
    batch.delete(docRef.collection("invitations").doc(inviteId));
    return batch.commit();
};
/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} groupId
 * @param {string} email
 */
const leaveGroup = async (firestore, groupId, email) => {
    logEvent("leave_group");

    var fieldRef = firestore.collection("groups").doc(groupId);
    // delete leaving-user-owned services first
    const snapshot = await firestore
        .collection("groups")
        .doc(groupId)
        .collection("services")
        .get();
    const services = snapshot.docs.map((d) => {
        const data = d.data();
        return {
            id: d.id,
            ref: d.ref,
            ...data,
        };
    });
    const batch = firestore.batch();
    for (const service of services) {
        if (service.owner === email) {
            batch.delete(service.ref);
        }
    }
    await batch.commit();
    var rolePath = new firestore.FieldPath("roles", email);
    return fieldRef.update(rolePath, firestore.FieldValue.delete());
};

export {
    leaveGroup,
    joinGroup,
    uploadGroupImage,
    editGroup,
    deleteGroup,
    addGroup,
};
