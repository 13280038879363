/**
 *
 * @param {Object.<string, {email: string}>}invitations
 * @param emailToRemove
 * @returns {string}
 */
export default function getInviteIdToCancel(invitations, emailToRemove) {
    return (
        Object.entries(invitations).reduce(
            (acc, [inviteId, invitation]) => ({
                ...acc,
                [invitation.email]: inviteId,
            }),
            {}
        )[emailToRemove] ?? null
    );
}
