import { Grommet } from "grommet";
import React from "react";
import styled from "styled-components";
import { Box, grommetTheme } from "UI";

const StyledGrommet = styled(Grommet)`
    min-height: -webkit-fill-available;
    max-height: 100%;
    max-height: -webkit-fill-available;
`;

const Container = (props) => (
    <StyledGrommet full theme={grommetTheme} {...props}>
        <Box fill={true} direction="row">
            {props.children}
        </Box>
    </StyledGrommet>
);

export default Container;
