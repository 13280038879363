import React from "react";
import { formatStorageUrl } from "common/formatters";
import { ListUserRow } from "Components";
import { CardButton } from "UI";

export default ({ groups }) => (
    <>
        {groups &&
            groups.map((group) => (
                <CardButton
                    background={
                        group.image && formatStorageUrl(group.image.gsPath)
                    }
                    to={`/group/view/${group.id}`}
                    key={group.id}
                    title={group.name}
                    subtitle={
                        Object.keys(group.roles).length === 1 ? (
                            "Just you"
                        ) : (
                            <ListUserRow
                                emails={Object.keys(group.roles)}
                                size="26px"
                            />
                        )
                    }
                />
            ))}
    </>
);
