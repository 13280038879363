import { createGlobalStyle } from "styled-components";
import spinner from "./Spinner.svg";

export default createGlobalStyle`
    body {
        
        .firebaseui-container {
            min-width: 280px;
            font-family: 'acumin-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            background-color: transparent !important;
        }

        .firebaseui-button {
            font-family: 'acumin-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }

        .mdl-button {
            font-family: 'acumin-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .firebaseui-idp-text {
            min-width: 180px;
        }

        .firebaseui-id-page-callback {
            background-image: url("${spinner}");
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            box-shadow: none;
        }

        .firebaseui-idp-google > .firebaseui-idp-text {
            color: #333;
        }

        /* .firebaseui-idp-text {
            color: #333;
        } */

        button.firebaseui-idp-password,
        .firebaseui-idp-google {
            background-color: white !important;
            border: 2px solid #eee;
        }

        button.firebaseui-idp-password,
        button.firebaseui-idp-google,
        button.firebaseui-idp-facebook,
        button.firebaseui-idp-generic {
            border-radius: 18px !important;
            transition: 0.2s all ease;

        }

        button.firebaseui-idp-google {
            &:hover {
                
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04),
                    0px 4px 8px rgba(0, 0, 0, 0.08) !important;
            }
        }

        button.firebaseui-idp-facebook,
        button.firebaseui-idp-generic {
            &:hover {
                
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
                    0px 4px 8px rgba(0, 0, 0, 0.16) !important;
            }
        }

        button.firebaseui-idp-password:hover,
        .firebaseui-idp-google:hover,
        .firebaseui-idp-facebook:hover,
        .firebaseui-idp-generic:hover {
            
        }

        .mdl-button--primary.mdl-button--primary {
            color: #333;
            text-transform: none;
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0;
            border-radius:14px;
        }

        .mdl-button--raised.mdl-button--colored {
            background: linear-gradient(227deg, #55dc03, #1dd499, #2e65e1);
            background-size: 600% 600%;
            animation: ButtonMoving 30s ease infinite;
            text-transform: none;
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0;
            border-radius: 14px;
        }

        .mdl-button--raised.mdl-button--colored:hover {
            background: #555;
        }

        .firebaseui-idp-password .firebaseui-idp-icon {
            filter: invert(1);
        }

        .firebaseui-form-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .mdl-shadow--2dp {
            box-shadow: none !important;
        }
        .mdl-progress {
            display: none;
        }

        .firebaseui-idp-list {
            margin: 0;
        }

        .firebaseui-idp-button {
            max-width: 360px;
            box-shadow: none;
            padding: 0.75rem;
            border-radius:2px;
        }

        .mdl-button--raised {
            box-shadow:none;
        }
        .firebaseui-idp-text {
            font-size: 1rem;
        }

        .firebaseui-title {
            text-align: center;
        }

        .firebaseui-card-content {
            padding: 0;
        }

        @media (max-width: 480px) {
            .firebaseui-card-header {
                border-bottom: 0;
            }
        }
    }
`;
