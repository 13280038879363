import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthRouter from "./_AuthRouter";

import { Create } from "Pages/Service/Create";
import { Edit } from "Pages/Service/Edit";
import { View } from "Pages/Service/View";

import { RouteNotFound } from "Pages/404";

const ServiceRouter = () => {
    return (
        <AuthRouter>
            <Switch>
                <Route
                    exact
                    path="/service/edit/:groupid/:id"
                    component={Edit}
                />
                <Route path="/service/new/:groupid" component={Create} />
                <Route path="/service/view/:groupid/:id" component={View} />
                <Route component={RouteNotFound} />
            </Switch>
        </AuthRouter>
    );
};

export { ServiceRouter };
