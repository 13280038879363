import { useEffect, useState } from "react";
import {
    isLoaded,
    useFirebase,
    useFirestoreConnect,
} from "react-redux-firebase";
import { useSelector } from "react-redux";

import useAuth from "./useAuth";

export default (groupid, id) => {
    useFirestoreConnect([
        {
            path: `groups/${groupid}/services/${id}`,
            collection: "groups",
            doc: groupid,
            subcollections: [{ collection: "services", doc: id }],
            storeAs: `service_${groupid}_${id}`,
        },
        {
            path: `groups/${groupid}/services/${id}`,
            collection: "groups",
            doc: groupid,
            subcollections: [
                { collection: "services", doc: id },
                { collection: "comments" },
            ],
            orderBy: ["date"],
            storeAs: `comments_${groupid}_${id}`,
        },
        {
            collection: `groups`,
            doc: groupid,
            storeAs: `group_${groupid}`,
        },
    ]);
    const service = useSelector(
        (state) => state.firestore.data[`service_${groupid}_${id}`]
    );
    const group = useSelector(
        (state) => state.firestore.data[`group_${groupid}`]
    );
    const comments = useSelector(
        (state) => state.firestore.data[`comments_${groupid}_${id}`]
    );

    const [thanked, setThanked] = useState(false);
    const [numThanked, setNumThanked] = useState(0);
    const [thanksLoaded, setThanksLoaded] = useState(false);
    const { uid } = useAuth();

    useEffect(() => {
        if (service && !thanksLoaded) {
            service.thanked && setThanked(uid in service.thanked);
            service.thanked &&
                setNumThanked(Object.keys(service.thanked).length);
            setThanksLoaded(true);
        }
    }, [service, thanksLoaded]);

    const firebase = useFirebase();
    const voteRecommendation = firebase
        .functions()
        .httpsCallable("voteService");
    const toggleThank = async (dir) => {
        voteRecommendation({ sid: id, groupId: groupid }).then(function (res) {
            // maybe have popup that you've voted like in youtube app
        });
        if (thanked) {
            setNumThanked((num) => --num);
        } else {
            setNumThanked((num) => ++num);
        }
        setThanked((val) => !val);
    };

    return service
        ? {
              ...service,
              comments,
              groupName: group && group.name,
              isLoaded: isLoaded(service) && isLoaded(group),
              numThanked,
              thanked,
              toggleThank,
          }
        : service;
};
