import React from "react";
import { Helmet } from "react-helmet";
import { Router } from "Routes/_BaseRouter";

import { Container } from "UI";
import { DefaultStyle } from "UI/Theme";
import { MainRouter } from "Routes/MainRouter";

import { SidebarDesktop, MainWindow } from "Components";

const App = () => (
    <Container>
        <Helmet
            titleTemplate={"%s • Sharezies"}
            defaultTitle="Sharezies"
        ></Helmet>
        <Router>
            <SidebarDesktop />
            <DefaultStyle />
            <MainWindow>
                <MainRouter />
            </MainWindow>
        </Router>
    </Container>
);

export default App;
