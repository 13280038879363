import isURL from "validator/es/lib/isURL";

/**
 * Checks whether input is a valid URL
 *
 * @param {string} url - string to check if url or not
 * @returns {boolean} true if valid URL and false otherwise
 */
export const validUrl = (url) => {
    return isURL(url);
};

/**
 * Adds protocol http:// to a valid URL if no http or https protocol present
 * Returns back input if invalid URL
 *
 * @param {string} url - valid url to prepend protocol to
 * @returns {string} url with protocol prepended
 */
export const formatUrl = (url) => {
    if (validUrl(url)) {
        if (
            url.substring(0, 8) == "https://" ||
            url.substring(0, 7) == "http://"
        ) {
            return url;
        }
        return "http://" + url;
    } else {
        return url;
    }
};
