const formatNewRecommendation = ({
    createdOn,
    firebase,
    metadata,
    name,
    owner,
    relatedService,
    score = 0,
    siteIcon,
    testimonial,
    type,
    url,
    votes = {up: {}, down: {}},
}) => {
    const recommendation = {
        createdOn,
        lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
        metadata,
        name,
        owner,
        relatedService,
        score,
        siteIcon: siteIcon || false,
        testimonial,
        type,
        url,
        votes,
    };
    return recommendation;
};

export default formatNewRecommendation;
