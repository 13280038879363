import React from "react";
import { Box } from "UI";
import { EmptyWelcome } from "Components";

const WelcomeToGroup = () => (
    <Box>
        <EmptyWelcome
            shift={1}
            heading={"Wow! A group."}
            subheading={"Now it's time to add your first password."}
        />
    </Box>
);

export default WelcomeToGroup;
