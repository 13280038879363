import {
    getInvitedUidByEmail,
    newInvitationTemplate,
} from "../Components/FormGroups";

export default function formatNewGroup({
    name,
    motto,
    currency,
    currencySymbol,
    currencySymbolSimple,
    roles,
    firebase,
}) {
    const group = {
        createdOn: firebase.firestore.Timestamp.fromDate(new Date()),
        currency: currency,
        currencySymbol: currencySymbol,
        currencySymbolSimple: currencySymbolSimple,
        isDisabled: false,
        name: name,
        motto: motto,
        roles: roles,
    };

    return group;
}

/**
 *
 * @param {{email: string, role: string}[]} users
 * @param {string} owner
 * @param firebase
 * @param {firebase.firestore.Firestore} firestore
 * @return {{roles: Map<string, Object>, invitations: Object[]}}
 */
export async function formatNewGroupUsers(users, owner, firebase, firestore) {
    const currentDate = firebase.firestore.Timestamp.fromDate(new Date());
    const formattedUsers = {};
    const invitations = [];
    for (const user of users) {
        if (user.role === "owner") {
            // Add the owner into formattedUsers
            formattedUsers[user.email] = {
                addedBy: user.role === "owner" ? false : owner,
                role: user.role,
                isAccepted: user.role === "owner",
                invitedAt: currentDate,
                joinedAt: user.role === "owner" ? currentDate : null,
                balance: 0,
            };
        } else {
            // Generate an invitation
            const uid = await getInvitedUidByEmail(firestore, user.email);
            const newInvitation = newInvitationTemplate(
                {
                    email: user.email,
                    uid,
                    addedBy: owner,
                },
                firebase
            );
            invitations.push(newInvitation);
        }
    }
    return { roles: formattedUsers, invitations };
}
