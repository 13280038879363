import React from "react";

import { ConnectedRouter } from "connected-react-router";
import { history } from "common/store";

const Router = ({ children }) => {
    return <ConnectedRouter history={history}>{children}</ConnectedRouter>;
};

export { Router };
