const removeUserFromRoles = (roles, emailToRemove) => {
    // TODO: this probably needs to send to firebase directly from here with a set/merge: false
    let newRoles = { ...roles };
    if (newRoles[emailToRemove]) {
        delete newRoles[emailToRemove];
    }
    return newRoles;
};

export default removeUserFromRoles;
