import React from "react";
import { useHistory } from "react-router-dom";
import toaster from "toasted-notes";

import { LoadingScreen } from "UI";
import { FormGroup } from "Components/FormGroup";
import useGroupEdit from "common/Components/Hooks/useGroupEdit";

/**
 *
 * @param {string} message
 * @returns {Promise<boolean>}
 */
const confirmAsync = (message) => {
    return new Promise(function (resolve, reject) {
        const confirmed = window.confirm(message);
        return resolve(confirmed);
    });
};

const GroupEdit = ({
    match: {
        params: { id },
    },
}) => {
    const history = useHistory();
    const redirectGroup = () => history.push(`/group/view/${id}`);
    const redirectHome = () => history.push("/");
    const getFileFromTarget = (target) => target.file;
    const handleNotifySuccess = (message) =>
        toaster.notify(message, { position: "bottom", type: "success" });
    const handleNotifyError = (message) =>
        toaster.notify(message, { position: "bottom", type: "error" });
    const checkGroupNameValid = () => {
        const nameInput = document.getElementById("name-input");
        return nameInput.checkValidity();
    };
    const handleGroupNameNotValid = () => {
        const nameInput = document.getElementById("name-input");
        nameInput.reportValidity();
    };

    const { isLoaded, ...propsToPass } = useGroupEdit({
        id,
        redirectGroup,
        redirectHome,
        getFileFromTarget,
        handleNotifySuccess,
        handleNotifyError,
        handleConfirm: confirmAsync,
        checkGroupNameValid,
        handleGroupNameNotValid,
    });

    return isLoaded ? <FormGroup {...propsToPass} /> : <LoadingScreen />;
};

export default GroupEdit;
