import React, { useState, useEffect } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import toaster from "toasted-notes";

import { formatNewRecommendation } from "common/formatters";
import { formatUrl, validateRecommendation } from "common/helpers";
import { editRecommendation, getMetadata } from "common/Firebase/Actions";
import { useGroup, useRecommendation } from "common/Firebase/Hooks";

import { FormRecommendation } from "Components/FormRecommendation";
import { LoadingScreen } from "UI";

const RecommendationEdit = ({
    match: {
        params: { id, groupid },
    },
}) => {
    const firebase = useFirebase();
    const firestore = useFirestore();
    const history = useHistory();
    const recommendation = useRecommendation(groupid, id);

    // TODO: this code is pretty splindly and awkward.
    // I'm sure we can refactor and improve.
    const lastUpdated = firebase.firestore.Timestamp.fromDate(new Date());
    const { services } = useGroup(groupid);

    const [isSaving, setIsSaving] = useState(false);
    const [isLoadingUrl, setIsLoadingUrl] = useState(false);
    const [hasBeenEdited, setHasBeenEdited] = useState({
        name: false,
        description: false,
    });
    const [hasRecommendationLoaded, setHasRecommendationLoaded] = useState(
        false
    );

    const [metadata, setMetadata] = useState(false);
    const [testimonial, setTestimonial] = useState("");
    const [name, setName] = useState("");
    const [inputUrl, setInputUrl] = useState("");
    const [relatedService, setRelatedService] = useState("");
    const [siteIcon, setSiteIcon] = useState(false);
    const [type, setType] = useState("TV");

    useEffect(() => {
        if (recommendation && !hasRecommendationLoaded) {
            setTestimonial(recommendation.testimonial);
            setName(recommendation.name);
            setType(recommendation.type);
            setInputUrl(recommendation.url);
            setRelatedService(recommendation.relatedService);
            setSiteIcon(recommendation.siteIcon);
            setHasRecommendationLoaded(true);
        }
    }, [recommendation, hasRecommendationLoaded]);

    const isDataLoaded = recommendation !== {};

    const handleUrlBlur = async () => {
        if (inputUrl && inputUrl !== "") {
            setIsLoadingUrl(true);
            setMetadata(false);
            try {
                const md = await getMetadata(inputUrl);
                setMetadata(md);
                if (!hasBeenEdited.name && md.general && md.general.title) {
                    setName(md.general.title);
                }
                setSiteIcon(md.siteIcon);
                setIsLoadingUrl(false);
            } catch (error) {
                console.log(error);
                setIsLoadingUrl(false);
            }

            setIsLoadingUrl(false);
        }
    };

    const handleSave = async () => {
        const urlInput = document.getElementById("url-input");
        const nameInput = document.getElementById("name-input");
        if (validateRecommendation(urlInput, nameInput)) {
            const url = formatUrl(inputUrl);
            setIsSaving(true);
            await editRecommendation(
                firestore,
                groupid,
                id,
                formatNewRecommendation({
                    ...recommendation,
                    lastUpdated,
                    firebase,
                    name,
                    relatedService,
                    siteIcon,
                    testimonial,
                    type,
                    url,
                })
            );
            toaster.notify("Recommendation changes saved.", {
                position: "bottom",
            });
            history.push(`/recommendation/view/${groupid}/${id}`);
        }
    };

    const isNewRecommendation = false;

    const propsToPass = {
        groupid,
        groupName: recommendation && recommendation.groupName,
        handleSave,
        handleUrlBlur,
        hasBeenEdited,
        id,
        inputUrl,
        isDataLoaded,
        isLoadingUrl,
        isNewRecommendation,
        isSaving,
        metadata,
        name,
        relatedService,
        services,
        setHasBeenEdited,
        setIsLoadingUrl,
        setInputUrl,
        setName,
        setRelatedService,
        setTestimonial,
        setType,
        siteIcon,
        testimonial,
        type,
    };

    return recommendation && recommendation.isLoaded ? (
        <FormRecommendation {...propsToPass} title={`Edit ${name}`} />
    ) : (
        <LoadingScreen />
    );
};

export default RecommendationEdit;
