import React from "react";
import { useUsers } from "common/Firebase/Hooks";
import { formatDisplayName } from "common/formatters";
import { Avatar, Box, Text } from "UI";

export default ({ emails, showUserText, size = "48px" }) => {
    const emailsSliced = emails.slice(0, 4);
    const { users, isLoaded } = useUsers(emailsSliced);
    const usersArray = users && Object.values(users);
    return isLoaded ? (
        <>
            <Box
                direction="row"
                gap="0"
                height={size}
                align="center"
                justify="end"
            >
                {emailsSliced.map((email) => {
                    return (
                        <Box key={email} style={{ marginLeft: "-6px" }}>
                            <Avatar
                                photoURL={
                                    users &&
                                    users[email] &&
                                    users[email].photoURL
                                }
                                email={email}
                                size={"small"}
                            />
                        </Box>
                    );
                })}
            </Box>
            {showUserText && (
                <Box align="center" justify="center">
                    <Text size={"small"}>
                        {usersArray &&
                            usersArray.map((user, index) => {
                                const name = user
                                    ? formatDisplayName(user.name, user.email)
                                    : "";
                                return (
                                    <React.Fragment key={user.id}>
                                        {name}
                                        {index < usersArray.length - 1 && ", "}
                                    </React.Fragment>
                                );
                            })}
                        {usersArray && emails.length > usersArray.length && (
                            <>
                                , and {emails.length - usersArray.length}{" "}
                                invited
                            </>
                        )}
                    </Text>
                </Box>
            )}
        </>
    ) : null;
};
