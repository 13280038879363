import { logEvent } from "../Analytics";
import { deleteComment } from "./shared";

/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} groupid
 * @param {string} service
 */
const addService = async (firestore, groupid, service) => {
    logEvent("create_service");
    /*
  if (service.serviceUrl) {
      service.metadata = await getMetadata(service.serviceUrl);
  } else {
      service.metadata = {};
  }
  */
    return firestore
        .collection("groups")
        .doc(groupid)
        .collection("services")
        .add(service);
};
/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} groupid
 * @param {string} id
 */
const deleteService = async (firestore, groupid, id) => {
    logEvent("delete_service");

    // delete comments first
    const snapshot = await firestore
        .collection("groups")
        .doc(groupid)
        .collection("services")
        .doc(id)
        .collection("comments")
        .get();
    snapshot.docs.map((doc) =>
        deleteComment(firestore, doc.id, groupid, "services", id)
    );

    return firestore.collection("groups").doc(groupid).delete();
};

/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} groupid
 * @param {string} id
 * @param {Object} service
 */
const editService = async (firestore, groupid, id, service) => {
    /*
  if (service.serviceUrl) {
      service.metadata = await getMetadata(service.serviceUrl);
  } else {
      service.metadata = {};
  }
  */
    return firestore
        .collection("groups")
        .doc(groupid)
        .collection("services")
        .doc(id)
        .set(service, { merge: true });
};

export { addService, deleteService, editService };
