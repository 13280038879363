import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useAuth, useGroupPreview } from "common/Firebase/Hooks";
import { useResponsive } from "Hooks";
import { Box, Button, Heading, LoadingScreen, Text } from "UI";
import { QueryContext } from "../ContextQuery";
import queryString from "query-string";
import { FormField, TextInput } from "grommet";
import toaster from "toasted-notes";
import {
    CODE_ALLOWED_CHARS_INVERTED_REGEX,
    CODE_LENGTH,
} from "common/helpers/invitationConfig";

// TODO: break up this screen into smaller components
/**
 *
 * @param {string} groupId
 * @returns {React.ReactHTMLElement}
 */
const GroupPreview = ({ groupId }) => {
    const { isMobile } = useResponsive();
    const history = useHistory();
    const { search } = useLocation();
    const { isLoggedIn, email: authEmail } = useAuth();
    const { queries } = useContext(QueryContext);
    const searchParams = queryString.parse(search);
    const inviteId = searchParams.secret ?? queries.secret;
    const queryEmail = searchParams.email ?? queries.email;
    const [inviteCode, setInviteCode] = useState("");

    const redirectGroupView = () => {
        history.push(`/group/view/${groupId}/`);
    };
    const redirectReject = () => {
        history.push(`/`);
    };
    const redirectError = () => {
        history.push(`/404`);
    };

    const {
        isJoining,
        isLoading,
        handleJoin,
        handleReject,
        name,
        services,
        members,
        invitedEmail,
        errorMessage,
    } = useGroupPreview({
        groupId,
        inviteId,
        redirectGroupView,
        redirectReject,
        redirectError,
        inviteCode,
    });

    const codeValid =
        (authEmail && authEmail === invitedEmail) ||
        inviteCode.length === CODE_LENGTH;

    useEffect(() => {
        if (errorMessage) toaster.notify(errorMessage, { position: "bottom" });
    }, [errorMessage]);

    if (isLoading || isJoining) return <LoadingScreen />;

    const numMembers = members.length;
    const numServices = services.length;

    return (
        <>
            <Heading textAlign={"center"} level={2} margin={"small"}>
                {name}
            </Heading>
            <Heading textAlign={"center"} level={3} margin={"small"}>
                <Box>
                    <Box>
                        <Text color="dark-4">
                            {numMembers} member
                            {numMembers !== 1 ? "s" : ""}:
                        </Text>
                    </Box>
                    {members.map((member, idx) => (
                        <Box key={idx}>{member.name}</Box>
                    ))}
                </Box>
                <>
                    <br />
                    {numServices} service
                    {numServices !== 1 ? "s" : ""}
                </>
            </Heading>
            {authEmail && authEmail !== invitedEmail && (
                <Box margin={"medium"} gap={"small"}>
                    <FormField label="Invite code">
                        <TextInput
                            id={"invite-code-input"}
                            required
                            onChange={(e) =>
                                setInviteCode(
                                    e.target.value.replace(
                                        CODE_ALLOWED_CHARS_INVERTED_REGEX,
                                        ""
                                    )
                                )
                            }
                            placeholder="Enter invite code here"
                            value={inviteCode}
                            maxLength={CODE_LENGTH}
                            minLength={CODE_LENGTH}
                        />
                    </FormField>
                </Box>
            )}
            <Box
                margin={"medium"}
                gap={"small"}
                direction={isMobile ? "column" : "row"}
            >
                {isLoggedIn ? (
                    <>
                        <Button onClick={handleJoin} disabled={!codeValid}>
                            Accept invitation
                        </Button>
                        <Button
                            type="secondary"
                            onClick={handleReject}
                            disabled={!codeValid}
                        >
                            Decline invitation
                        </Button>
                    </>
                ) : (
                    <Text textAlign="center" style={{ lineHeight: 1.5 }}>
                        Sign in below to accept your friend's invitation. You
                        can either sign in as <strong>{queryEmail}</strong>, or
                        enter the invite code from your invitation email when
                        prompted.
                    </Text>
                )}
            </Box>
        </>
    );
};

export default GroupPreview;
