import React from "react";

import { Page, AppHeader } from "UI";
import { ButtonBack, MarkdownContent } from "Components";
import PrivacyUrl from "common/pages/Privacy.md";

export default () => (
    <>
        <AppHeader
            align="center"
            titleDesktop={"Privacy Policy"}
            back={<ButtonBack />}
        />
        <Page>
            <MarkdownContent markdownUrl={PrivacyUrl} />
        </Page>
    </>
);
