import React from "react";
import { Image } from "UI";

import image1 from "common/images/illu-share-1.jpg";
import image2 from "common/images/illu-share-2.jpg";
import image3 from "common/images/illu-share-3.jpg";

const images = [image1, image2, image3];

const ImageDash = ({ shift }) => {
    let hourNo =
        (new Date().getHours() + (shift ? Number(shift) : 0)) % images.length;
    return (
        <Image
            style={{ minHeight: "30vh" }}
            fill={true}
            fit="contain"
            src={images[hourNo]}
        />
    );
};
export default ImageDash;
