// TODO change to balance colours instead
import { isNativeApp } from "common/helpers";

const formatRowBgColor = (balance, rankByBalance) => {
    let color = isNativeApp ? null : "none";
    if (rankByBalance) {
        if (balance > 0) {
            color = "#37B99E";
        } else if (balance < 0) {
            color = "#B93737";
        } else {
            color = "#444";
        }
    }
    return color;
};

export default formatRowBgColor;
