import React from "react";
import styled from "styled-components";
import { Box } from "UI";
import { Link } from "react-router-dom";

const ButtonPrimaryLink = styled(Link)`
    align-items: center;

    background: linear-gradient(227deg, #55dc03, #1dd499, #2e65e1);
    background-size: 600% 600%;
    animation: ButtonMoving 30s ease infinite;

    border-radius: 16px;
    border: 0;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
    &:hover {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
            0px 2px 4px rgba(0, 0, 0, 0.16);
    }
    transition: 0.2s box-shadow ease;
    color: white;
    cursor: pointer;
    display: flex;
    font-family: "acumin-pro";
    font-size: 1rem;
    font-weight: 600;
    height: 2.25rem;
    justify-content: center;
    padding: 0 1.333rem;
    white-space: nowrap;
    pointer-events: ${(props) => (props.disabled ? "none" : "inherit")};
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};
    user-select: none;
`;

const ButtonPrimaryFullLink = styled(ButtonPrimaryLink)`
    width: 100%;
    height: 3rem;
    border-radius: 12px;
`;

const ButtonSecondaryLink = styled(ButtonPrimaryLink)`
    border: 2px solid #eee;
    background: transparent;
    color: black;
    &:hover {
        background: #efefef;
    }
`;

const ButtonTertiaryLink = styled(ButtonPrimaryLink)`
    font-size: 0.9rem;
    background: none;
    border: none;
    color: rgba(0, 0, 0, 0.75);
    &:hover {
        background: #efefef;
    }
`;

const buttonTypes = {
    primary: ButtonPrimaryLink,
    primaryFull: ButtonPrimaryFullLink,
    secondary: ButtonSecondaryLink,
    tertiary: ButtonTertiaryLink,
};

const Button = ({
    disabled = false,
    to,
    fill = "horizontal",
    type = "primary",
    ...props
}) => {
    const ButtonToUse = buttonTypes[type];
    return (
        <Box fill={fill} align="center" justify="center">
            {!disabled && to ? (
                <ButtonToUse to={to}>{props.children}</ButtonToUse>
            ) : (
                <ButtonToUse
                    disabled={disabled}
                    to={""}
                    as="button"
                    {...props}
                />
            )}
        </Box>
    );
};

export default Button;
