import React from "react";
import styled from "styled-components";

import { Jdenticon } from "Components";
import { Box, Text } from "UI";

const ImageUploaderBox = styled(Box)`
    border-radius: 12px;
    border: 2px solid #eee;
    cursor: pointer;
    margin: 3rem auto 0;
    max-width: 522px;
    position: relative;
    width: 100%;

    &:focus,
    &:active {
        box-shadow: none !important;
        outline: none !important;
    }

    @media screen and (max-width: 620px) {
        border: 2px solid #eee;
        border-radius: 0;
        border-width: 2px 0;
        box-shadow: none;
        margin-top: 0;
    }
`;

const JdenticonBox = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageUploader = ({
    existingImageUrl,
    name,
    groupImageTarget,
    setGroupImageTarget,
}) => {
    const imageToShow = groupImageTarget
        ? groupImageTarget.url
        : existingImageUrl
        ? existingImageUrl
        : false;
    return (
        <ImageUploaderBox
            background={{
                color: "#fff",
                dark: true,
                opacity: true,
                position: "50%",
                image: imageToShow ? `url("${encodeURI(imageToShow)}")` : null,
            }}
            height="9rem"
            align="center"
            justify="end"
            onClick={() => document.getElementById("group-image").click()}
        >
            {!imageToShow && (
                <JdenticonBox>
                    <Jdenticon
                        name={name}
                        size="88"
                        style={{ position: "relative", top: "-12px" }}
                    />
                </JdenticonBox>
            )}
            {!groupImageTarget && !existingImageUrl && (
                <Box pad={"small"}>
                    <Text color="dark-5" size="small">
                        <span role="img" aria-label="Camera">
                            📷
                        </span>{" "}
                        Tap to add an image (optional)
                    </Text>
                </Box>
            )}
            <input
                accept="image/*"
                type="file"
                id="group-image"
                onChange={(e) =>
                    setGroupImageTarget({
                        file: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                    })
                }
                style={{ display: "none" }}
            />
        </ImageUploaderBox>
    );
};

export default ImageUploader;
