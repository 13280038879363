import React from "react";
import { Box, Text } from "UI";

import { useAuth } from "common/Firebase/Hooks";
import { formatRowBgColor } from "common/formatters";
import { ListLeaderboardItem, MessageHeader } from "Components";
import { getRank, getScoresRanks, isRankShared } from "common/helpers/squareUp";

const getNamedRank = (balance, rank, totalRanks, joinedDate) => {
    if (rank === 1) {
        return "GOLDEN";
    } else if (balance && Math.round(balance) === 0) {
        return "EVEN";
    } else if (rank === totalRanks) {
        return "SCRUB";
    } else if (joinedDate) {
        return "NEW";
    } else {
        return false;
    }
};

export default ({
    id,
    currencySymbolSimple,
    rankByBalance,
    services,
    sortedMembers,
}) => {
    const { email: signedInEmail } = useAuth();

    const [scoreMap, rankArray] = getScoresRanks(
        sortedMembers,
        services,
        rankByBalance
    );

    const rank = getRank(scoreMap.get(signedInEmail), rankArray);

    const rankName = getNamedRank(
        scoreMap.get(signedInEmail),
        rank,
        rankArray.length,
        false
    );

    return (
        <Box>
            <MessageHeader
                id={id}
                rank={rank}
                rankByBalance={rankByBalance}
                rankName={rankName}
                rankShared={isRankShared(scoreMap.get(signedInEmail), scoreMap)}
                isRankLast={rank === rankArray.length}
            />
            <Box
                direction="row"
                justify={"between"}
                align="center"
                pad={{
                    horizontal: "1.2rem",
                    top: "medium",
                    bottom: "small",
                }}
            >
                <Text weight="bold" size="16px">
                    Member
                </Text>
                <Text weight="bold" size="16px">
                    {rankByBalance ? "Balance" : "Passwords"}
                </Text>
            </Box>
            {Array.from(scoreMap, ([email, score]) => (
                <Box key={email} pad={"0 1.2rem"}>
                    <ListLeaderboardItem
                        score={score}
                        currency={rankByBalance ? currencySymbolSimple : null}
                        decimalScale={rankByBalance ? 2 : 0}
                        rank={getRank(score, rankArray)}
                        tone={formatRowBgColor(score, rankByBalance)}
                        email={email}
                        signedInEmail={signedInEmail}
                    />
                </Box>
            ))}
        </Box>
    );
};
