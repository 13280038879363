import React from "react";

import { ButtonBack, Password } from "Components";
import { FormField, TextInput, TextArea } from "grommet";
import { Box, AppHeader, Page, SaveOverlay, LoadingScreen } from "UI";
import NumberFormat from "react-number-format";

const FormService = ({
    cost,
    currencySymbol,
    description,
    groupid,
    groupName = "Group",
    handleSave,
    id,
    isDataLoaded,
    isNewService,
    name,
    password,
    setCost,
    setDescription,
    setName,
    setPassword,
    setInputUrl,
    setUsername,
    title,
    inputUrl,
    username,
    isSaving,
}) =>
    !isDataLoaded ? (
        <LoadingScreen />
    ) : (
        <>
            <AppHeader
                title={title}
                titleDesktop={
                    isNewService ? "Create Password" : "Edit Password"
                }
                crumbGrandparent={{
                    text: groupName /* TODO: pass group name through */,
                    link: `/group/view/${groupid}`,
                }}
                crumbParent={
                    !isNewService && {
                        text: name,
                        link: `/service/view/${groupid}/${id}`,
                    }
                }
                back={<ButtonBack />}
                next={
                    <div style={{ cursor: "pointer" }} onClick={handleSave}>
                        Save
                    </div>
                }
            />
            <Page gap="small" pad="none">
                <Box pad={"24px"}>
                    <FormField label="Service name">
                        <TextInput
                            id={"name-input"}
                            required
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Service name"
                            value={name || ""}
                            maxLength={30}
                            minLength={1}
                        />
                    </FormField>

                    <FormField label="Service URL">
                        <TextInput
                            id={"url-input"}
                            onChange={(e) => setInputUrl(e.target.value)}
                            placeholder="website.com"
                            value={inputUrl || ""}
                            autoCapitalize="off"
                            autoCorrect="off"
                            autoComplete="off"
                        />
                    </FormField>

                    <FormField label="Service username">
                        <TextInput
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="username"
                            value={username || ""}
                            autoCapitalize="off"
                            autoCorrect="off"
                            autoComplete="off"
                        />
                    </FormField>

                    <FormField label="Service password">
                        <Password
                            mode="input"
                            password={password || ""}
                            setPassword={setPassword}
                            placeholder="password"
                            autoCapitalize="off"
                            autoCorrect="off"
                            autoComplete="off"
                        />
                    </FormField>

                    <FormField
                        label="Service cost/month (if applicable)"
                        style={{ position: "relative" }}
                    >
                        <NumberFormat
                            id={"cost-input"}
                            customInput={TextInput}
                            onValueChange={({ value }) => {
                                setCost(value);
                            }}
                            decimalScale={2}
                            inputMode="decimal"
                            isNumericString={true}
                            fixedDecimalScale={false}
                            thousandSeparator={true}
                            placeholder={`Amount`}
                            prefix={currencySymbol}
                            value={cost || `0.00`}
                            plain={true}
                            style={{
                                fontVariantNumeric: "tabular-nums",
                                textAlign: "left",
                            }}
                        />
                    </FormField>

                    <FormField label="Notes">
                        <TextArea
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder={
                                "Any notes for other members of the group?"
                            }
                            rows="5"
                            value={description || ""}
                        />
                    </FormField>
                </Box>

                {isSaving && <SaveOverlay />}
            </Page>
        </>
    );

export default FormService;
