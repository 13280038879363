import React from "react";
import { useAuth } from "common/Firebase/Hooks";
import styled from "styled-components";
import { Box } from "grommet";
import { Image } from "UI";

const FONT_SIZES = {
    xsmall: "12px",
    small: "14px",
    medium: "28px",
};

const MARGIN_SIZES = {
    xsmall: "-2px",
    small: "-3px",
    medium: "-5px",
};

const BOX_SIZES = {
    xsmall: "20px",
    small: "26px",
    medium: "48px",
};

export const AvatarImage = styled(Box)`
    background-color: #f9f9f9;
    background-image: ${(props) => `url("${encodeURI(props.photoURL)}")`};
    background-size: cover;
    background-position: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    user-select: none;
`;

const AvatarBox = styled(Box)`
    font-size: ${(props) => FONT_SIZES[props.size]};
`;

const LetterBox = styled(Box)`
    align-items: center;
    background: #48345d; /* todo: bolder color */
    border-radius: 50%;
    color: white;
    display: flex;
    font-size: 1em;
    font-weight: ${(props) => (props.size === "small" ? "600" : "normal")};
    justify-content: center;
    text-transform: uppercase;
    user-select: none;
    span {
        margin-top: ${(props) => MARGIN_SIZES[props.size]};
    }
`;

export const Avatar = ({ photoURL, email, size = "medium", ...props }) => {
    const boxSize = BOX_SIZES[size];
    return (
        <AvatarBox
            key={email}
            width={boxSize}
            height={boxSize}
            size={size}
            round="full"
        >
            {photoURL ? (
                <Image
                    style={{ borderRadius: "50%" }}
                    fill={true}
                    fit="cover"
                    src={photoURL}
                    {...props}
                />
            ) : (
                <LetterBox
                    {...props}
                    size={size}
                    width={boxSize}
                    height={boxSize}
                >
                    <span>{email && email.slice(0, 1)}</span>
                </LetterBox>
            )}
        </AvatarBox>
    );
};

const AvatarOwn = (props) => {
    const {
        email,
        photoURL: fallBackURL,
        profile: { photoURL },
    } = useAuth();
    return (
        <Avatar
            {...props}
            flex={false}
            size={props.size}
            photoURL={photoURL || fallBackURL}
            email={email}
        />
    );
};

export default AvatarOwn;
