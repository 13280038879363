import React, { useContext, useEffect } from "react";
import toaster from "toasted-notes";

import { Redirect } from "react-router-dom";
import { Page } from "UI";
import { useFirebase } from "react-redux-firebase";
import { useAuth } from "common/Firebase/Hooks";
import { QueryContext } from "Components/ContextQuery";

const Logout = () => {
    const firebase = useFirebase();
    const { isLoggedIn } = useAuth();
    const { resetQueries } = useContext(QueryContext);

    useEffect(() => {
        if (isLoggedIn) {
            resetQueries();
            firebase.auth().signOut();
        } else {
            toaster.notify("You are now logged out. See you again soon!", {
                position: "bottom",
            });
        }
    }, [firebase, isLoggedIn, resetQueries]);

    return <LogoutUI isLoggedIn={isLoggedIn} />;
};

const LogoutUI = ({ isLoggedIn }) => (
    <>
        {isLoggedIn ? (
            <Page>Logging out...</Page>
        ) : (
            <Redirect to="/account/login" />
        )}
    </>
);

export default Logout;
