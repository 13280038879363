import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const ButtonBackDiv = styled.div`
    cursor: pointer;
`;

const ButtonBack = () => {
    const history = useHistory();

    const loc = history.location.pathname.split("/");

    let redirect = false;

    if (loc[1] === "group" && loc[2] === "view") {
        redirect = "/";
    }
    if (loc[1] === "service" && loc[2] === "view") {
        redirect = `/group/view/${loc[3]}`;
    }
    if (loc[1] === "recommendation" && loc[2] === "view") {
        redirect = `/group/view/${loc[3]}/recommendations`;
    }

    return (
        <ButtonBackDiv
            onClick={() =>
                redirect ? history.push(redirect) : history.goBack()
            }
        >
            Back
        </ButtonBackDiv>
    );
};

export default ButtonBack;
