import { getFirebase } from "react-redux-firebase";
import { isNativeApp } from "common/helpers/isNativeApp";
// import * as ExpoAnalytics from "expo-firebase-analytics";

/**
 *
 * @param {string} name - name of the event to record in analytics
 * @param {object} payload - payload object with details of the event eg group ID
 */
export const logEvent = (name, payload) => {
    if (!isNativeApp) {
        const firebase = getFirebase();
        return firebase.analytics().logEvent(name, payload);
    } else {
        return true;
        // TODO: we need to config native firebase if we want to use this
        //return ExpoAnalytics.logEvent(name, payload);
    }
};
