import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthRouter from "./_AuthRouter";

import { Create } from "Pages/Recommendation/Create";
import { Edit } from "Pages/Recommendation/Edit";
import { View } from "Pages/Recommendation/View";

import { RouteNotFound } from "Pages/404";

const RecommendationRouter = () => {
    return (
        <AuthRouter>
            <Switch>
                <Route
                    exact
                    path="/recommendation/edit/:groupid/:id"
                    component={Edit}
                />
                <Route path="/recommendation/new/:groupid" component={Create} />
                <Route
                    path="/recommendation/view/:groupid/:id"
                    component={View}
                />
                <Route component={RouteNotFound} />
            </Switch>
        </AuthRouter>
    );
};

export { RecommendationRouter };
