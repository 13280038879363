import React from "react";
import { Box } from "UI";
import { ListLeaderboard, OnboardLeaderboard } from "Components";
import { useAuth } from "common/Firebase/Hooks";

import EmptyLeaderboard from "./_EmptyLeaderboard";

export default ({
    id,
    currencySymbolSimple,
    rankByBalance,
    services,
    sortedMembers,
}) => {
    const {
        profile: { hasClosedLeaderboardOnboarding, isLoaded: profileIsLoaded },
    } = useAuth();
    const leaderboardProps = {
        id,
        currencySymbolSimple,
        rankByBalance,
        services,
        sortedMembers,
    };

    return (
        <Box pad={{ bottom: "48px" }}>
            {profileIsLoaded && !hasClosedLeaderboardOnboarding && (
                <OnboardLeaderboard />
            )}
            {sortedMembers.length === 1 ? (
                <EmptyLeaderboard />
            ) : (
                <ListLeaderboard {...leaderboardProps} />
            )}
        </Box>
    );
};
