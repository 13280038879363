import React from "react";
import { Box, Button } from "UI";

import { ListRecommendation } from "Components";
import WelcomeToRecommendations from "./_WelcomeToRecommendations";

export default ({ recommendations, groupid }) => (
    <Box
        pad={{
            top: "large",
            horizontal: "large",
            bottom: "xlarge",
        }}
    >
        {!recommendations || recommendations.length === 0 ? (
            <>
                <WelcomeToRecommendations />
                <Box margin={{ top: "18px" }}>
                    <Button to={`/recommendation/new/${groupid}`}>
                        Add a recommendation
                    </Button>
                </Box>
            </>
        ) : (
            <>
                <Box margin={{ bottom: "18px" }}>
                    <Button
                        to={`/recommendation/new/${groupid}`}
                        type={"primaryFull"}
                    >
                        Add a recommendation
                    </Button>
                </Box>
                <ListRecommendation
                    recommendations={recommendations}
                    groupId={groupid}
                />
            </>
        )}
    </Box>
);
