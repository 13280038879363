import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import { useResponsive } from "Hooks";
import { Button, Box, DefaultLogo, Text } from "UI";

import image1 from "common/images/intro1.jpg";
import image2 from "common/images/intro2.jpg";
import image3 from "common/images/intro3.jpg";
import image4 from "common/images/intro4.jpg";
import screens from "common/images/screens.jpg";
import screensMobile from "common/images/screens-mobile.jpg";

import appleStore from "common/images/badge-ios-app-store.png";
import googleStore from "common/images/badge-google-play-store.png";

const images = [image1, image2, image3, image4];

const SwitchingImageImg = styled.img`
    width: 100%;
    max-width: 536px;
    margin-bottom: 12px;
    height: auto;
`;

const SwitchingImage = () => {
    const index = 0;
    return (
        <>
            <SwitchingImageImg src={images[index]} />
        </>
    );
};

const BadgeImage = styled.img`
    height: 52px;
    @media screen and (max-width: 767px) {
        height: 40px;
    }
`;

const TextBlocks = styled(Box)`
    max-width: 812px;
    margin: 32px auto 0;

    h2 {
        font-size: 1.25rem;
        text-align: center;
    }
`;

const PhoneImage = styled.img`
    display: block;
    margin: 6rem auto 3rem;
    max-width: 1280px;
    width: 100%;
`;

const HomeBox = styled(Box)`
    display: block;
    a:hover {
        opacity: 0.9;
    }
`;

export default () => {
    const { isMobile } = useResponsive();
    const { search } = useLocation();

    return (
        <HomeBox direction="column" pad="24px">
            <Box
                direction="column"
                gap={isMobile ? "24px" : "32px"}
                justify="center"
                align="center"
                pad={isMobile ? "12px 0" : "24px"}
            >
                <SwitchingImage />
                <DefaultLogo style={{ height: "2.6rem" }} />
                <Box gap="4px">
                    <Text textAlign={"center"}>
                        Where everybody knows your password.
                    </Text>
                    <Text textAlign={"center"} color={"rgba(0,0,0,0.5)"}>
                        The simplest shared password manager
                        <br />
                        for iOS, Android and the web.
                    </Text>
                </Box>
                <Box direction={"row"} gap={"16px"}>
                    <a
                        href="https://apps.apple.com/tt/app/sharezies/id1507725958?uo=2"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <BadgeImage src={appleStore} />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.sharezies.sharezies"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <BadgeImage src={googleStore} />
                    </a>
                </Box>
                <Text>
                    <Text color="rgba(0,0,0,0.5)">or</Text>{" "}
                    <Link to={{ pathname: "/account/login", search }}>
                        Sign in online
                    </Link>
                </Text>
            </Box>
            <TextBlocks
                direction={isMobile ? "column" : "row"}
                gap={isMobile ? "24px" : "64px"}
            >
                <Box basis="50%">
                    <h2>
                        Save all your family
                        <br />
                        accounts in one place
                    </h2>
                    <p>
                        You'll never have to reset a password or, even worse,
                        talk your parents through resetting their passwords
                        again. Create a group for your family and keep track of
                        all the passwords, accounts, and memberships that you
                        have together.
                    </p>
                </Box>
                <Box basis="50%">
                    <h2>
                        Split your subscriptions
                        <br />
                        with friends
                    </h2>
                    <p>
                        Start a group with your friends, family, roommates, the
                        Queen of England, or even your co-workers. Then you can
                        pool your online subscription passwords. Groups are
                        private and secure: what happens in the group, stays in
                        the group.
                    </p>
                </Box>
            </TextBlocks>
            <TextBlocks
                direction={isMobile ? "column" : "row"}
                gap={isMobile ? "24px" : "64px"}
            >
                <Box basis="50%">
                    <h2>Free and secure</h2>
                    <p>
                        Sharezies is completely free to use, and has complex
                        encryption to ensure the security of your account
                        details and data: all data is automatically encrypted
                        before it is written to disk, and then automatically and
                        transparently decrypted when read by an authorized user.
                    </p>
                </Box>
                <Box basis="50%">
                    <h2>Track who's paying for what</h2>
                    <p>
                        We’ve made it easy to track and share the cost of
                        subscriptions amongst group members. If one person is
                        contributing more than their fair share, the rest of the
                        group can send them some money to square things up. Or
                        perhaps they just want the bragging rights.
                    </p>
                </Box>
            </TextBlocks>

            <PhoneImage src={isMobile ? screensMobile : screens} />
            <TextBlocks
                gap={isMobile ? "24px" : "64px"}
                direction="row"
                align="center"
                style={{ maxWidth: "406px", textAlign: "center" }}
            >
                <Box>
                    <h2>Why share?</h2>
                    <p>
                        “Sometimes me think, what is friend? And then me say: a
                        friend is someone to share last cookie with.”—Cookie
                        Monster
                    </p>
                    <Box margin="24px 0 0 0">
                        <Button to={{ pathname: "/account/login", search }}>
                            Get started
                        </Button>
                    </Box>
                </Box>
            </TextBlocks>
            <Box
                direction={isMobile ? "column" : "row"}
                justify={"between"}
                align={"center"}
                margin={"8rem auto 0"}
                style={{
                    maxWidth: "812px",
                    width: "100%",
                    textAlign: isMobile ? "center" : "left",
                }}
            >
                <Box>
                    <Text color="rgba(0,0,0,0.7)" size="0.85rem">
                        © 2020 Sharezies Pty Ltd. Illustrations by{" "}
                        <a
                            href="https://www.rebeccaclarke.info/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Rebecca Clarke
                        </a>
                    </Text>
                </Box>
                <Box direction="row">
                    <Text color="rgba(0,0,0,0.7)" size="0.85rem">
                        <Link to="/about/support">Support</Link> •{" "}
                        <Link to="/about/terms">Terms</Link> •{" "}
                        <Link to="/about/privacy">Privacy</Link>
                    </Text>
                </Box>
            </Box>
        </HomeBox>
    );
};
