import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppHeader, Page } from "UI";
import { ButtonBack, UsersManage } from "Components";
import { UsersContext } from "common/Components";

const UsersEdit = () => {
    const { users, addUser, removeUser } = useContext(UsersContext);
    const [emailToAdd, setEmailToAdd] = useState("");

    const handleAddUser = (email) => {
        if (email && email !== "") {
            addUser({ email: email, role: "member" });
            setEmailToAdd("");
        }
    };

    return (
        <>
            <AppHeader
                back={<ButtonBack />}
                next={<Link to={`/group/new/details`}>Next</Link>}
                title={"Add members"}
            />
            <Page>
                <UsersManage
                    emailToAdd={emailToAdd}
                    handleAddUser={handleAddUser}
                    removeUser={removeUser}
                    setEmailToAdd={setEmailToAdd}
                    users={users}
                />
            </Page>
        </>
    );
};

export default UsersEdit;
