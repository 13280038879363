import React, { useEffect, useState, useMemo } from "react";
import { useAuth, useGroups } from "common/Firebase/Hooks";
import { formatDisplayName } from "common/formatters";
import useInvitations from "common/Firebase/Hooks/useInvitations";
import { useFirebase } from "react-redux-firebase";

export const NEW_USER = "NEW_USER";
export const NEW_USER_WITH_INVITE = "NEW_USER_WITH_INVITE";
export const NORMAL_USER = "NORMAL USER";
export const NORMAL_USER_WITHOUT_NOTIFICATION_PREF =
    "NORMAL_USER_WITHOUT_NOTIFICATION_PREF";

/**
 *
 * @param {string} [queryInviteId] - if present, will be used for an additional preview
 * @param {string} [queryGroupId] - if present, will be used for an additional preview
 * @returns {{hasClosedOnboarding, firstName, newGroups: Object[], profileIsLoaded: boolean, joinedGroups: Object[], isLoaded: boolean, newUserStatus: string}}
 */
export default function useDashboard({ queryInviteId, queryGroupId } = {}) {
    const firebase = useFirebase();
    const {
        displayName,
        email,
        uid,
        profile: { hasClosedOnboarding, isLoaded: profileIsLoaded },
    } = useAuth();

    const {
        joinedGroups = [],
        newGroups: oldNewGroups = [],
        isLoaded,
    } = useGroups(email);

    const { isLoaded: isInvitationsLoaded, invitations } = useInvitations({
        email,
        uid,
    });
    const [newGroups, setNewGroups] = useState([]);

    // TODO: better loading handling
    const firstName = formatDisplayName(displayName, email) || displayName;

    useEffect(() => {
        let isCancelled = false;

        const loadInvitations = async () => {
            if (!isInvitationsLoaded) return;
            //To avoid duplicating loaded invitations and query param invitation, combine
            const invitationsByGroup = Object.entries(
                invitations
            ).map(([inviteId, { groupId }]) => ({ groupId, inviteId }));
            const queryInvitation =
                queryInviteId && queryGroupId
                    ? [{ groupId: queryGroupId, inviteId: queryInviteId }]
                    : [];

            const combinedInvitationsByGroup = [
                ...invitationsByGroup,
                ...queryInvitation,
            ];

            const getPreviews = async () =>
                Promise.all(
                    combinedInvitationsByGroup.map(({ groupId, inviteId }) =>
                        firebase
                            .functions()
                            .httpsCallable("previewGroup")({
                                inviteId,
                                groupId,
                            })
                            .then((result) => ({
                                createdOn: firebase.firestore.Timestamp.now(), // Provide a default value to ensure sorting without errors
                                ...result.data,
                                id: groupId,
                                inviteId,
                            }))
                            .catch((err) => {
                                console.warn(err);
                                return null;
                            })
                    )
                );
            try {
                const previews = (await getPreviews()).filter(
                    (p) => p !== null
                );
                if (isCancelled) return;
                setNewGroups(previews);
                console.log("group previews updated:", previews);
            } catch (err) {
                if (isCancelled) return;
                console.error("Error while fetching group previews:", err);
            }
        };
        loadInvitations();

        // Cleanup to stop state update on unmounted component
        return () => {
            isCancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitations, queryGroupId, queryInviteId]);

    const combinedNewGroups = useMemo(
        () => [...oldNewGroups, ...newGroups],
        oldNewGroups,
        newGroups
    );

    const newUserStatus =
        joinedGroups.length === 0
            ? combinedNewGroups.length === 0
                ? NEW_USER
                : NEW_USER_WITH_INVITE
            : NORMAL_USER;

    return {
        firstName,
        newGroups: combinedNewGroups,
        newUserStatus,
        joinedGroups,
        profileIsLoaded,
        hasClosedOnboarding,
        isLoaded,
    };
}
