import React from "react";

import { ButtonBack } from "Components";
import { FormField, TextInput, TextArea, Select } from "grommet";
import {
    Box,
    AppHeader,
    Page,
    SaveOverlay,
    LoadingScreen,
    Spinner,
    Text,
} from "UI";
import { recommendationTypes } from "common/helpers";

const FormRecommendation = ({
    groupid,
    groupName = "Group",
    handleUrlBlur,
    handleSave,
    hasBeenEdited,
    id,
    inputUrl,
    isDataLoaded,
    isLoadingUrl,
    isNewRecommendation,
    isSaving,
    name,
    relatedService,
    services,
    setHasBeenEdited,
    setInputUrl,
    setName,
    setRelatedService,
    setTestimonial,
    setType,
    siteIcon,
    testimonial,
    title,
    type,
}) => {
    const TypeIcon =
        recommendationTypes[type] && recommendationTypes[type].icon;
    const typeLabel =
        recommendationTypes[type] && recommendationTypes[type].label;
    const relatedServiceName =
        services && relatedService && services[relatedService].name;

    return !isDataLoaded ? (
        <LoadingScreen />
    ) : (
        <>
            <AppHeader
                title={title}
                titleDesktop={
                    isNewRecommendation
                        ? "Create Recommendation"
                        : "Edit Recommendation"
                }
                crumbGrandparent={{
                    text: groupName /* TODO: pass group name through */,
                    link: `/group/view/${groupid}`,
                }}
                crumbParent={
                    !isNewRecommendation && {
                        text: name,
                        link: `/recommendation/view/${groupid}/${id}`,
                    }
                }
                back={<ButtonBack />}
                next={
                    <div style={{ cursor: "pointer" }} onClick={handleSave}>
                        Save
                    </div>
                }
            />
            <Page gap="small" pad="none">
                <Box pad={"24px"}>
                    <FormField
                        label={recommendationTypes[type].urlLabel}
                        style={{ position: "relative" }}
                    >
                        <TextInput
                            id={"url-input"}
                            onChange={(e) => setInputUrl(e.target.value)}
                            onBlur={(e) => handleUrlBlur()}
                            placeholder="website.com"
                            value={inputUrl || ""}
                            autoCapitalize="off"
                            autoCorrect="off"
                            autoComplete="off"
                        />
                        {(isLoadingUrl || siteIcon) && (
                            <Box
                                height="47px"
                                width="47px"
                                align="center"
                                justify="center"
                                background={"white"}
                                style={{
                                    position: "absolute",
                                    right: "1px",
                                    top: "33px",
                                }}
                            >
                                {isLoadingUrl ? (
                                    <Spinner />
                                ) : (
                                    <img
                                        src={siteIcon}
                                        width="16"
                                        height="16"
                                        alt={"Site icon"}
                                    />
                                )}
                            </Box>
                        )}
                    </FormField>

                    <FormField label="Type">
                        <Select
                            options={Object.keys(recommendationTypes)}
                            value={
                                <Box
                                    pad="12px 12px 12px 16px"
                                    gap="16px"
                                    direction="row"
                                >
                                    <TypeIcon color="#888" />
                                    {typeLabel}
                                </Box>
                            }
                            onChange={({ option }) => setType(option)}
                            children={(option) => {
                                const Icon = recommendationTypes[option].icon;
                                return (
                                    <Box
                                        pad="12px 12px 12px 16px"
                                        gap="16px"
                                        direction="row"
                                    >
                                        <Icon color="#888" />
                                        {recommendationTypes[option].label}
                                    </Box>
                                );
                            }}
                        />
                    </FormField>
                    <FormField
                        label="Name of recommendation"
                        style={{ position: "relative" }}
                    >
                        <TextInput
                            id={"name-input"}
                            required
                            onChange={(e) => setName(e.target.value)}
                            onBlur={() =>
                                setHasBeenEdited({
                                    ...hasBeenEdited,
                                    name: true,
                                })
                            }
                            placeholder="Pet Sounds"
                            value={name || ""}
                            maxLength={45}
                            minLength={1}
                        />
                    </FormField>

                    {services && Object.keys(services).length && (
                        <Box style={{ position: "relative" }}>
                            <FormField
                                label={`Related Service ${
                                    !relatedService ? `(if applicable)` : ""
                                }`}
                            >
                                <Select
                                    options={Object.keys(services)}
                                    value={relatedService}
                                    valueLabel={
                                        relatedService ? (
                                            <Box
                                                pad="12px 12px 12px 16px"
                                                gap="16px"
                                                direction="row"
                                            >
                                                {relatedServiceName}
                                            </Box>
                                        ) : (
                                            false
                                        )
                                    }
                                    onChange={({ option, ...props }) => {
                                        return setRelatedService(option);
                                    }}
                                    placeholder={"Choose a service"}
                                    children={(option) => {
                                        return (
                                            <Box
                                                pad="12px 12px 12px 16px"
                                                gap="16px"
                                                direction="row"
                                            >
                                                {services[option].name}
                                            </Box>
                                        );
                                    }}
                                />
                            </FormField>
                            {relatedService && (
                                <Box
                                    style={{
                                        position: "absolute",
                                        right: 0,
                                        top: "5px",
                                    }}
                                >
                                    <Text
                                        size="14px"
                                        color="dark-3"
                                        onClick={() => setRelatedService(null)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Remove selected service
                                    </Text>
                                </Box>
                            )}
                        </Box>
                    )}

                    <FormField label="Your testimonial">
                        <TextArea
                            onChange={(e) => setTestimonial(e.target.value)}
                            placeholder={
                                "Why are you recommending this to your group?"
                            }
                            rows="7"
                            value={testimonial || ""}
                        />
                    </FormField>
                </Box>

                {isSaving && <SaveOverlay />}
            </Page>
        </>
    );
};

export default FormRecommendation;
