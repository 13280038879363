import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Box, Text } from "UI";

const FooterBox = styled(Box)`
    a {
        color: #666;
        &:hover {
            color: #555;
        }
    }
`;

export default ({ margin }) => (
    <FooterBox
        margin={margin || { top: "xlarge", bottom: "medium" }}
        align="center"
        justify="center"
    >
        <Text size="small" color="dark-4">
            © {new Date().getFullYear()} Sharezies •{" "}
            <Link to="/about/terms">Terms</Link> •{" "}
            <Link to="/about/privacy">Privacy</Link>
        </Text>
    </FooterBox>
);
