import { logEvent } from "../Analytics";
import { default as formatStorageUrl } from "common/formatters/formatStorageUrl";
import { getFirebase } from "react-redux-firebase";
import { deleteService } from "./services";
import { deleteGroup } from "./groups";

/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {object} user object containing uid and email items
 * @param {object} userGroups groups object from useGroups hook
 */
const deleteAccount = async (firestore, user, userGroups) => {
    logEvent("delete_account");

    const firebase = getFirebase();

    // 1. Loop through groups and delete all services you are an owner of
    await Promise.all(
        userGroups.map(async (group) => {
            const groupSnapshot = await firestore
                .collection("groups")
                .doc(group.id);

            const servicesSnapshot = await groupSnapshot
                .collection("services")
                .get();

            const services = servicesSnapshot.empty
                ? []
                : servicesSnapshot.docs;

            // loop through services
            await Promise.all(
                Object.values(services).map(async (serviceSnapshot) => {
                    const service = serviceSnapshot.data();
                    // if owner is user email, delete
                    if (service.owner === user.email) {
                        console.log(group.id, serviceSnapshot.id);
                        deleteService(firestore, group.id, serviceSnapshot.id);
                    }
                })
            );

            // 2. Delete group if owner, otherwise remove user from roles

            if (group.roles[user.email].role === "owner") {
                // delete group altogether
                await deleteGroup(firestore, group.id);
            } else {
                // remove user from group
                const newRoles = { ...group.roles };
                delete newRoles[user.email];
                await groupSnapshot.update({ roles: newRoles });
            }
        })
    );

    // 3. Delete user
    await firestore.collection("users").doc(user.uid).delete();
    const userAuth = firebase.auth().currentUser;
    return await userAuth.delete();
};
/**
 *
 * @param {firebase} firebase
 * @param {Object} update
 */
const editProfile = (firebase, update) => {
    return firebase.updateProfile(update);
};
/**
 *
 * @param {firebase} firebase
 * @param {string} userId
 * @param {File} imageObject
 */
const uploadAvatar = async (firebase, userId, imageObject) => {
    logEvent("upload_avatar");
    const filePath = `/users/${userId}/`;
    const image = await firebase.uploadFile(filePath, imageObject);
    if (image.uploadTaskSnapshot.state === "success") {
        return firebase.updateProfile({
            photoURL: formatStorageUrl(
                image.uploadTaskSnapshot.metadata.fullPath
            ),
        });
    } else {
        return false;
    }
};

/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} uid
 * @param {Object} data
 */
const updateUserPrivateData = async (firestore, uid, data) => {
    return firestore.collection("usersPrivate").doc(uid).set(data);
};

export { uploadAvatar, deleteAccount, editProfile, updateUserPrivateData };
