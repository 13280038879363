import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Globe } from "grommet-icons";
import { formatDisplayName, formatStorageUrl } from "common/formatters";
import { useUsers } from "common/Firebase/Hooks";
import { Box, Text } from "UI";

const ServiceBox = styled(Box)`
    &:hover {
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
        &:hover {
            border-color: #e7e7e7;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02),
                0px 4px 8px rgba(0, 0, 0, 0.04);
        }
        transition: 0.2s all ease;
    }
`;

export default ({ services, groupId }) => {
    return (
        <Box margin={{ top: "none", bottom: "medium" }}>
            {services &&
                Object.keys(services).map((sId, index) => {
                    if (!services[sId]) {
                        return false;
                    }
                    const service = services[sId];
                    return (
                        <ServiceListItem
                            key={sId}
                            {...service}
                            url={`/service/view/${groupId}/${sId}`}
                        />
                    );
                })}
        </Box>
    );
};

const ServiceListItem = ({ url, name, owner, serviceImage }) => {
    const { users } = useUsers([owner]);
    const userDetails = users && users[owner];
    const userName = userDetails
        ? formatDisplayName(userDetails.name, userDetails.email)
        : "";
    return (
        <Link to={url} style={{ marginBottom: "8px" }}>
            <ServiceBox
                round={"12px"}
                border={{
                    color: "#eee",
                    size: "2px",
                    side: "all",
                }}
                pad={{ vertical: "16px", horizontal: "medium" }}
            >
                <Box
                    direction="row"
                    justify={"between"}
                    align="center"
                    gap="xsmall"
                >
                    <Box direction="row" align="center" justify="center">
                        {serviceImage ? (
                            <img
                                src={`${formatStorageUrl(serviceImage)}`}
                                width="16"
                                height="16"
                                alt=""
                                style={{
                                    verticalAlign: "bottom",
                                    marginRight: "12px",
                                    transform: "translateY(2px)",
                                }}
                            />
                        ) : (
                            <Box margin={{ right: "12px" }}>
                                <Globe
                                    color={"#ccc"}
                                    size={"16px"}
                                    style={{ transform: "translateY(2px)" }}
                                />
                            </Box>
                        )}
                        <Text color="black" weight={600} size="18px">
                            {name}
                        </Text>
                    </Box>
                    <Text size="14px" color="dark-3">
                        {userName && `${userName}`}
                    </Text>
                </Box>
            </ServiceBox>
        </Link>
    );
};
