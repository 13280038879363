import React from "react";
import { Avatar, Box, Text } from "UI";
import { Close } from "grommet-icons";
import { useAuth, useUsers } from "common/Firebase/Hooks";

const UserRow = ({ email, name, photoURL, removeAction, role }) => {
    return (
        <Box direction="row" gap="small" align="center">
            <Box>
                <Avatar photoURL={photoURL} email={email} />
            </Box>
            <Box direction="column" basis="full">
                <Box>
                    <Text>{name || email}</Text>
                </Box>
                <Box>
                    <Text size="small" color="dark-4">
                        {role === "owner" && "Owner"}
                        {role === "member" && "Member"}
                        {role === "invited" && "Invited"}
                    </Text>
                </Box>
            </Box>
            {removeAction && (
                <Box onClick={removeAction}>
                    <Close size="16px" color="dark-4" />
                </Box>
            )}
        </Box>
    );
};

export default ({ users: groupUsers, removeAction }) => {
    const { users: allUsers, isLoaded } = useUsers(
        groupUsers.map((user) => user.email)
    );
    const currentUser = useAuth();
    // TODO: sort by owner first or by number of accounts shared
    return groupUsers && isLoaded ? (
        <>
            <Box gap="medium">
                {groupUsers.map((groupUser) => {
                    return (
                        <UserRow
                            key={groupUser.email}
                            // no removeAction if user is owner or currently logged in
                            removeAction={
                                groupUser.role === "owner" ||
                                groupUser.email === currentUser.email
                                    ? false
                                    : () =>
                                          removeAction(
                                              allUsers[groupUser.email]
                                                  ? allUsers[groupUser.email]
                                                        .email
                                                  : groupUser.email
                                          )
                            }
                            {...groupUser}
                            {...allUsers[groupUser.email]}
                        />
                    );
                })}
            </Box>
        </>
    ) : null;
};
