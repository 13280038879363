import randomNameBands from "./randomNameBands.json";
import randomMottos from "./randomMottos.json";

/**
 * Returns a single random element of the array provided
 *
 * @param {array} randomArray - array of items to choose from
 * @returns {any} the single random element
 */
const randomPicker = (randomArray) => {
    const d = new Date();
    const minuteOfDay = d.getHours() * 60 + d.getMinutes();
    const randomNumber = minuteOfDay % randomArray.length;
    return randomArray[randomNumber];
};

const randomBandName = () => randomPicker(randomNameBands);
const randomMotto = () => randomPicker(randomMottos);

export { randomBandName, randomMotto };
