import app from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

import firebaseConfig from "./firebaseConfig";
import { isNativeApp } from "../../helpers";

if (!window || !window.addEventListener) {
    // fix for https://stackoverflow.com/questions/61429599/error-cloud-firestore-addeventlistener-is-not-a-function-react-native-firestor
    window.addEventListener = (x) => x;
}

app.initializeApp(firebaseConfig);
const db = app.firestore();

if (isNativeApp()) {
    // Native-specific initialisations
    window = undefined;
} else {
    require("firebase/analytics");
    app.analytics();
}

if (
    !isNativeApp() &&
    window &&
    window.location &&
    window.location.hostname === "localhost" &&
    process.env.REACT_APP_USE_LOCAL_DB === "true"
) {
    // don't forget to start the DB/functions emulators: `firebase emulators:start`
    console.log("Using local DB!");
    db.settings({
        host: "localhost:8080",
        ssl: false,
    });
}

export default app;
