import { useState, useEffect, useMemo } from "react";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

/**
 *
 * @param {string} email
 * @param {string} uid
 */
export default function useInvitations({ email, uid }) {
    useFirestoreConnect([
        {
            collectionGroup: "invitations",
            where: ["email", "==", email],
            storeAs: "invitationsEmail",
        },
        {
            collectionGroup: "invitations",
            where: ["uid", "==", uid],
            storeAs: "invitationsUid",
        },
    ]);
    const { invitationsEmail, invitationsUid } = useSelector(
        ({ firestore }) => firestore.data
    );
    const invitationsIsLoaded = useMemo(
        () => isLoaded(invitationsEmail) && isLoaded(invitationsUid),
        [invitationsEmail, invitationsUid]
    );

    const [invitations, setInvitations] = useState(
        invitationsIsLoaded ? { ...invitationsEmail, ...invitationsUid } : {}
    );

    useEffect(() => {
        if (!invitationsIsLoaded) return;
        setInvitations({ ...invitationsEmail, ...invitationsUid });
    }, [invitationsEmail, invitationsUid]);

    return {
        invitations,
        isLoaded: invitationsIsLoaded,
    };
}
