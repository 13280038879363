import { useEffect, useState, useMemo } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useAuth, useGroup } from "../../Firebase/Hooks";
import currencyData from "../../helpers/currencies.json";
import { formatStorageUrl } from "../../formatters";
import {
    addInvitationToFirestore,
    deleteGroup,
    deleteInvitationFromFirestore,
    editGroup,
    leaveGroup,
    uploadGroupImage,
} from "../../Firebase/Actions";
import {
    addInvitation,
    getInviteIdToCancel,
    removeUserFromRoles,
} from "../FormGroups";

const currencyOptions = Object.keys(currencyData);

/**
 *
 * @param {string} id
 * @param {function(): void} redirectGroup
 * @param {function(): void} redirectHome
 * @param {function(target: unknown): Promise<File>}getFileFromTarget
 * @param {function(message: string): void } handleNotifySuccess
 * @param {function(message: string): void } handleNotifyError
 * @param {function(message: string, title: string?): Promise<boolean>} handleConfirm
 * @param {function(groupName: string?): boolean} checkGroupNameValid
 * @param {function(): void} handleGroupNameNotValid
 */
export default function useGroupEdit({
    id,
    redirectGroup,
    redirectHome,
    getFileFromTarget,
    handleNotifySuccess,
    handleNotifyError,
    handleConfirm,
    checkGroupNameValid,
    handleGroupNameNotValid,
}) {
    const { email } = useAuth();
    const { group, invitations: reduxInvitations, isLoaded } = useGroup(id);
    const firebase = useFirebase();
    const firestore = useFirestore();

    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLeaving, setIsLeaving] = useState(false);
    const [groupMotto, setGroupMotto] = useState("");
    const [groupName, setGroupName] = useState("");
    const [groupCurrency, setGroupCurrency] = useState("USD");
    const [groupImageTarget, setGroupImageTarget] = useState(null);
    const [newEmail, setNewEmail] = useState("");
    const [roles, setRoles] = useState(group ? group.roles : {});
    const [localInvitations, setLocalInvitations] = useState(
        reduxInvitations ?? {}
    );
    const isOwner = roles[email]?.role === "owner";

    const users = useMemo(() => {
        const baseEmailRoleMap = Object.entries(roles).reduce(
            (acc, [email, { role }]) => ({ ...acc, [email]: role }),
            {}
        );
        const invitedEmailRoleMap = Object.values(localInvitations)
            .filter((invitation) => !!invitation?.email)
            .reduce(
                (acc, invitation) => ({
                    ...acc,
                    [invitation.email]: "invited",
                }),
                {}
            );
        const mergedEmailRoleMap = {
            ...baseEmailRoleMap,
            ...invitedEmailRoleMap,
        };
        console.log(mergedEmailRoleMap);
        return Object.entries(mergedEmailRoleMap).reduce(
            (acc, [email, role]) => [...acc, { email, role }],
            []
        );
    }, [roles, localInvitations]);

    // Update local state when Redux Firestore state changes
    useEffect(() => {
        setGroupMotto(group ? group.motto : "");
        setGroupName(group ? group.name : "");
        setGroupCurrency(group ? group.currency : "USD");
        if (group && group.roles) {
            setRoles(group.roles);
        }
    }, [group]);
    useEffect(() => {
        setLocalInvitations(reduxInvitations ?? {});
    }, [reduxInvitations]);

    /**
     * Update local invitations state and try to commit changes to Firestore, rolling back
     * local state changes if this fails.
     * @param newInvitations
     * @param {function(): Promise<void>} firestoreUpdate
     */
    const updateInvitationsWithRollback = (newInvitations, firestoreUpdate) => {
        // Update local state first
        const oldInvitations = { ...localInvitations };
        setLocalInvitations(newInvitations);
        firestoreUpdate().catch((err) => {
            // If adding to Firestore failed, rollback
            console.error(err);
            setLocalInvitations(oldInvitations);
        });
    };

    const handleDelete = async () => {
        if (!isOwner) return;
        if (
            await handleConfirm(
                `Are you sure you want to delete the ${groupName} group?`,
                "Delete Group"
            )
        ) {
            try {
                setIsDeleting(true);
                await deleteGroup(firestore, id);
                redirectHome();
                handleNotifySuccess(`${groupName} has been deleted.`);
            } catch (err) {
                console.warn(err);
                handleNotifyError(
                    `Sorry, we ran into an error when trying to delete ${groupName}. Please try again.`
                );
                setIsDeleting(false);
            }
        }
    };

    const handleLeave = async () => {
        if (
            await handleConfirm(
                `Are you sure you want to leave the ${groupName} group?`,
                "Leave Group"
            )
        ) {
            try {
                setIsLeaving(true);
                await leaveGroup(firestore, id, email);
                redirectHome();
                handleNotifySuccess(`You have left ${groupName}.`);
            } catch (err) {
                console.warn(err);
                handleNotifyError(
                    `Sorry, we ran into an error when trying to leave ${groupName}. Please try again.`
                );
                setIsLeaving(false);
            }
        }
    };

    const handleSave = async () => {
        const isNameValid = checkGroupNameValid(groupName);
        if (!isNameValid) {
            handleGroupNameNotValid();
            return;
        }
        try {
            setIsSaving(true);
            await editGroup(firestore, id, {
                ...group,
                name: groupName,
                motto: groupMotto,
                currency: groupCurrency,
                currencySymbol: currencyData[groupCurrency].symbol,
                currencySymbolSimple: currencyData[groupCurrency].symbol_native,
                roles,
            });
            if (groupImageTarget) {
                const blob = await getFileFromTarget(groupImageTarget);
                await uploadGroupImage(firestore, firebase, id, blob);
            }
            redirectGroup();
            handleNotifySuccess(`Changes to ${groupName} saved.`);
        } catch (err) {
            console.warn(err);
            handleNotifyError(
                `Sorry, we ran into an error when trying to edit ${groupName}. Please try again.`
            );
            setIsSaving(false);
        }
    };

    const handleAddUser = async (newEmailOverride) => {
        const result = await addInvitation(
            firebase,
            firestore,
            {
                groupId: id,
                email: newEmailOverride || newEmail,
                addedBy: email,
            },
            roles,
            localInvitations
        );
        if (!result) return;
        const { invitationRef, invitation } = result;
        const newInvitations = {
            ...localInvitations,
            [invitationRef.id]: invitation,
        };
        updateInvitationsWithRollback(newInvitations, () =>
            addInvitationToFirestore(firestore, invitationRef, invitation)
        );
    };

    /** Remove the specified user from the group roles/invitations. */
    const handleRemoveUser = async (user) => {
        const emailToRemove = user.email || user;
        let confirmMessage;
        let confirmTitle;
        /** @type {function(): void} */
        let removeHandler;
        if (roles.hasOwnProperty(emailToRemove)) {
            // Remove a full member from the group
            confirmMessage = `Are you sure you want to remove ${
                user.name || emailToRemove
            } from this group?`;
            confirmTitle = "Remove User";
            removeHandler = () =>
                setRoles(removeUserFromRoles(roles, emailToRemove));
        } else if (
            Object.values(localInvitations).some(
                (invitation) => invitation.email === emailToRemove
            )
        ) {
            // Cancel an invitation
            confirmMessage = `Are you sure you want to cancel an invitation to ${
                user.name || emailToRemove
            }?`;
            confirmTitle = "Cancel Invitation";
            removeHandler = () => {
                const inviteIdToRemove = getInviteIdToCancel(
                    localInvitations,
                    emailToRemove
                );
                const {
                    [inviteIdToRemove]: _,
                    ...newInvitations
                } = localInvitations;
                updateInvitationsWithRollback(newInvitations, () =>
                    deleteInvitationFromFirestore(
                        firestore,
                        id,
                        inviteIdToRemove
                    )
                );
            };
        } else {
            // Something weird has happened
            console.warn(
                `No entries for emailToRemove ${emailToRemove} found in roles or invitations`
            );
            return;
        }

        if (await handleConfirm(confirmMessage, confirmTitle)) {
            return removeHandler();
        }
    };

    return {
        isLoaded,
        groupImageTarget,
        groupImageUrl:
            group && group.image && formatStorageUrl(group.image.gsPath),
        groupMotto,
        groupName,
        groupCurrency,
        currencyOptions,
        handleAddUser,
        handleDelete,
        handleLeave,
        handleRemoveUser,
        handleSave,
        id,
        isDeleting,
        isLeaving,
        isSaving,
        newEmail,
        users,
        isOwner,
        setGroupImageTarget,
        setGroupMotto,
        setGroupName,
        setGroupCurrency,
        setNewEmail,
        userEmail: email,
    };
}
