import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Logo from "common/images/logo.svg";

const LogoImg = styled.img`
    height: 1.25rem;
    width: auto;
    position: relative;
    top: 2px;
`;

export default ({ style }) => (
    <>
        <Link to="/">
            <LogoImg style={style} src={Logo} alt="Sharezies" />
        </Link>
    </>
);
