import React from "react";

import { ListService } from "Components";
import { Box, Text } from "UI";

import { NORMAL_GROUP, EMPTY_GROUP } from "../View";

import AddGroupSection from "./_AddGroupSection";
import WelcomeToGroup from "./_WelcomeToGroup";

export default ({ id, groupStatus, roles, services, motto }) => (
    <Box
        pad={{
            top: "large",
            horizontal: "large",
            bottom: "xlarge",
        }}
    >
        {/* groupStatus === NORMAL_GROUP && roles && (
            <ListUserRow emails={Object.keys(roles)} />
        ) */}
        {groupStatus === NORMAL_GROUP && (
            <>
                {motto && motto !== "" && (
                    <Box
                        margin={{ bottom: "24px" }}
                        direction="row"
                        align="center"
                    >
                        <Box grow="1" shrink="1" basis="100%" pad="8px">
                            <Text weight={700} size="20px" textAlign="center">
                                “{motto}”
                            </Text>
                        </Box>
                    </Box>
                )}
                <ListService services={services} groupId={id} />
            </>
        )}

        {groupStatus === EMPTY_GROUP && <WelcomeToGroup />}

        <AddGroupSection id={id} />
    </Box>
);
