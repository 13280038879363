import React from "react";
import { Route, Switch } from "react-router-dom";

import { isLoaded } from "react-redux-firebase";

import { useAuth } from "common/Firebase/Hooks";

import { ContextQuery } from "Components";

import { AboutRouter } from "./AboutRouter";
import { AccountRouter } from "./AccountRouter";
import { GroupRouter } from "./GroupRouter";
import { HomeRouter } from "./HomeRouter";
import { RecommendationRouter } from "./RecommendationRouter";
import { ServiceRouter } from "./ServiceRouter";
import { RouteNotFound } from "Pages/404";

import { Page, LoadingScreen } from "UI";
import { Preview } from "../Pages/Group/Preview";

const MainRouterRouter = () => {
    const auth = useAuth();
    return (
        <>
            <ContextQuery>
                {isLoaded(auth) ? (
                    <Switch>
                        <Route path="/" exact component={HomeRouter} />
                        <Route path="/about/" component={AboutRouter} />
                        <Route path="/account/" component={AccountRouter} />
                        <Route
                            exact
                            path="/group/preview/:id"
                            component={Preview}
                        />
                        <Route path="/group/" component={GroupRouter} />
                        <Route
                            path="/recommendation/"
                            component={RecommendationRouter}
                        />
                        <Route path="/service/" component={ServiceRouter} />
                        <Route component={RouteNotFound} />
                    </Switch>
                ) : (
                    <Page>
                        <LoadingScreen />
                    </Page>
                )}
            </ContextQuery>
        </>
    );
};

export const MainRouter = MainRouterRouter;
