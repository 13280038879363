import React from "react";
import { Box, Layer, Spinner } from "UI";

const LoadingScreen = () => (
    <Layer animate={false} animation={false} full plain>
        <Box
            fill
            background={"rgba(255,255,255,0)"}
            align="center"
            justify="center"
        >
            <Spinner />
        </Box>
    </Layer>
);

export default LoadingScreen;
