import React from "react";
import "toasted-notes/src/styles.css";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *, input {
        box-sizing: border-box !important;
    }
    html {
        font-size: 112.5%;
        background-color: #fcfcfc;
    }

    a { 
        text-decoration: none;
        color: inherit;
    }

    p, ul, ol {
        margin: 0 0 1rem 0;
    }
    
    @keyframes ButtonMoving {
        0% {
            background-position: 0% 13%;
        }
        50% {
            background-position: 100% 88%;
        }
        100% {
            background-position: 0% 13%;
        }
    }

    body .Toaster__alert {
        background: #333;
        color: white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08),
                0px 4px 8px rgba(0, 0, 0, 0.16);
        padding: 16px 48px 16px 24px;
        border-radius: 20px;
    }

    body .Toaster__alert_text {
        color: inherit;
        font-family: 'acumin-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    body .Toaster__alert_close {
        filter: invert(1);
    }
    button {
        outline: none !important;
        box-shadow: none !important;
    }
    @-webkit-keyframes Background {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }
    @-moz-keyframes Background {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }
    @keyframes Background {
        0%{background-position:0% 50%}
        50%{background-position:100% 50%}
        100%{background-position:0% 50%}
    }

    [class^="StyledLayer__StyledOverlay"] {
        background-size: 800% 800%;
        animation: Background 60s ease infinite;
        position:absolute !important;
        opacity: 0.75;
    }
`;

export default () => (
    <>
        <GlobalStyle />
    </>
);
