import { useSelector } from "react-redux";

export default () => {
    const auth = useSelector(({ firebase: { auth, profile } }) => ({
        ...auth,
        profile,
        isLoggedIn: !!auth.email || (auth.isLoaded && !auth.isEmpty),
    }));
    return auth;
};
