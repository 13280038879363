import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { OnboardGeneral } from "Components";

import {
    FooterNormal,
    ImageDash,
    ListGroup,
    ListGroupInvited,
} from "Components";
import { AppHeader, Box, Button, Heading, LoadingScreen, Page } from "UI";
import { QueryContext } from "../../../Components/ContextQuery";
import queryString from "query-string";
import useDashboard, {
    NEW_USER,
    NEW_USER_WITH_INVITE,
    NORMAL_USER,
} from "common/Components/Hooks/useDashboard";

const Dashboard = () => {
    // If query params for preview are present, add this group to the preview
    const { search } = useLocation();
    const { queries } = useContext(QueryContext);
    const searchParams = queryString.parse(search);
    const queryInviteId = searchParams.secret ?? queries.secret ?? null;
    const queryGroupId = searchParams.groupId ?? queries.groupId ?? null;

    const {
        isLoaded,
        firstName,
        newUserStatus,
        newGroups,
        joinedGroups,
        profileIsLoaded,
        hasClosedOnboarding,
    } = useDashboard({
        queryInviteId,
        queryGroupId,
    });

    if (!isLoaded) {
        return (
            <>
                <AppHeader title={false} titleDesktop={"Home"} />
                <LoadingScreen />
            </>
        );
    }

    return (
        <DashboardUI
            displayName={firstName}
            newGroups={newGroups}
            newUserStatus={newUserStatus}
            joinedGroups={joinedGroups}
            hasClosedOnboarding={
                !profileIsLoaded || (profileIsLoaded && hasClosedOnboarding)
            }
        />
    );
};

const DashboardUI = ({
    displayName,
    newGroups,
    newUserStatus,
    joinedGroups,
    hasClosedOnboarding,
}) => (
    <>
        <AppHeader
            next={<></>}
            title={false}
            titleDesktop={"Home"}
            titleOverride={"Home"}
        />

        <Page>
            {!hasClosedOnboarding && <OnboardGeneral />}

            {newUserStatus === NEW_USER && (
                <NewUserScreen displayName={displayName} />
            )}

            {newUserStatus === NEW_USER_WITH_INVITE && (
                <NewUserWithInviteScreen
                    displayName={displayName}
                    newGroups={newGroups}
                />
            )}
            {newUserStatus === NORMAL_USER && (
                <NormalUserScreen
                    joinedGroups={joinedGroups}
                    newGroups={newGroups}
                />
            )}
            <FooterNormal />
        </Page>
    </>
);

const NewUserScreen = ({ displayName }) => (
    <Box margin={{ bottom: "medium" }}>
        <ImageDash />
        <Heading level="2" margin={{ bottom: "small" }} textAlign="center">
            Welcome {displayName}!
        </Heading>
        <Heading
            level="3"
            margin={{ top: "xsmall" }}
            style={{ marginLeft: "auto", marginRight: "auto" }}
            textAlign="center"
        >
            Start by creating a group
            <br />
            to share with your family/friends.
        </Heading>
        <AddGroup />
    </Box>
);

const NewUserWithInviteScreen = ({ displayName, newGroups }) => (
    <Box margin={{ bottom: "medium" }}>
        <ImageDash />
        <Heading level="2" margin={{ bottom: "small" }} textAlign="center">
            Welcome {displayName}!
        </Heading>
        <Heading level="3" margin={{ top: "xsmall" }} textAlign="center">
            You've already been invited
            <br />
            to your first group:
        </Heading>
        {newGroups && <ListGroupInvited groups={newGroups} />}
        <Heading level="3" textAlign="center" margin={{ top: "xsmall" }}>
            or
        </Heading>
        <AddGroup />
    </Box>
);

const NormalUserScreen = ({ newGroups, joinedGroups }) => (
    <>
        {newGroups && <ListGroupInvited groups={newGroups} />}
        {joinedGroups && <ListGroup groups={joinedGroups} />}
        <AddGroup />
    </>
);

const AddGroup = () => (
    <Link to="/group/new" width="100%">
        <Button fill={"horizontal"}>Create a group</Button>
    </Link>
);

export default Dashboard;
