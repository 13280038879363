import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory, createMemoryHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "../reducers";
import { isNativeApp } from "../helpers";

export const history = isNativeApp()
    ? createMemoryHistory()
    : createBrowserHistory();
const composeEnhancers =
    (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const configureStore = (preloadedState) =>
    createStore(
        createRootReducer(history),
        preloadedState,
        composeEnhancers(applyMiddleware(routerMiddleware(history)))
    );

export default configureStore;
