import React, { useState, useEffect } from "react";
import { useAuth } from "common/Firebase/Hooks";

export const UsersContext = React.createContext({});

export default ({ children }) => {
    const { email, profile } = useAuth();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // reload and replace first element with your profile
        if (profile && profile.isLoaded) {
            setUsers((u) => [{ email: email, role: "owner" }, ...u.slice(1)]);
        }
    }, [profile, email]);

    const addUser = (email) => {
        // TODO: fix unique and empty check
        setUsers((u) => {
            return u.map((user) => user.email).includes(email.email)
                ? u
                : [...u, email];
        });
    };

    const removeUser = (emailToRemove) => {
        setUsers((u) =>
            u.filter((existingUser) => existingUser.email !== emailToRemove)
        );
    };

    const clearUsers = () => {
        setUsers([users[0]]);
    };

    return (
        <UsersContext.Provider
            value={{
                users: users,
                setUsers: setUsers,
                addUser: addUser,
                removeUser: removeUser,
                clearUsers: clearUsers,
            }}
        >
            {children}
        </UsersContext.Provider>
    );
};
