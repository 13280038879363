import React from "react";

import { Box, Button } from "UI";

const AddGroupSection = ({ id }) => (
    <Box>
        <Button to={`/service/new/${id}`}>Add a password</Button>
    </Box>
);

export default AddGroupSection;
