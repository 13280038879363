import React from "react";
import { Route, Switch } from "react-router-dom";

import { useAuth } from "common/Firebase/Hooks";
import { Router } from "./_BaseRouter";

import { Dashboard } from "Pages/Home/Dashboard";
import { Brochure } from "Pages/Home/Brochure";
import { RouteNotFound } from "Pages/404";

const RouterHome = () => {
    const { isLoggedIn } = useAuth();
    return (
        <Router>
            <Switch>
                <Route
                    path="/"
                    exact
                    component={isLoggedIn ? Dashboard : Brochure}
                />
                <Route component={RouteNotFound} />
            </Switch>
        </Router>
    );
};

export const HomeRouter = RouterHome;
