import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import { isLoaded } from "react-redux-firebase";
import toaster from "toasted-notes";

import { recommendationTypes } from "common/helpers";
import { useAuth, useRecommendation, useGroup } from "common/Firebase/Hooks";
import { useResponsive } from "Hooks";
import { deleteRecommendation } from "common/Firebase/Actions";

import ViewUI from "./ViewUI";

export default (props) => {
    const { groupid, id } = props.match.params;

    const { email } = useAuth();
    const { services } = useGroup(groupid);
    const history = useHistory();
    const firestore = useFirestore();
    const recommendation = useRecommendation(groupid, id);
    const [isDeleting, setIsDeleting] = useState(false);
    const isLoading =
        !recommendation || !isLoaded(recommendation) || isDeleting;

    const TypeIcon =
        recommendation && recommendationTypes[recommendation.type].icon;
    const typeLabel =
        recommendation && recommendationTypes[recommendation.type].label;

    const handleDelete = (id) => {
        if (
            window.confirm(
                `Are you sure you want to delete the ${recommendation.name} recommendation?`
            )
        ) {
            setIsDeleting(true);
            toaster.notify(`${recommendation.name} was deleted.`, {
                position: "bottom",
            });
            deleteRecommendation(firestore, groupid, id).then((x) => {
                history.push(`/group/view/${groupid}`);
            });
        }
    };

    const createdOnDate = recommendation && recommendation.createdOn.toDate();
    const lastUpdatedDate = recommendation && recommendation.lastUpdated.toDate();

    const isUserRecommendationOwner =
        recommendation && recommendation.owner === email;
    let canDelete = isUserRecommendationOwner;

    const { isMobile } = useResponsive();

    const propsToPass = {
        canDelete,
        createdOnDate,
        lastUpdatedDate,
        groupid,
        id,
        isUserRecommendationOwner,
        isMobile,
        ...recommendation,
        isLoading,
        services,
        handleDelete,
        TypeIcon,
        typeLabel,
    };

    return <ViewUI {...propsToPass} />;
};
