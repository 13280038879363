import { getFirebase } from "react-redux-firebase";
import { formatUrl } from "common/helpers/urlHelpers";
import { logEvent } from "../Analytics";
/**
 * Grabs opengraph and other metadata for a specified URL
 * @param {string} url the URL of the site to grab the metadata for
 * @returns {Object}
 */
const getMetadata = async (url) => {
    const firebase = getFirebase();

    const scrapeUrlMetadata = firebase
        .functions()
        .httpsCallable("scrapeUrlMetadata-scrapeUrlMetadata");

    try {
        const metadata = await scrapeUrlMetadata({
            url: formatUrl(url),
        });
        let metadataFormatted = JSON.parse(metadata.data) || {};
        // need to do this to prevent nested array error in firestore
        if (metadataFormatted.jsonLd) {
            metadataFormatted.jsonLd = JSON.stringify(metadataFormatted.jsonLd);
        }
        metadataFormatted.url = formatUrl(url);
        return metadataFormatted;
    } catch (error) {
        console.log(error);
        return false;
    }
};

/**
 *
 * @param {FirebaseFirestore} firestore
 * @param {string} cid - comment ID
 * @param {string} groupid
 * @param {string} groupSubcollection - either "services" or "recommendations"
 * @param {string} id
 */
const deleteComment = (firestore, cid, groupid, groupSubcollection, id) => {
    logEvent("delete_comment");

    return firestore
        .collection("groups")
        .doc(groupid)
        .collection(groupSubcollection)
        .doc(id)
        .collection("comments")
        .doc(cid)
        .delete();
};

export { getMetadata, deleteComment };
