import React from "react";
import { Box } from "UI";
import { EmptyWelcome } from "Components";

const WelcomeToGroup = () => (
    <Box>
        <EmptyWelcome
            shift={2}
            heading={"Psst—got a hot tip for your group?"}
            subheading={"Recommend a TV show, movie, book, article, anything!"}
        />
    </Box>
);

export default WelcomeToGroup;
