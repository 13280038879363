import React, { useContext, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";
import { AppHeader, Box, Button, Page } from "UI";
import { Auth, GroupPreview } from "Components";
import { useAuth } from "common/Firebase/Hooks";
import { QueryContext } from "../../../Components/ContextQuery";

const Login = () => {
    const { isLoggedIn } = useAuth();
    const { search } = useLocation();
    const { queries } = useContext(QueryContext);
    const searchParams = queryString.parse(search);
    const groupId = searchParams.groupId ?? queries.groupId ?? null;
    const inviteId = searchParams.secret ?? queries.secret ?? null;
    const usePreview = groupId && inviteId;
    const [showPreview, setShowPreview] = useState(false);
    const previewUrlBase = `/group/preview/${groupId}/`;
    const dashboardUrlBase = `/`;

    if (isLoggedIn) {
        if (usePreview)
            return <Redirect to={{ pathname: previewUrlBase, search }} />;
        return <Redirect to={{ pathname: dashboardUrlBase, search }} />;
    }
    return (
        <>
            <AppHeader
                align="center"
                back={<Link to="/">Back</Link>}
                crumbParent={{ text: "Sharezies", link: "/" }}
                next={<></>}
                title={"Sign in/Register"}
            />
            <Page fill align="center" justify="center" insideFill={"vertical"}>
                {usePreview && !isLoggedIn && (
                    <Box flex={"grow"} justify={"center"} align={"center"}>
                        {showPreview ? (
                            <GroupPreview groupId={groupId} />
                        ) : (
                            <Button onClick={() => setShowPreview(true)}>
                                View Group Preview
                            </Button>
                        )}
                    </Box>
                )}
                <Auth
                    returnUrl={usePreview ? previewUrlBase : dashboardUrlBase}
                />
            </Page>
        </>
    );
};

export default Login;
