export const getOwners = (group) => {
    const members = group.roles;
    const ownerRole = "owner";
    const owners = [];
    if (members) {
        for (const emailKey of Object.keys(members)) {
            const member = members[emailKey];
            if (member.role === ownerRole) {
                owners.push(emailKey);
            }
        }
    }
    return owners;
};
