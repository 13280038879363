import React from "react";
import { Trash } from "grommet-icons";

import { Button } from "UI";

// TODO: add confirm
export default ({ label, action }) => (
    <>
        <Button type={"tertiary"} icon={<Trash />} onClick={action}>
            {label}
        </Button>
    </>
);
