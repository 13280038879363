import React from "react";
import { useUsers } from "common/Firebase/Hooks";
import { formatDisplayName } from "common/formatters";
import { Text, Box } from "UI";

export default ({ email, size }) => {
    const { users } = useUsers([email]);
    const user = users && Object.values(users)[0];
    const name = user ? formatDisplayName(user.name, user.email) : "";

    return (
        <Box pad={{ bottom: "small" }}>
            <Text textAlign="center" color="dark-3" size={size}>
                Shared by {name}
            </Text>
        </Box>
    );
};
