import React from "react";
import { Box } from "UI";

export default ({ children }) => {
    return (
        <Box fill={true} direction="column">
            {children}
        </Box>
    );
};
