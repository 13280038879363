import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import filterObj from "filter-obj";

export default (usersArray = []) => {
    usersArray = usersArray ? usersArray : []; // falsey to []

    const userQuery = usersArray.map((email) => {
        return {
            collection: "users",
            where: [["email", "==", email]],
            storeAs: `user_${email}`,
        };
    });

    useFirestoreConnect(userQuery, [usersArray]);
    const allowedKeys = usersArray.map((email) => `user_${email}`);

    const users = useSelector(({ firestore: { data } }) => {
        const filtered = filterObj(data, (k, v) => allowedKeys.includes(k));
        const users = {};
        for (let user of Object.keys(filtered)) {
            if (filtered[user] !== null) {
                users[user.slice(5)] = {
                    ...Object.values(filtered[user])[0],
                    id: Object.keys(filtered[user])[0],
                };
            }
        }
        return { users: users };
    });

    return users ? { ...users, isLoaded: isLoaded(users.users) } : users;
};
