import React from "react";
import NumberFormat from "react-number-format";
import { formatDisplayName } from "common/formatters";
import { useUsers } from "common/Firebase/Hooks";
import { Avatar, Box, Text } from "UI";

export default ({
    signedInEmail,
    delta = 0,
    decimalScale = 2,
    rank,
    email,
    currency = false,
    score,
    tone = "inherit",
    style,
}) => {
    const { users } = useUsers([email]);
    const userDetails = users && users[email];
    const userName = userDetails
        ? formatDisplayName(userDetails.name, email)
        : email;
    return (
        <Box direction="row" justify="stretch" align="center" style={style}>
            <Box
                direction="row"
                justify="between"
                border="top"
                overflow="hidden"
                pad={{ vertical: "0.5rem" }}
                fill={true}
            >
                <Box
                    flex={"shrink"}
                    basis="100%"
                    direction="row"
                    gap="8px"
                    style={{ minWidth: 0 }}
                >
                    {/* Rank */}
                    <Box basis={"4rem"} direction="row">
                        <Text color="dark-4">
                            {rank}
                            {delta !== 0 && (
                                <Text>
                                    {" "}
                                    ({delta > 0 && "+"}
                                    {delta})
                                </Text>
                            )}
                        </Text>
                    </Box>

                    {/* Avatar */}
                    <Box justify="center">
                        <Avatar
                            photoURL={
                                users && users[email] && users[email].photoURL
                            }
                            email={email}
                            size={"xsmall"}
                        />
                    </Box>

                    {/* Name */}
                    <Box
                        basis="100%"
                        flex="shrink"
                        style={{ overflow: "hidden" }}
                    >
                        <Text
                            truncate={true}
                            style={{
                                fontWeight:
                                    signedInEmail === email ? "600" : "400",
                            }}
                        >
                            {userName}
                        </Text>
                    </Box>
                </Box>
                <Box basis="5rem" flex={false} justify="end">
                    <Text
                        margin={{ left: "1.2rem" }}
                        style={{
                            color: tone,
                            fontVariantNumeric: "tabular-nums",
                        }}
                        truncate={true}
                        textAlign="end"
                    >
                        <NumberFormat
                            displayType={"text"}
                            prefix={currency}
                            value={score}
                            decimalScale={decimalScale}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                        />
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};
