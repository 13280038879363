import React from "react";
import useFetch from "react-fetch-hook";
import ReactMarkdown from "react-markdown/with-html";

export default ({ markdownUrl }) => {
    const { isLoading, data } = useFetch(markdownUrl, {
        formatter: (response) => response.text(),
    });
    return isLoading ? (
        false
    ) : (
        <ReactMarkdown source={data} escapeHtml={false} />
    );
};
