import React, { useEffect, useState } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";
import delay from "delay";

import useAuth from "./useAuth";
import useGroups from "./useGroups";
import { deleteInvitationFromFirestore } from "../Actions";

/**
 * @callback redirectCallback
 * @returns {void}
 */

/**
 * @callback handleInviteCallback
 * @param {string} id
 * @returns {Promise<void>}
 */

const INVITE_CODE_ERROR_MSG =
    "We couldn't handle your invitation response: check that your invite code is correct.";
const GENERIC_ERROR_MSG =
    "An error occurred when we were trying to handle your invitation response.";

/**
 * Custom hook containing shared logic for Group Preview (i.e. Group Invite) screens.
 * @param {string} groupId - group ID for which to get preview info
 * @param {string} inviteId
 * @param {string} inviteCode
 * @param {redirectCallback} redirectGroupView
 * @param {redirectCallback} redirectReject
 * @param {redirectCallback} redirectError
 */
export default function useGroupPreview({
    groupId,
    inviteId,
    inviteCode,
    redirectGroupView,
    redirectReject,
    redirectError,
}) {
    const firebase = useFirebase();
    const previewGroup = firebase.functions().httpsCallable("previewGroup");
    const joinGroup = firebase.functions().httpsCallable("joinGroup");
    const declineGroup = firebase.functions().httpsCallable("declineGroup");
    const [previewData, setPreviewData] = useState({});
    const {
        name = null,
        motto = null,
        image = null,
        services = [],
        members = [],
        email: invitedEmail = null,
    } = previewData;
    const [isPreviewLoaded, setPreviewLoaded] = useState(false);

    useEffect(() => {
        let didCancel = false;
        previewGroup({ groupId, inviteId, inviteCode })
            .then(({ data }) => {
                if (didCancel) return;
                setPreviewData(data);
                console.log(data);
                setPreviewLoaded(true);
            })
            .catch((err) => {
                if (didCancel) return;
                console.error(err);
                setPreviewLoaded(err);
            });
        return () => {
            didCancel = true;
        };
    }, []);

    const { email } = useAuth();
    const { joinedGroups, isLoaded: isGroupsLoaded } = useGroups(email);
    const firestore = useFirestore();
    const [isJoining, setIsJoining] = useState(false);
    const isLoading = !isPreviewLoaded || !isGroupsLoaded;
    const [errorMessage, setErrorMessage] = useState(null);

    // Redirect to view if already in group
    useEffect(() => {
        if (isGroupsLoaded) {
            const reduceGroups = (isGroup, group) =>
                isGroup || group.id === groupId;
            const isJoinedGroup = joinedGroups.reduce(reduceGroups, false);
            if (isJoinedGroup) {
                redirectGroupView();
            }
        }
    }, [joinedGroups, groupId, isGroupsLoaded]);

    // Show 404 if error occurs while loading group
    useEffect(() => {
        if (isPreviewLoaded instanceof Error) {
            console.error(isPreviewLoaded);
            redirectError();
        }
    }, [isPreviewLoaded]);

    /**
     * Handles accepting the group invitation.
     * @returns {Promise<void>}
     */
    const handleJoin = async () => {
        setIsJoining(true);
        await joinGroup({ groupId, inviteId, inviteCode })
            .then(() => delay(1000))
            .then(() => redirectGroupView())
            .catch((err) => {
                setErrorMessage(
                    err.code === "permission-denied" && !!inviteCode
                        ? INVITE_CODE_ERROR_MSG
                        : GENERIC_ERROR_MSG
                );
                console.error(err);
                setIsJoining(false);
            });
    };

    /**
     * Handles rejecting the group invitation.
     * @returns {Promise<void>}
     */
    const handleReject = async () => {
        setIsJoining(true);
        await deleteInvitationFromFirestore(firestore, groupId, inviteId)
            .catch((err) => {
                console.warn("deleteInvitationFromFirestore:", err);
                return declineGroup({ groupId, inviteId, inviteCode });
            })
            .then(() => delay(1000))
            .then(() => redirectReject())
            .catch((err) => {
                setErrorMessage(
                    err.code === "permission-denied" && !!inviteCode
                        ? INVITE_CODE_ERROR_MSG
                        : GENERIC_ERROR_MSG
                );
                console.error(err);
                setIsJoining(false);
            });
    };

    return {
        isJoining,
        isLoading,
        handleJoin,
        handleReject,
        name,
        motto,
        image,
        services,
        members,
        invitedEmail,
        errorMessage,
    };
}
