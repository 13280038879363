import React from "react";
import styled from "styled-components";
import Jdenticon from "react-jdenticon";

import { Box } from "UI";

const JDBox = styled(Box)`
    svg {
        transform: scale(1.15);
        position: relative;
        top: 4px;
    }
`;

export default ({ name, size = 48, ...props }) => {
    return (
        <JDBox direction={"row"} {...props}>
            <Jdenticon size={size} value={name} />
            <Jdenticon size={size} value={name} />
            <Jdenticon size={size} value={name} />
        </JDBox>
    );
};
