import React from "react";
import styled from "styled-components";
import { Box, Text } from "UI";
import { Link } from "react-router-dom";
import Jdenticon from "Components/Jdenticon/Jdenticon";

const CardLink = styled(Link)`
    width: 100%;
`;

const CardButtonBox = styled(Box)`
    border-radius: 4px;
    border: 2px solid #eee;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    min-height: 10rem;
    position: relative;

    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
    &:hover {
        border-color: #e7e7e7;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02),
            0px 4px 8px rgba(0, 0, 0, 0.04);
    }
    transition: 0.2s all ease;
`;

const CardButtonImageBox = styled(Box)`
    align-items: center;
    background-image: ${(props) => `url("${encodeURI(props.bg)}")`};
    background-position: 50%;
    background-size: cover;
    border-bottom: 1px solid #ececec;
    display: flex;
    height: 7rem;
    justify-content: center;
`;

const CardButtonTextBox = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: 3rem;
    line-height: 1.25rem;
    justify-content: space-between;
    padding: 0.875rem 16px;
`;

const CardButton = ({
    background,
    to,
    title,
    subtitle,
    topCaption,
    ...props
}) => (
    <CardLink to={to}>
        <CardButtonBox round={{ size: "small" }} {...props}>
            <CardButtonImageBox bg={background}>
                {!background && <Jdenticon size="88" name={title} />}
            </CardButtonImageBox>

            <CardButtonTextBox>
                <Box basis="65%">
                    <Text
                        color="black"
                        weight={600}
                        style={{ lineHeight: "1.25rem" }}
                        size="20px"
                    >
                        {title}
                    </Text>
                </Box>
                <Box basis="35%">
                    <Text
                        textAlign="end"
                        size="16px"
                        style={{ lineHeight: "1.25rem" }}
                        color="dark-3"
                    >
                        {topCaption}
                        {subtitle}
                    </Text>
                </Box>
            </CardButtonTextBox>
        </CardButtonBox>
    </CardLink>
);

export default CardButton;
