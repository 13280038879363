import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Trash } from "grommet-icons";
import { format as timeFormat } from "timeago.js";

import { Box, Button, AppHeader, Page, LoadingScreen, Text } from "UI";
import { ButtonBack, Password, SharedBy } from "Components";

const ServiceUserPassBox = styled(Box)`
    padding: 16px;
    border-radius: 12px;
    border: 2px solid #eee;
    &:hover {
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
        &:hover {
            border-color: #e7e7e7;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02),
                0px 4px 8px rgba(0, 0, 0, 0.04);
        }
        transition: 0.2s all ease;
    }
`;

const ServiceViewItem = ({ label, children }) => (
    <Box>
        <Box>
            <Text color="dark-5" style={{ userSelect: "none" }}>
                {label}
            </Text>
        </Box>
        <Box>{children}</Box>
    </Box>
);

export default ({
    canDelete,
    createdOnDate,
    lastUpdatedDate,
    currencySymbol,
    groupid,
    groupName = "Group",
    copied,
    tapToCopy,
    handleDelete,
    id,
    isMobile,
    isUserServiceOwner,
    isLoading,
    name,
    owner,
    serviceCost,
    serviceDescription,
    servicePassword,
    serviceUsername,
    serviceUrl,
}) =>
    isLoading ? (
        <LoadingScreen />
    ) : (
        <>
            <AppHeader
                back={<ButtonBack />}
                crumbGrandparent={{
                    text: groupName /* TODO: pass group name through */,
                    link: `/group/view/${groupid}`,
                }}
                next={
                    isUserServiceOwner ? (
                        <Link to={`/service/edit/${groupid}/${id}`}>Edit</Link>
                    ) : (
                        <></>
                    )
                }
                title={"View service"}
            />
            <Page pad={`${isMobile ? 8 : 32}px 24px`}>
                <Box gap={isMobile ? "24px" : "32px"}>
                    <Box>
                        <Box align="center" textAlign="center">
                            <Text size="32px" textAlign="center" weight={600}>
                                {name}
                            </Text>
                        </Box>
                        {owner && <SharedBy email={owner} />}
                    </Box>

                    {/* ServiceLink */}

                    {serviceUrl && (
                        <Box margin={{ bottom: "8px" }}>
                            <a
                                href={serviceUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button
                                    style={{ width: "100%", height: "50px" }}
                                >
                                    <Text truncate={true}>
                                        Visit ({new URL(serviceUrl).hostname})
                                    </Text>
                                </Button>
                            </a>
                        </Box>
                    )}

                    {(serviceUsername || servicePassword) && (
                        <Box gap="8px">
                            <Box
                                direction="row"
                                justify="between"
                                style={{ userSelect: "none" }}
                            >
                                <Text color="dark-5">
                                    {serviceUsername && "Username"}
                                    {serviceUsername && servicePassword && "/"}
                                    {servicePassword && "Password"}
                                </Text>
                                <Text color="dark-5">(Tap to copy)</Text>
                            </Box>
                            {serviceUsername && (
                                <ServiceUserPassBox
                                    direction="row"
                                    align={"center"}
                                    gap={"small"}
                                    onClick={() =>
                                        tapToCopy("user", serviceUsername)
                                    }
                                >
                                    <Text size="18px">{serviceUsername}</Text>
                                </ServiceUserPassBox>
                            )}

                            {servicePassword && (
                                <ServiceUserPassBox
                                    direction="row"
                                    align={"center"}
                                    gap={"small"}
                                    onClick={() =>
                                        tapToCopy("pass", servicePassword)
                                    }
                                >
                                    <Password
                                        size="18px"
                                        password={servicePassword}
                                    />
                                </ServiceUserPassBox>
                            )}
                        </Box>
                    )}

                    {/* Description */}
                    {serviceDescription && (
                        <ServiceViewItem label="Notes">
                            {serviceDescription}
                        </ServiceViewItem>
                    )}

                    {serviceCost && (
                        <ServiceViewItem label="Cost">
                            {currencySymbol}
                            {serviceCost}/month
                        </ServiceViewItem>
                    )}

                    {createdOnDate && (
                        <ServiceViewItem label="Added">
                            {timeFormat(createdOnDate)}
                        </ServiceViewItem>
                    )}

                    {timeFormat(lastUpdatedDate) !==
                        timeFormat(createdOnDate) && (
                        <ServiceViewItem label="Updated">
                            {timeFormat(lastUpdatedDate)}
                        </ServiceViewItem>
                    )}

                    <Box margin={{ vertical: "large" }}>
                        <Button
                            icon={<Trash />}
                            onClick={() => handleDelete(id)}
                            plain={true}
                            disabled={!canDelete}
                            type="tertiary"
                        >
                            Delete this service
                        </Button>
                    </Box>
                </Box>
            </Page>
        </>
    );
