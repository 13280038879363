import React from "react";
import { useHistory } from "react-router-dom";
import toaster from "toasted-notes";

import CreateUI from "./CreateUI";
import useGroupCreate from "common/Components/Hooks/useGroupCreate";

const GroupCreate = () => {
    const history = useHistory();

    const redirectGroup = (id) => history.push(`/group/view/${id}`);
    const getFileFromTarget = (target) => target.file;
    const handleNotifySuccess = (message) =>
        toaster.notify(message, { position: "bottom", type: "success" });
    const checkGroupNameValid = () => {
        const nameInput = document.getElementById("name-input");
        return nameInput.checkValidity();
    };
    const handleGroupNameNotValid = () => {
        const nameInput = document.getElementById("name-input");
        nameInput.reportValidity();
    };

    const propsToPass = useGroupCreate({
        redirectGroup,
        getFileFromTarget,
        handleNotifySuccess,
        checkGroupNameValid,
        handleGroupNameNotValid,
    });

    return <CreateUI {...propsToPass} />;
};

// Wrap element in Context Provider
export default GroupCreate;
