const formatNewService = ({
    cost,
    createdOn,
    description,
    firebase,
    email,
    name,
    password,
    url,
    username,
}) => {
    const service = {
        createdOn: createdOn,
        lastUpdated: firebase.firestore.Timestamp.fromDate(new Date()),
        isDisabled: false,
        isPaid: !!cost,
        name: name,
        owner: email,
        serviceCost: cost,
        serviceCostCurrency: "AUD",
        serviceDescription: description,
        servicePassword: password,
        serviceUsername: username,
        serviceUrl: url,
    };
    return service;
};

export default formatNewService;
