import React from "react";
import NumberFormat from "react-number-format";

import { formatRowBgColor } from "common/formatters";
import { getRank } from "common/helpers/squareUp";

import { ListLeaderboardItem } from "Components";
import { Box, Button, FormField, Select, TextInput, Text } from "UI";

const SquareUpSelectUI = ({
    amountToPay,
    capSquareUpValue,
    currencySymbol,
    currencySymbolSimple,
    handleChangeAmount,
    handleChangeUser,
    id,
    signedInEmail,
    preview,
    users,
    userToPay,
}) => (
    <>
        <Box pad="16px 16px 0">
            <Box>
                <FormField label="Who are you paying?">
                    {userToPay && (
                        <Select
                            options={users.filter(
                                (user) =>
                                    user.email !== signedInEmail &&
                                    user.isAccepted
                            )}
                            value={
                                userToPay.name
                                    ? `${userToPay.name} (${userToPay.email})`
                                    : userToPay.email
                            }
                            onChange={({ option }) => {
                                return handleChangeUser(option);
                            }}
                            children={(option) => (
                                <Box pad="12px">
                                    {option.name
                                        ? `${option.name} (${option.email})`
                                        : option.email}
                                </Box>
                            )}
                        />
                    )}
                </FormField>
            </Box>

            <Box>
                <FormField label={"... and how much?"}>
                    <NumberFormat
                        customInput={TextInput}
                        onValueChange={({ value }) => {
                            handleChangeAmount(value);
                        }}
                        placeholder={`Amount`}
                        decimalScale={2}
                        inputMode="decimal"
                        fixedDecimalScale={false}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                        value={amountToPay}
                        style={{
                            fontVariantNumeric: "tabular-nums",
                            textAlign: "right",
                        }}
                        isNumericString={true}
                        isAllowed={(values) => capSquareUpValue(values.value)}
                    />
                </FormField>
            </Box>
        </Box>

        {preview && (
            <Box pad={"0"} margin={{ bottom: "24px", top: "24px" }}>
                <Box pad={"0 16px"} margin={{ bottom: "medium" }}>
                    {preview.yourDeltaRank > 0 && (
                        <Text size="15px">
                            After this payment, you'll rise{" "}
                            {preview.yourDeltaRank} place
                            {preview.yourDeltaRank > 1 ? "s" : ""}.
                        </Text>
                    )}
                </Box>
                <Box pad="0 16px">
                    {Array.from(preview.scoreMap, ([email, score]) => (
                        <ListLeaderboardItem
                            key={email}
                            delta={preview.deltaMap.get(email)}
                            currency={currencySymbolSimple}
                            score={score}
                            rank={getRank(score, preview.rankArray)}
                            tone={formatRowBgColor(score, true)}
                            email={email}
                            signedInEmail={signedInEmail}
                            style={{
                                background:
                                    email === signedInEmail
                                        ? "#E9FFFB"
                                        : "inherit",
                            }}
                        />
                    ))}
                </Box>
            </Box>
        )}

        <Box>
            <Button
                disabled={!amountToPay || amountToPay <= 0}
                to={`/group/squareup/${id}/pay`}
            >
                Continue to payment
            </Button>
        </Box>
    </>
);

export default SquareUpSelectUI;
