import { Layer } from "grommet";
import { Box, Spinner } from "UI";
import React from "react";
export default () => (
    <Layer full plain>
        <Box
            fill
            background={"rgba(255,255,255,0.75)"}
            align="center"
            justify="center"
        >
            <Spinner />
        </Box>
    </Layer>
);
