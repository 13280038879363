import React from "react";
import { formatStorageUrl } from "common/formatters";
import { CardButton } from "UI";

export default ({ groups }) => (
    <>
        {groups &&
            groups.map((group) => (
                <CardButton
                    background={
                        group.image && formatStorageUrl(group.image.gsPath)
                    }
                    to={{
                        pathname: `/group/preview/${group.id}`,
                        search: group.invitedId
                            ? `?secret=${group.inviteId}`
                            : null,
                    }}
                    key={group.id}
                    title={`${group.name}`}
                    subtitle={"You're invited!"}
                />
            ))}
    </>
);
