import {
    Book,
    Channel,
    Globe,
    Info,
    Multimedia,
    Music,
    Restaurant,
    Schedule,
} from "grommet-icons";

export default {
    TV: {
        label: "TV Show",
        urlLabel: "Website URL (optional)",
        icon: Channel,
        nativeIcon: "md-tv",
        color: "#f244a4",
    },
    FILM: {
        label: "Film",
        urlLabel: "Website URL (optional)",
        icon: Multimedia,
        nativeIcon: "ios-film",
        color: "#5aa8d9",
    },
    MUSIC: {
        label: "Song/Album",
        urlLabel: "Website URL (optional)",
        icon: Music,
        nativeIcon: "ios-musical-notes",
        color: "#c7d943",
    },
    BOOK: {
        label: "Book",
        urlLabel: "Website URL (optional)",
        icon: Book,
        nativeIcon: "md-book",
        color: "#f2c12f",
    },
    RECIPE: {
        label: "Recipe",
        urlLabel: "Recipe URL (optional)",
        icon: Restaurant,
        nativeIcon: "md-pizza",
        color: "#663399",
    },
    URL: {
        label: "Website/Article",
        urlLabel: "URL",
        icon: Globe,
        nativeIcon: "ios-globe",
        color: "#f24c3c",
    },
    EVENT: {
        label: "Event",
        urlLabel: "Event URL (optional)",
        icon: Schedule,
        nativeIcon: "ios-calendar",
        color: "#6ebe4f",
    },
    OTHER: {
        label: "Something else",
        urlLabel: "Website URL (optional)",
        icon: Info,
        nativeIcon: "ios-information-circle-outline",
        color: "#7b64ac",
    },
};
