import React from "react";
import { Link } from "react-router-dom";
import { AppHeader, Box, Page } from "UI";
import { Heading } from "grommet";

export default () => (
    <>
        <AppHeader align="center" title="404" />
        <Page align="center" justify="center">
            <Heading level="2" textAlign="center">
                Page not found
            </Heading>
            <Box margin="medium" align="center" justify="center">
                <Link to="/">Go home »</Link>
            </Box>
        </Page>
    </>
);
