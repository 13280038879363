import {
    isLoaded,
    useFirestoreConnect,
    useFirebase,
} from "react-redux-firebase";
import { useSelector } from "react-redux";

export default (email) => {
    if (!email) return { joinedGroups: [], newGroups: [], isLoaded: true };
    const firebase = useFirebase();

    const rolePath = new firebase.firestore.FieldPath(
        "roles",
        email,
        "isAccepted"
    );

    useFirestoreConnect([
        {
            collection: "groups",
            where: [rolePath, "==", false],
            storeAs: "newGroups",
        },
        {
            collection: "groups",
            where: [rolePath, "==", true],
            storeAs: "joinedGroups",
        },
    ]);

    const groups = useSelector(
        ({
            firestore: {
                ordered: { joinedGroups, newGroups },
            },
        }) => ({
            joinedGroups,
            newGroups,
        })
    );

    const groupsObject = {
        ...groups,
        isLoaded: isLoaded(groups.joinedGroups) && isLoaded(groups.newGroups),
    };

    return groupsObject;
};
