import React from "react";

import { Page, AppHeader } from "UI";
import { ButtonBack, MarkdownContent } from "Components";
import LearnUrl from "common/pages/LearnMore.md";

export default () => (
    <>
        <AppHeader align="center" back={<ButtonBack />} />
        <Page>
            <MarkdownContent markdownUrl={LearnUrl} />
        </Page>
    </>
);
