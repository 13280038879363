import React from "react";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

import firebase from "./Firebase";
import rrfConfig from "./rrfConfig";
import configureStore from "common/store";

const store = configureStore();

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
};

const ShareziesProvider = ({ children }) => (
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            {children}
        </ReactReduxFirebaseProvider>
    </Provider>
);

export default ShareziesProvider;
