import React from "react";
import { Route, Switch } from "react-router-dom";

import { Router } from "./_BaseRouter";

import { RouteNotFound } from "Pages/404";

import { Login } from "Pages/Account/Login";
import { Logout } from "Pages/Account/Logout";
import { Register } from "Pages/Account/Register";
import { Edit } from "Pages/Account/Edit";

const AccountRouter = () => {
    return (
        <Router>
            <Switch>
                <Route path="/account" exact component={Edit} />
                <Route path="/account/new" component={Register} />
                <Route path="/account/login" component={Login} />
                <Route path="/account/logout" component={Logout} />
                <Route component={RouteNotFound} />
            </Switch>
        </Router>
    );
};

export { AccountRouter };
