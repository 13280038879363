import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import { isLoaded } from "react-redux-firebase";
import toaster from "toasted-notes";

import { useAuth, useGroup, useService } from "common/Firebase/Hooks";
import { useResponsive } from "Hooks";
import { deleteService } from "common/Firebase/Actions";
import { getOwners } from "common/helpers/getOwners";

import ViewUI from "./ViewUI";

const View = (props) => {
    const { groupid, id } = props.match.params;
    const minTimeBetweenNotifs = 2000;

    const { email } = useAuth();
    const history = useHistory();
    const firestore = useFirestore();
    const service = useService(groupid, id);
    const [isDeleting, setIsDeleting] = useState(false);
    const [copied, setCopied] = useState(null);
    const [lastUsernameToastTime, setLastUsernameToastTime] = useState(
        -minTimeBetweenNotifs
    );
    const [lastPasswordToastTime, setLastPasswordToastTime] = useState(
        -minTimeBetweenNotifs
    );
    const isLoading = !service || !isLoaded(service) || isDeleting;

    const tapToCopy = (field, text) => {
        // Have to create dummy textarea and copy that since not all elements support .select()
        var dummy = document.createElement("textarea");
        dummy.value = text;
        dummy.style.position = "fixed"; //avoid scrolling to bottom
        document.body.appendChild(dummy);
        dummy.focus();
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        // Only notify if there has been a sufficient duration since the last notification
        const toastOptions = {
            position: "bottom",
            duration: minTimeBetweenNotifs,
        };
        if (
            field === "user" &&
            performance.now() - lastUsernameToastTime >= minTimeBetweenNotifs
        ) {
            toaster.notify(
                `Username copied to clipboard!`,
                toastOptions
            );
            setLastUsernameToastTime(performance.now());
        } else if (
            field === "pass" &&
            performance.now() - lastPasswordToastTime >= minTimeBetweenNotifs
        ) {
            toaster.notify(
                `Password copied to clipboard!`,
                toastOptions
            );
            setLastPasswordToastTime(performance.now());
        }
        setCopied(field);
    };

    const handleDelete = (id) => {
        if (
            window.confirm(
                `Are you sure you want to delete the ${service.name} service?`
            )
        ) {
            setIsDeleting(true);
            toaster.notify(`${service.name} was deleted.`, {
                position: "bottom",
            });
            deleteService(firestore, groupid, id).then((x) => {
                history.push(`/group/view/${groupid}`);
            });
        }
    };

    const createdOnDate = service && service.createdOn.toDate();

    const lastUpdatedDate =
        service && service.lastUpdated && service.lastUpdated.toDate();
    const group = useGroup(groupid);
    const groupOwners = getOwners(group);
    const isUserGroupOwner = Object.keys(groupOwners).indexOf(email) > -1;
    const isUserServiceOwner = service && service.owner === email;
    let canDelete = isUserGroupOwner || isUserServiceOwner;

    const currencySymbol = group["group"] && group["group"].currencySymbol;

    const { isMobile } = useResponsive();

    const propsToPass = {
        canDelete,
        createdOnDate,
        lastUpdatedDate,
        currencySymbol,
        groupid,
        id,
        isUserServiceOwner,
        isMobile,
        ...service,
        isLoading,
        copied,
        tapToCopy,
        handleDelete,
    };

    return <ViewUI {...propsToPass} />;
};

export default View;