import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { connectRouter } from "connected-react-router"; // TODO: shouldn't be in common

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        firebase: firebaseReducer,
        firestore: firestoreReducer,
    });

export default createRootReducer;
