import React from "react";
import { Box } from "UI";

import { EmptyWelcome } from "Components";

export default () => {
    return (
        <Box pad="large">
            <EmptyWelcome
                shift={1}
                heading={"It's only you"}
                subheading={
                    "Add another member to this group to start a leaderboard"
                }
            />
        </Box>
    );
};
