import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";
import styled, { keyframes } from "styled-components";
import { editProfile } from "common/Firebase/Actions";

import { Button, Box, Text } from "UI";

const SlideImage = styled(Box)`
    width: 100%;
    height: 13rem;
    background-image: ${(props) => `url("${encodeURI(props.bg)}")`};
    background-size: cover;
    background-position: 50%;
    border-radius: 8px 8px 0 0;
`;

const SlideButtonBox = styled(Box)`
    height: 16px;
    width: 16px;
    background: black;
    border-radius: 12px;
    opacity: ${(props) => props.opacity};
    margin: 0 6px;
`;

const colorChangeShadow = keyframes`
    0% { box-shadow: 0 0 4px 0px rgba(85, 220, 3, 0.5); }
    33% { box-shadow: 0 0 4px 0px rgba(29, 212, 153, 0.5); }
    67% {  box-shadow: 0 0 4px 0px rgba(46, 100, 225, 0.5); }
    100% { box-shadow: 0 0 4px 0px rgba(85, 220, 3, 0.5);  }
`;

const OnboardBox = styled(Box)`
    animation: ${colorChangeShadow} 15s infinite;
    border-radius: 8px;
`;

const DotsBox = styled(Box)`
    width: 100%;
    cursor: pointer;
`;

const WelcomeSlide = ({
    handleClick,
    image,
    stepText,
    blurbText,
    buttonText,
    ...props
}) => (
    <Box pad="0" align="center" {...props} width="100%">
        <Box margin={{ bottom: "36px" }} width="100%">
            <SlideImage margin={{ bottom: "24px" }} bg={image} width="100%" />
            <Box gap="24px" pad={"0 16px"}>
                <Text textAlign="center" weight={600}>
                    Here's how to get started on Sharezies.
                </Text>
                <Text textAlign="center" weight={500}>
                    {stepText}
                </Text>
            </Box>
            <Box pad={"0 16px"}>
                <Text textAlign="center" weight={500}>
                    {blurbText}
                </Text>
            </Box>
        </Box>

        <Box margin={{ bottom: "36px" }}>
            <Button onClick={handleClick}>{buttonText}</Button>
        </Box>
    </Box>
);

const contents = [
    {
        header: (
            <>
                <br />
            </>
        ),
        stepText: "STEP ONE.",
        blurbText: "Start a group and invite your friends and family.",
        buttonText: "OK, I have friends!",
        image: "https://media.giphy.com/media/g0akc23y5kgqskTzxL/giphy.gif",
    },
    {
        header: (
            <>
                <br />
            </>
        ),
        stepText: "STEP TWO.",
        blurbText: "Add your passwords for your favourite services.",
        buttonText: "Can do.",
        image: "https://media.giphy.com/media/CzbiCJTYOzHTW/giphy.gif",
    },
    {
        header: (
            <>
                <br />
            </>
        ),
        stepText: "STEP THREE.",
        blurbText:
            "Lastly, download our iOS/Android app to have your passwords handy whenever you need them, and be notified when other members add something new.",
        buttonText: "Let me at it!",
        image: "https://media.giphy.com/media/uyWTOgNGGWfks/giphy.gif",
    },
];
export default () => {
    const [hidden, setHidden] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const firebase = useFirebase();

    const handleClose = () => {
        setHidden(true);
        editProfile(firebase, {
            hasClosedOnboarding: true,
        });
    };

    if (hidden) {
        return <></>;
    }

    return (
        <OnboardBox
            align="center"
            pad={{ bottom: "24px" }}
            margin={{ bottom: "24px" }}
        >
            <Box width="100%">
                {contents.map((content, index) => (
                    <WelcomeSlide
                        key={content.stepText}
                        {...content}
                        index={index}
                        style={{
                            display: index === selectedIndex ? "flex" : "none",
                        }}
                        handleClick={() => {
                            if (contents.length - 1 === index) {
                                handleClose();
                            } else {
                                setSelectedIndex(index + 1);
                            }
                        }}
                    />
                ))}
            </Box>

            <DotsBox direction="row" align="center" justify="center">
                {contents.map((content, index) => (
                    <SlideButtonBox
                        key={index}
                        opacity={index === selectedIndex ? 1 : 0.25}
                        onClick={() => setSelectedIndex(index)}
                    />
                ))}
            </DotsBox>
        </OnboardBox>
    );
};
