import React, { useState } from "react";
import { useFirebase } from "react-redux-firebase";
import styled, { keyframes } from "styled-components";

import { useResponsive } from "Hooks";
import { editProfile } from "common/Firebase/Actions";

import { Button, Box, Text } from "UI";

import rankImageEven from "common/images/rank-even.jpg";

const colorChangeShadow = keyframes`
    0% { box-shadow: 0 0 4px 0px rgba(85, 220, 3, 0.5); }
    33% { box-shadow: 0 0 4px 0px rgba(29, 212, 153, 0.5); }
    67% {  box-shadow: 0 0 4px 0px rgba(46, 100, 225, 0.5); }
    100% { box-shadow: 0 0 4px 0px rgba(85, 220, 3, 0.5);  }
`;

const OnboardBox = styled(Box)`
    animation: ${colorChangeShadow} 15s infinite;
    border-radius: 8px;
    img {
        max-width: 100%;
        height: auto;
    }
`;

export default () => {
    const [hidden, setHidden] = useState(false);
    const firebase = useFirebase();
    const { isMobile } = useResponsive();

    const handleClose = () => {
        setHidden(true);
        editProfile(firebase, {
            hasClosedLeaderboardOnboarding: true,
        });
    };

    if (hidden) {
        return <></>;
    }

    return (
        <OnboardBox align="center" margin={{ top: "24px", horizontal: "24px" }}>
            <Box
                pad="24px 24px 32px"
                fill
                align="center"
                justify="between"
                style={{ display: "block", margin: "auto 0" }}
                onClick={() => handleClose()}
            >
                <Box pad="0 8px 24px">
                    <Text
                        textAlign="center"
                        size={isMobile ? "1rem" : "24px"}
                        weight={600}
                    >
                        Your group's statistics
                    </Text>
                </Box>
                <Box align="center" justify="center">
                    <img
                        src={rankImageEven}
                        alt=""
                        style={{
                            maxWidth: "240px",
                        }}
                    />
                </Box>
                <Box pad={isMobile ? "0" : "16px"}>
                    <Text textAlign="center">
                        <p>
                            Think of this like your trusted accountant’s office.
                        </p>
                        <p>
                            Every day, we tally up who’s paying what. Then we
                            update the balances on the leaderboard. The more you
                            contribute, the higher your balance.
                        </p>
                        <p>
                            You can also square costs up by sending money to
                            each other. If a friend is contributing more, you
                            might want to hit the Square Up button and send them
                            some{" "}
                            <span role="img" aria-label="Money">
                                💵
                            </span>
                            <span role="img" aria-label="Money">
                                💶
                            </span>
                            <span role="img" aria-label="Money">
                                💴
                            </span>
                            !
                        </p>
                    </Text>
                </Box>
                <Box>
                    <Button type="secondary" onClick={() => handleClose()}>
                        Ok, got it.
                    </Button>
                </Box>
            </Box>
        </OnboardBox>
    );
};
