import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Box } from "UI";

const TAB_CHANGE_BP = "1128px";

const TabLink = styled(Link)`
    align-items: center;
    color: ${(props) => (props.selected ? "black" : "rgba(0, 0, 0, 0.65)")};
    display: flex;
    flex: 1 1 50%;
    font-size: 0.875rem;
    font-weight: ${(props) => (props.selected ? "600" : "400")};
    height: 100%;
    max-width: 260px;
    justify-content: center;
    position: relative;
    user-select: none;
    &:active {
        background: inherit;
    }
    ::after {
        background: black;
        border-radius: 3px 3px 0 0;
        bottom: 0;
        content: "";
        display: ${(props) => (props.selected ? "block" : "none")};
        height: 3px;
        left: 30%;
        position: absolute;
        width: 40%;
    }
    &:hover {
        color: ${(props) => (props.selected ? "black" : "rgba(0, 0, 0, .9)")};
    }

    @media screen and (min-width: ${TAB_CHANGE_BP}) {
        font-size: 1rem;
        max-width: 220px;
    }
`;

export const Tab = ({ children, to, ...props }) => {
    const { pathname } = useLocation();
    return (
        <TabLink to={to} selected={pathname === to} {...props}>
            {children}
        </TabLink>
    );
};

export const TabBox = styled(Box)`
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 2.75rem;
    flex-basis: 2.75rem;
    flex-shrink: 0;

    > * {
        flex: 1;
    }

    @media screen and (max-width: 320px) {
        letter-spacing: -0.35px;
    }

    @media screen and (min-width: ${TAB_CHANGE_BP}) {
        height: 4rem;
        flex-basis: 4rem;
        margin-top: -4rem;
    }
`;
