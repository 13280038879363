function compareByBalance(a, b) {
    // compare function to return descending
    return parseFloat(b.balance) - parseFloat(a.balance);
}

export default (membersDict) => {
    // sorts members in descending order by balance attr
    membersDict = membersDict ? membersDict : {}; // falsey to {}

    const members = [];
    for (const memberEmail of Object.keys(membersDict)) {
        const member = membersDict[memberEmail];
        member.email = memberEmail;
        members.push(member);
    }
    if (members.length < 2) {
        return members;
    }
    members.sort(compareByBalance);
    return members;
};
