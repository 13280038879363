import React from "react";
import { Link } from "react-router-dom";
import { Auth } from "Components";
import { AppHeader, Page } from "UI";

// TODO: DRY, this is very similar to Login page
const Register = () => (
    <>
        <AppHeader
            title="Register"
            crumbParent={{ text: "Sharezies", link: "/" }}
            isHidingBorder={true}
            back={<Link to="/">Back</Link>}
            next={<></>}
        />
        <Page fill align="center" justify="center" insideFill={"vertical"}>
            <Auth />
        </Page>
    </>
);

export default Register;
