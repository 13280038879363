import React, { useState, useContext } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";

import { addGroup, uploadGroupImage } from "common/Firebase/Actions";
import { useAuth } from "common/Firebase/Hooks";
import { UsersContext } from "common/Components";
import { formatNewGroup, formatNewGroupUsers } from "common/formatters";

import currencyData from "common/helpers/currencies.json";

const currencyOptions = Object.keys(currencyData);

/**
 *
 * @param {function(groupId: string?): void} redirectGroup
 * @param {function(target: unknown): Promise<File>}getFileFromTarget
 * @param {function(message: string): void } handleNotifySuccess
 * @param {function(groupName: string?): boolean} checkGroupNameValid
 * @param {function(): void} handleGroupNameNotValid
 */
export default function useGroupCreate({
    redirectGroup,
    getFileFromTarget,
    handleNotifySuccess,
    checkGroupNameValid,
    handleGroupNameNotValid,
}) {
    const firebase = useFirebase();
    const firestore = useFirestore();
    const { email } = useAuth();

    const [isSaving, setIsSaving] = useState(false); // bool
    const { users, clearUsers } = useContext(UsersContext);
    const [groupMotto, setGroupMotto] = useState(""); // string
    const [groupName, setGroupName] = useState(""); // string
    const [groupCurrency, setGroupCurrency] = useState("USD");
    const [groupImageTarget, setGroupImageTarget] = useState(null);
    // TODO: create image upload flow

    const handleSave = async () => {
        const isNameValid = checkGroupNameValid(groupName);
        if (!isNameValid) {
            handleGroupNameNotValid();
            return;
        }

        setIsSaving(true);
        const { roles, invitations } = await formatNewGroupUsers(
            users,
            email,
            firebase,
            firestore
        );
        const groupDoc = await addGroup(
            firestore,
            formatNewGroup({
                name: groupName,
                motto: groupMotto,
                currency: groupCurrency,
                currencySymbol: currencyData[groupCurrency].symbol,
                currencySymbolSimple: currencyData[groupCurrency].symbol_native,
                roles,
                firebase: firebase,
            }),
            invitations
        );
        if (groupImageTarget) {
            const blob = await getFileFromTarget(groupImageTarget);
            await uploadGroupImage(firestore, firebase, groupDoc.id, blob);
        }

        clearUsers();

        handleNotifySuccess("Your group has been created!");
        redirectGroup(groupDoc.id);
    };

    return {
        groupImageTarget,
        groupMotto,
        groupName,
        groupCurrency,
        currencyOptions,
        handleSave,
        isSaving,
        setGroupImageTarget,
        setGroupMotto,
        setGroupName,
        setGroupCurrency,
        users,
        clearUsers,
    };
}
