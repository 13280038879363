import React, { useState, useEffect } from "react";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import toaster from "toasted-notes";

import { formatNewService } from "common/formatters";
import { formatUrl, validateService } from "common/helpers";
import { editService } from "common/Firebase/Actions";
import { useAuth, useGroup, useService } from "common/Firebase/Hooks";

import { FormService } from "Components/FormService";
import { LoadingScreen } from "UI";

const ServiceEdit = ({
    match: {
        params: { id, groupid },
    },
}) => {
    const { email } = useAuth();
    const firebase = useFirebase();
    const firestore = useFirestore();
    const history = useHistory();
    const service = useService(groupid, id);

    // TODO: this code is pretty splindly and awkward.
    // I'm sure we can refactor and improve.

    const { group } = useGroup(groupid);
    const currencySymbol = group && group.currencySymbol;

    const createdOn = service && service.createdOn;

    const [isSaving, setIsSaving] = useState(false);
    const [hasServiceLoaded, setHasServiceLoaded] = useState(false);
    const [cost, setCost] = useState("");
    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [inputUrl, setInputUrl] = useState("");
    const [username, setUsername] = useState("");

    useEffect(() => {
        if (service && !hasServiceLoaded) {
            setCost(service.serviceCost);
            setDescription(service.serviceDescription);
            setName(service.name);
            setPassword(service.servicePassword);
            setInputUrl(service.serviceUrl);
            setUsername(service.serviceUsername);
            setHasServiceLoaded(true);
        }
    }, [service, hasServiceLoaded]);

    const isDataLoaded = service !== {};

    const handleSave = async () => {
        const urlInput = document.getElementById("url-input");
        const nameInput = document.getElementById("name-input");
        const costInput = document.getElementById("cost-input");
        if (validateService(urlInput, nameInput, costInput)) {
            const url = formatUrl(inputUrl);
            setIsSaving(true);
            await editService(
                firestore,
                groupid,
                id,
                formatNewService({
                    createdOn,
                    cost,
                    description,
                    email,
                    firebase,
                    name,
                    password,
                    url,
                    username,
                })
            );
            toaster.notify("Password changes saved.", {
                position: "bottom",
            });
            history.push(`/service/view/${groupid}/${id}`);
        }
    };

    const isNewService = false;

    const propsToPass = {
        cost,
        currencySymbol,
        description,
        groupid,
        groupName: service && service.groupName,
        handleSave,
        id,
        isDataLoaded,
        isNewService,
        name,
        password,
        setCost,
        setDescription,
        setName,
        setPassword,
        setUsername,
        setInputUrl,
        inputUrl,
        username,
        isSaving,
    };

    return service && service.isLoaded ? (
        <FormService {...propsToPass} title={`Edit ${name}`} />
    ) : (
        <LoadingScreen />
    );
};

export default ServiceEdit;
