/**
 * Tests current application context (web/native) based on `navigator` global object
 *
 * @returns {boolean} true if context is native app, false if not (assume web)
 */
export const isNativeApp = () => {
    return (
        typeof navigator != "undefined" && navigator.product == "ReactNative"
    );
};
