import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import Logo from "common/images/logo.svg";

import { AvatarOwn, Box, Text } from "UI";
import { useResponsive } from "Hooks";
import { useAuth, useGroups } from "common/Firebase/Hooks";

const LogoImg = styled.img`
    height: 1.25rem;
    width: auto;
    margin-bottom: 0.25rem;
`;

export default () => {
    const { isDesktop } = useResponsive();
    const { email, isLoggedIn, profile, isLoaded: isAuthLoaded } = useAuth();

    return !email || !isDesktop || !isLoggedIn ? (
        false
    ) : (
        <SidebarContainerBox>
            {isAuthLoaded && (
                <>
                    <SidebarSpacer>
                        <SidebarItemLink isLogo={true} to={`/`}>
                            <LogoImg src={Logo} alt="Sharezies" />
                        </SidebarItemLink>
                    </SidebarSpacer>

                    <GroupsList email={email} />

                    <SidebarSpacerBottom>
                        <Account {...profile} />
                    </SidebarSpacerBottom>
                </>
            )}
        </SidebarContainerBox>
    );
};

const SidebarSpacer = ({ children, ...props }) => (
    <Box
        margin={{ bottom: "small" }}
        pad={{ bottom: "small" }}
        border={{
            color: "#e3e3e3",
            size: "1px",
            side: "bottom",
        }}
        {...props}
    >
        {children}
    </Box>
);

const SidebarSpacerBottom = styled(SidebarSpacer)`
    margin-top: auto;
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
`;
const GroupsList = ({ email }) => {
    const { newGroups, joinedGroups, isLoaded } = useGroups(email);
    return (
        isLoaded && (
            <>
                {(newGroups.length > 0 || joinedGroups.length > 0) && (
                    <SidebarSpacer>
                        <Box>
                            {newGroups &&
                                newGroups.map((group) => (
                                    <GroupItem
                                        key={group.id}
                                        {...group}
                                        joined={false}
                                    />
                                ))}
                            {joinedGroups &&
                                joinedGroups.map((group) => (
                                    <GroupItem
                                        key={group.id}
                                        {...group}
                                        joined={true}
                                    />
                                ))}
                        </Box>
                    </SidebarSpacer>
                )}

                <SidebarSpacer border={false}>
                    <SidebarItemLink to={`/group/new`}>
                        <Text>+ New Group</Text>
                    </SidebarItemLink>
                </SidebarSpacer>
            </>
        )
    );
};

const Account = ({ name }) => (
    <SidebarItemLink to={`/account`} isActive={false}>
        <Text truncate={true} basis={"184px"} flex={true}>
            {name}
        </Text>
        <AvatarOwn size={"small"} />
    </SidebarItemLink>
);

const GroupItem = ({ id, name, isActive, joined }) => {
    const linkPath = `/group/${joined ? "view" : "preview"}/${id}`;
    return (
        <SidebarItemLink to={linkPath} id={id} isActive={isActive}>
            <Text
                style={{ display: "block", maxWidth: "208px" }}
                truncate={true}
            >
                {name}
            </Text>
        </SidebarItemLink>
    );
};

const SidebarItemBox = ({ children, isActive, ...props }) => (
    <Box
        pad={{ horizontal: "16px", vertical: "xsmall" }}
        background={isActive ? "#efefef" : "transparent"}
        overflow="hidden"
        direction="row"
        justify="between"
        {...props}
    >
        {children}
    </Box>
);

const SidebarItemLink = ({ children, to, id, isLogo, ...props }) => {
    const { pathname } = useLocation();
    const isActive =
        !isLogo && (id ? pathname.indexOf(id) > -1 : pathname === to);
    return (
        <Link to={to}>
            <SidebarItemBox {...props} isActive={isActive}>
                {children}
            </SidebarItemBox>
        </Link>
    );
};

const SidebarContainerBox = ({ children, ...props }) => (
    <Box
        background="#f8f8f8"
        basis="240px"
        direction="column"
        fill={"vertical"}
        flex={false}
        pad={{ vertical: "20px" }}
        {...props}
    >
        {children}
    </Box>
);
