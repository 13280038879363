import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { formatDisplayName } from "common/formatters";
import { useUsers } from "common/Firebase/Hooks";
import { recommendationTypes } from "common/helpers";
import { Box, Text } from "UI";

const RecommendationBox = styled(Box)`
    &:hover {
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
        &:hover {
            border-color: #e7e7e7;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02),
                0px 4px 8px rgba(0, 0, 0, 0.04);
        }
        transition: 0.2s all ease;
    }
`;

export default ({ recommendations, groupId }) => {
    return (
        <Box margin={{ top: "none", bottom: "medium" }}>
            {recommendations &&
                Object.keys(recommendations).map((sId, index) => {
                    if (!recommendations[sId]) {
                        return false;
                    }
                    const recommendation = recommendations[sId];
                    return (
                        <RecommendationListItem
                            key={sId}
                            {...recommendation}
                            url={`/recommendation/view/${groupId}/${sId}`}
                        />
                    );
                })}
        </Box>
    );
};

const RecommendationListItem = ({
    url,
    name,
    owner,
    type,
    recommendationImage,
}) => {
    const { users } = useUsers([owner]);
    const userDetails = users && users[owner];
    const userName = userDetails
        ? formatDisplayName(userDetails.name, userDetails.email)
        : "";

    const { icon: Icon, color: iconColor } = recommendationTypes[type];
    return (
        <Link to={url} style={{ marginBottom: "8px" }}>
            <RecommendationBox
                round={"12px"}
                border={{
                    color: "#eee",
                    size: "2px",
                    side: "all",
                }}
                pad={{ vertical: "16px", horizontal: "medium" }}
            >
                <Box
                    direction="row"
                    justify={"between"}
                    align="center"
                    gap="xsmall"
                >
                    <Box
                        gap="14px"
                        direction="row"
                        align="center"
                        justify="center"
                    >
                        <Box>{<Icon color={iconColor} size={"20px"} />}</Box>
                        <Text
                            color="black"
                            weight={600}
                            size="18px"
                            style={{ lineHeight: 1.25 }}
                        >
                            {name}
                        </Text>
                    </Box>
                    <Text size="14px" color="dark-3">
                        {userName && `${userName}`}
                    </Text>
                </Box>
            </RecommendationBox>
        </Link>
    );
};
