import React from "react";

import {
    ButtonBack,
    ButtonDelete,
    ImageUploader,
    UsersManage,
} from "Components";
import {
    AppHeader,
    Box,
    FormField,
    Page,
    SaveOverlay,
    Select,
    Text,
    TextArea,
    TextInput,
} from "UI";

const DeleteGroupSection = ({ label, action }) => (
    <Box justify="end" margin={{ top: "large", bottom: "medium" }}>
        <ButtonDelete label={label} action={action} />
    </Box>
);

const FormGroup = ({
    groupImageUrl,
    groupImageTarget,
    groupMotto,
    groupName,
    groupCurrency,
    currencyOptions,
    handleAddUser,
    handleDelete,
    handleLeave,
    handleRemoveUser,
    handleSave,
    id,
    isDeleting,
    isLeaving,
    isNewGroup,
    isSaving,
    newEmail,
    isOwner,
    users,
    setGroupImageTarget,
    setGroupMotto,
    setGroupName,
    setGroupCurrency,
    setNewEmail,
}) => {
    return (
        <>
            <AppHeader
                back={<ButtonBack />}
                crumbParent={
                    !isNewGroup && {
                        text: groupName,
                        link: `/group/view/${id}`,
                    }
                }
                titleDesktop={isNewGroup ? "New Group" : "Edit"}
                next={
                    <div style={{ cursor: "pointer" }} onClick={handleSave}>
                        Save
                    </div>
                }
                title={`Edit ${groupName}`}
            />
            <Page pad={{}}>
                <ImageUploader
                    name={groupName}
                    existingImageUrl={groupImageUrl}
                    groupImageTarget={groupImageTarget}
                    setGroupImageTarget={setGroupImageTarget}
                />
                <Box pad={{ horizontal: "large", vertical: "small" }}>
                    <Box gap={"small"}>
                        <FormField label="Group name">
                            <TextInput
                                id={"name-input"}
                                required
                                value={groupName || ""}
                                onChange={(e) => setGroupName(e.target.value)}
                                maxLength={30}
                                minLength={1}
                            />
                        </FormField>

                        <FormField label="Currency">
                            <Select
                                options={currencyOptions}
                                value={groupCurrency}
                                onChange={({ option }) =>
                                    setGroupCurrency(option)
                                }
                            />
                        </FormField>

                        <FormField label="Group motto">
                            <TextArea
                                background="white"
                                value={groupMotto || ""}
                                onChange={(e) => setGroupMotto(e.target.value)}
                                placeholder={
                                    "All hoard and no share makes Jack a dull boy"
                                }
                                style={{ minHeight: "6rem" }}
                            />
                        </FormField>
                        {users && users.length > 0 && (
                            <Box margin={{ vertical: "medium" }}>
                                <Box margin={{ bottom: "small" }}>
                                    <Text size="small" color="dark-4">
                                        Members
                                    </Text>
                                </Box>
                                <UsersManage
                                    emailToAdd={newEmail}
                                    handleAddUser={handleAddUser}
                                    removeUser={handleRemoveUser}
                                    setEmailToAdd={setNewEmail}
                                    users={users}
                                />
                            </Box>
                        )}
                    </Box>

                    <DeleteGroupSection
                        label={
                            isOwner ? "Delete this group" : "Leave this group"
                        }
                        action={isOwner ? handleDelete : handleLeave}
                    />

                    {(isSaving || isLeaving || isDeleting) && <SaveOverlay />}
                </Box>
            </Page>
        </>
    );
};

export default FormGroup;
