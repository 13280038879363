import React, { useState, useEffect } from "react";
import {
    Link,
    Redirect,
    Route,
    Switch,
    useParams,
    useHistory,
} from "react-router-dom";
import { useFirestore } from "react-redux-firebase";
import { Close } from "grommet-icons";

// helpers
import { suggestPayment, previewPayment } from "common/helpers";
import { getScoresRanks } from "common/helpers/squareUp";

// hooks
import { useAuth, useBalances, useGroup } from "common/Firebase/Hooks";
import { makePayment } from "common/Firebase/Actions";

// UI
import { AppHeader, Page, SaveOverlay } from "UI";

// partials
import { SquareUpPay } from "./SquareUpPay";
import { SquareUpSelect } from "./SquareUpSelect";
import toaster from "toasted-notes";

export default () => {
    let { id } = useParams();

    const firestore = useFirestore();
    const history = useHistory();
    const { email } = useAuth();

    const { sortedMembers, isLoaded: isMembersLoaded } = useBalances(id);

    const { group } = useGroup(id);
    const [userToPay, setUserToPay] = useState(null);
    const [amountToPay, setAmountToPay] = useState("0");
    const [suggestionCalled, setSuggestionCalled] = useState(false);
    const [suggestedPayment, setSuggestedPayment] = useState(0);
    const [preview, setPreview] = useState(false);
    const [isMakingPayment, setIsMakingPayment] = useState(false);

    // max enterable square up value is 1000 unless suggested payment is over 1000
    const capSquareUpValue = (value) => {
        return suggestedPayment > 1000 || value <= 1000;
    };

    const handleChangeUser = (value) => {
        setUserToPay(value);
        return true;
    };

    const handleChangeAmount = (value) => {
        setAmountToPay(value.replace("-", "")); // TODO: proper masking/validation
        return true;
    };

    const handleMakePayment = async (
        id,
        toUserEmail,
        fromUserEmail,
        amount
    ) => {
        setIsMakingPayment(true);
        await makePayment(firestore, id, toUserEmail, fromUserEmail, amount);
        toaster.notify("Payment made! The leaderboard has been updated.", {
            position: "bottom",
        });
        history.push(`/group/view/${id}/leaderboard`);
    };

    // handle getting the initial suggestion once email and sortedMembers arrive
    useEffect(() => {
        if (suggestionCalled || !isMembersLoaded) return;
        const suggestion = suggestPayment(sortedMembers, email);
        if (suggestion) {
            setUserToPay(suggestion.userToPay);
            setAmountToPay(suggestion.amountToPay.toFixed(2));
            setSuggestedPayment(suggestion.amountToPay.toFixed(2));
            setSuggestionCalled(true);
        }
    }, [isMembersLoaded, email, sortedMembers, suggestionCalled]);

    // call preview once userToPay and amountToPay are available/have changed
    useEffect(() => {
        if (userToPay && amountToPay) {
            const users = previewPayment(
                sortedMembers,
                userToPay.email,
                email,
                amountToPay
            );
            const [scoreMap, rankArray, deltaMap] = getScoresRanks(
                users,
                [],
                true
            );
            const yourDeltaRank = users.filter(
                (user) => user.email === email
            )[0].deltaRank;
            setPreview({ scoreMap, rankArray, yourDeltaRank, deltaMap });
        }
    }, [userToPay, amountToPay, sortedMembers, email]);

    const propsToPass = {
        amountToPay,
        email,
        group,
        capSquareUpValue,
        handleChangeAmount,
        handleChangeUser,
        handleMakePayment,
        id,
        isMakingPayment,
        preview,
        sortedMembers,
        userToPay,
    };

    return suggestionCalled ? <SquareUpUI {...propsToPass} /> : null;
};

const SquareUpUI = ({
    amountToPay,
    email,
    group,
    capSquareUpValue,
    handleChangeAmount,
    handleChangeUser,
    handleMakePayment,
    id,
    isMakingPayment,
    preview,
    sortedMembers,
    userToPay,
}) => (
    <>
        <AppHeader
            back={<></>}
            next={
                <>
                    <Link to={`/group/view/${id}`}>
                        <Close />
                    </Link>
                </>
            }
            title={
                <>
                    Square up<em>!</em>
                </>
            }
            titleOverride="Square up!"
        ></AppHeader>
        <Page pad="none">
            <Switch>
                <Redirect
                    from={"/group/squareup/:id"}
                    to={"/group/squareup/:id/select"}
                    exact
                />
                <Route exact path="/group/squareup/:id/select">
                    <SquareUpSelect
                        amountToPay={amountToPay}
                        capSquareUpValue={capSquareUpValue}
                        currencySymbol={group && group.currencySymbol}
                        currencySymbolSimple={
                            group && group.currencySymbolSimple
                        }
                        handleChangeAmount={handleChangeAmount}
                        handleChangeUser={handleChangeUser}
                        id={id}
                        users={sortedMembers}
                        signedInEmail={email}
                        userToPay={userToPay}
                        preview={preview}
                    />
                </Route>
                <Route exact path="/group/squareup/:id/pay">
                    <SquareUpPay
                        amountToPay={amountToPay}
                        currencySymbolSimple={
                            group && group.currencySymbolSimple
                        }
                        handleMakePayment={handleMakePayment}
                        isMakingPayment={isMakingPayment}
                        userToPay={userToPay}
                        signedInEmail={email}
                        id={id}
                    />
                </Route>
            </Switch>
            {isMakingPayment && <SaveOverlay />}
        </Page>
    </>
);
