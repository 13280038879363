import { validUrl } from "./urlHelpers";

/**
 * Validates the name, cost and url input elements of a recommendation.
 * Calls reportValidity() on elements if invalid.
 *
 * @param {Element} urlInput - URL TextInput element to be validated
 * @param {Element} nameInput - name TextInput element to be validated
 * @returns {boolean} true if all fields valid, false otherwise
 */
export const validateRecommendation = (urlInput, nameInput, costInput) => {
    const url = urlInput.value;
    if (validUrl(url) || url === "") {
        urlInput.setCustomValidity("");
        if (nameInput.checkValidity()) {
            return true;
        } else {
            nameInput.reportValidity();
            return false;
        }
    } else {
        urlInput.setCustomValidity("Please enter valid URL");
        urlInput.reportValidity();
        nameInput.reportValidity();
        return false;
    }
};

/**
 * Validates the name and url of recommendation for native
 *
 * @param {string} url - URL to be validated
 * @param {string} name - name to be validated
 * @returns {Object} true for a given field if it is invalid
 */
export const validateRecommendationNative = (url, name) => {
    let errors = {
        inputUrl: url !== "" && !validUrl(url),
        name: name == "",
    };
    return errors;
};
